import React from 'react';
//import useState from 'react-usestateref';
import "./Reporting.css";
import config from "../../config/config.json";

import "./ar-js-ui.css";
import "./ar-js-viewer.css";
import {Viewer} from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/xlsxexport";
import {Core} from "@grapecity/activereports";
import {FontStore} from "@grapecity/activereports/core";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function Reporting(props) {

    let  reportName, report, displayName;

    // Allows this component to use ActiveReportsJS
    Core.setLicenseKey(config.grapecitylicensekey);

    switch (props.reportName) {
        case "hiredcrewsettlementreport":
            reportName = 'crewSettlementReport';
            displayName = 'Hired Crew Settlement Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "hireddriversettlementreport":
            reportName = 'driverSettlementReport';
            displayName = 'Hired Driver Settlement Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "craneoperatorsettlementreport":
            reportName = 'craneOperatorSettlementReport';
            displayName = 'Crane Operator Settlement Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "internaltripdriversettlementreport":
            reportName = 'internalTripDriverSettlementReport';
            displayName = 'Internal Trip Driver Settlement Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "internalhourlydriversettlementreport":
            reportName = 'internalHourlyDriverSettlementReport';
            displayName = 'Internal Hourly Driver Settlement Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "companypaymentssummaryreport":
            reportName = 'companyPaymentsSummaryReport';
            displayName = 'Company Payments Summary Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "landownersreport":
            reportName = 'landOwnersReport';
            displayName = 'Land Owners Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "woodcontractsreport":
            reportName = 'woodContractsReport';
            displayName = 'Wood Contracts Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "crewsratesreport":
            reportName = 'crewsRatesReport';
            displayName = 'Crews Rates Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "driversratesreport":
            reportName = 'driversRatesReport';
            displayName = 'Drivers Rates Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "intercompanyratesreport":
            reportName = 'interCompanyRatesReport';
            displayName = 'InterCompany Rates Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "company#1:generatereport":
            reportName = 'company1Report';
            displayName = 'Company #1 Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "intercompany:generatereport":
            reportName = 'interCompanyReport';
            displayName = 'InterCompany Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "stumpageratesreport":
            reportName = 'stumpageRatesReport';
            displayName = 'Stumpage Rates Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "woodlotsreport":
            reportName = 'woodLotsReport';
            displayName = 'Wood Lots Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "stumpagereport":
            reportName = 'stumpageReport';
            displayName = 'Stumpage Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "gwylostumpagereport":
            reportName = 'GWYLOstumpageReport';
            displayName = 'GWYLO Stumpage Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "stumpagesummaryreport":
            reportName = 'stumpageSummaryReport';
            displayName = 'Stumpage Summary Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "millreport":
            reportName = 'millReport';
            displayName = 'Mill Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "weeklydeliveryreport":
            reportName = 'weeklyDeliveryReport';
            displayName = 'Weekly Delivery Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "woodaccountingreceivablesreportbyproduct":
            reportName = 'woodAccountingReceivablesByPSReport';
            displayName = 'Wood Accounting Receivables By Product Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "woodaccountingreceivablesreportbyticket":
            reportName = 'woodAccountingReceivablesByTTicketReport';
            displayName = 'Wood Accounting Receivables By Ticket Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "incompleteticketreport":
            reportName = 'incompleteTicketReport';
            displayName = 'Incomplete Ticket Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "intercompanysettlementreport":
            reportName = 'interCompanySettlementReport';
            displayName = 'InterCompany Settlement Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "intercompanyreceivablesexportreport":
            reportName = 'interCompanyReceivablesExportReport';
            displayName = 'InterCompany Receivables Export Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "internalcrewsettlementreport":
            reportName = 'internalCrewSettlementReport';
            displayName = 'Internal Crew Settlement Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "xquerydeliveryreport":
            reportName = 'xQueryDeliveryReport';
            displayName = 'X Query Delivery Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        case "landownerproduction":
            reportName = 'landOwnerProductionReport';
            displayName = 'Land Owner Production Report';
            report = 'assets/rdlx/' + reportName + '.rdlx-json';
            break;
        default:
            console.log("No matching report");
            return;
    }

    return (
        <div>
            <h3><ArrowBackIcon onClick={(e) => props.handleGoBack(e)}/> {displayName}</h3>
            <div id="viewer-host">
                <Viewer report={{Uri: report}}/>
            </div>
        </div>
    );

}