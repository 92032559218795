import React, {useState} from 'react';

// Material-UI Companents
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import config from "../../config/config";

import Grid from '@material-ui/core/Grid';


async function getData(endPoint, queryString, orderBy) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-querystring': queryString,
            'x-wtg-orderby': orderBy
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function getCompanyData(endPoint, resourceTypeId, resourceSubTypeId) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-resourcetypeid': resourceTypeId,
            'x-wtg-resourcesubtypeid': resourceSubTypeId
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function setLookups(endPoint, queryString = '{"inactive": "false"}', orderBy = '[["name", "ASC"]]') {
    let result, data = [];

    result = await getData('/' + endPoint, queryString, orderBy);

    if (result.success && result.success.length > 0) {

        for (const el of result.success) {
            data.push({"id": el.id, "name": el.name})
        }

        return (data);
    } else {
        return [];
    }

}

async function setCompanyLookups(endPoint, resourceTypeId, resourceSubTypeId) {
    let result, data = [];

    result = await getCompanyData('/' + endPoint, resourceTypeId, resourceSubTypeId);

    if (result.success && result.success.length > 0) {

        for (const el of result.success) {
            if (! data.find(x => x.id == el.Company.id)) {
                data.push({"id": el.Company.id, "name": el.Company.name})
            }
        }

        data = data.sort((a, b) => a.name < b.name ? -1: (a.name > b.name? 1: 0));

        return (data);
    } else {
        return [];
    }

}

export default function LaborResources(props) {

    const [hideCompanies, setHideCompanies] = useState(true);
    const [hideResources, setHideResources] = useState(true);
    const [hideResourceTypes, setHideResourceTypes] = useState(true);
    const [hideResourceSubTypes, setHideResourceSubTypes] = useState(true);
    const [resourceLineItems, setResourceLineItems] = useState(props.laborResources);
    const [companyId, setCompanyId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companies, setCompanies] = useState('');
    const [resourceId, setResourceId] = useState('');
    const [resourceName, setResourceName] = useState('');
    const [resources, setResources] = useState('');
    const [resourceTypeId, setResourceTypeId] = useState('');
    const [resourceTypeName, setResourceTypeName] = useState('');
    const [resourceTypes, setResourceTypes] = useState('');
    const [resourceSubTypeId, setResourceSubTypeId] = useState('');
    const [resourceSubTypeName, setResourceSubTypeName] = useState('');
    const [resourceSubTypes, setResourceSubTypes] = useState('');


    const handleAddClick = async () => {

        setHideResourceTypes(false);
        setResourceTypes(await setLookups("lookuplaborresourcetypes", '{}'));
        props.laborHasChanged();
    };

    const handleDeleteClick = async (index) => {

        let newResourceLineItems = [...resourceLineItems];

        newResourceLineItems.splice(index, 1);

        setResourceLineItems([...newResourceLineItems]);
        props.handleLaborChange([...newResourceLineItems]);
        props.laborHasChanged();
    }

    const handleChange = async (input, e) => {
        let filter;
        switch (input) {
            case 'company':

                if (resourceSubTypeId) {

                    filter = '{"inactive": "false","companyId": ' + e.target.value +
                        ',"laborResourceTypeId":' + resourceTypeId +
                        ',"laborResourceSubTypeId":' + resourceSubTypeId + '}';
                } else {
                    filter = '{"inactive": "false","companyId": ' + e.target.value +
                        ',"laborResourceTypeId":' + resourceTypeId + '}';
                }

                let c = companies.find(x => x.id == e.target.value);
                setCompanyId(e.target.value);
                setCompanyName(c.name);
                setHideResources(false);
                setResources(await setLookups("lookuplaborresources", filter,
                    '[["name", "ASC"]]'));
                break;
            case 'resource':
                let r = resources.find(x => x.id == e.target.value);
                setResourceId(e.target.value);
                setResourceName(r.name);
                setResourceLineItems([...resourceLineItems, ...[{
                    LaborResourceType: {"name": resourceTypeName},
                    LaborResourceSubType: {"name": resourceSubTypeName},
                    Company: {"name": companyName},
                    "name": r.name, "id": r.id
                }]]);
                props.handleLaborChange([...resourceLineItems, ...[{
                    LaborResourceType: {"name": resourceTypeName},
                    LaborResourceSubType: {"name": resourceSubTypeName},
                    Company: {"name": companyName},
                    "name": r.name, "id": r.id
                }]]);
                setHideCompanies(true);
                setHideResourceTypes(true);
                setHideResourceSubTypes(true);
                setHideResources(true);
                setResourceId('');
                setResourceName('');
                setResources('');
                setCompanyId('');
                setCompanyName('');
                setCompanies('');
                setResourceTypeId('');
                setResourceTypeName('');
                setResourceTypes('');
                setResourceSubTypeId('');
                setResourceSubTypeName('');
                setResourceSubTypes('');
                break;
            case 'resourcetype':
                let rt = resourceTypes.find(x => x.id == e.target.value);
                setResourceTypeId(e.target.value);
                setResourceTypeName(rt.name);

                setHideCompanies(true);
                setHideResourceSubTypes(true);
                setHideResources(true);
                setResourceId('');
                setResourceName('');
                setResources('');
                setCompanyId('');
                setCompanyName('');
                setCompanies('');
                setResourceSubTypeId('');
                setResourceSubTypeName('');
                setResourceSubTypes('');

                if (rt.name.toLowerCase() === "crew") {
                    setHideResourceSubTypes(false);
                    setHideResources(true);
                    setHideCompanies(true);
                    setResourceSubTypes(await setLookups("lookuplaborresourcesubtypes", '{}',
                        '[["name", "ASC"]]'));
                } else {
                    setHideCompanies(false);
                    setHideResourceSubTypes(true);
                    setCompanies(await setCompanyLookups("companiesbyresourcetypes", e.target.value));
                }
                break;
            case 'resourcesubtype':
                let rst = resourceSubTypes.find(x => x.id == e.target.value);
                setResourceSubTypeId(e.target.value);
                setResourceSubTypeName(rst.name);
                setHideCompanies(false);
                setHideResources(true);
                setResourceId('');
                setResourceName('');
                setResources('');
                setCompanyId('');
                setCompanyName('');
                setCompanies('');

                setCompanies(await setCompanyLookups("companiesbyresourcetypes", resourceTypeId, e.target.value));
                break;
            default:
                console.log("Handle change input not found.")
        }
    };

    return (


        <div align="left">
            <h5>Labor Resources</h5>

            <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-start">
                <Grid item xs={12}>
                    <Grid container wrap="nowrap" spacing={3} direction="row" alignItems="flex-start"
                          justify="flex-start">
                        <Grid item xs={3}>
                            <u>Company Name</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Labor Resource</u>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Delete</u>
                        </Grid>
                    </Grid>
                    {resourceLineItems.length > 0 && resourceLineItems.map((el, index) => {
                        return (
                            <Grid key={index} container wrap="nowrap" spacing={3} direction="row" alignItems="flex-start" justify="flex-start">
                                <Grid item xs={3}>
                                    {el.Company.name}
                                </Grid>
                                <Grid item xs={3}>
                                    {el.name}
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={3}>
                                    <DeleteIcon onClick={() => handleDeleteClick(index)}/>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={12}>
                    <AddIcon onClick={handleAddClick}/>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel id="resourcetype-label" hidden={hideResourceTypes}>Resource Type</InputLabel>
                    <Select hidden={hideResourceTypes} labelId="resourcetype-label" id="select-resourcetype"
                            value={resourceTypeId}
                            onChange={(e) => handleChange("resourcetype", e)}>
                        {resourceTypes.length > 0 && resourceTypes.map((el, index) =>
                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel id="resourcesubtype-label" hidden={hideResourceSubTypes}>Resource SubType</InputLabel>
                    <Select hidden={hideResourceSubTypes} labelId="resourcesubtype-label" id="select-resourcesubtype"
                            value={resourceSubTypeId}
                            onChange={(e) => handleChange("resourcesubtype", e)}>
                        {resourceSubTypes.length > 0 && resourceSubTypes.map((el, index) =>
                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel id="company-label" hidden={hideCompanies}>Companies</InputLabel>
                    <Select hidden={hideCompanies} labelId="company-label" id="select-company" value={companyId}
                            onChange={(e) => handleChange("company", e)}>
                        {companies.length > 0 && companies.map((el, index) =>
                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel id="resource-label" hidden={hideResources}>Company Labor Resources</InputLabel>
                    <Select hidden={hideResources} labelId="resource-label" id="select-resource" value={resourceId}
                            onChange={(e) => handleChange("resource", e)}>
                        {resources.length > 0 && resources.map((el, index) =>
                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid>

        </div>

    )
};