import React from 'react';

// Material-UI Components
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TicketIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import NaturePeopleTwoToneIcon from '@material-ui/icons/NaturePeopleTwoTone';
import NatureIcon from '@material-ui/icons/Nature';
import BarChartIcon from '@material-ui/icons/BarChart';
import PermDataSettingTwoToneIcon from '@material-ui/icons/PermDataSettingTwoTone';
import CustomerIcon from '@material-ui/icons/EmojiPeople';

export default function Menu(props) {

    return (

    <div className="grid-child">
        <ListItem button onClick={props.selectMenu} onContextMenu={props.selectMenu}>
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
        </ListItem>
        <ListItem button onClick={props.selectMenu} onContextMenu={props.selectMenu}>
            <ListItemIcon>
                <BarChartIcon/>
            </ListItemIcon>
            <ListItemText primary="Reporting"/>
        </ListItem>
        <ListItem button onClick={props.selectMenu} onContextMenu={props.selectMenu}>
            <ListItemIcon>
                <TicketIcon />
            </ListItemIcon>
            <ListItemText primary="Tickets"/>
        </ListItem>
        <ListItem button onClick={props.selectMenu} onContextMenu={props.selectMenu}>
            <ListItemIcon>
                <TicketIcon />
            </ListItemIcon>
            <ListItemText primary="Complete Ticket"/>
        </ListItem>
        <ListItem button onClick={props.selectMenu} onContextMenu={props.selectMenu}>
            <ListItemIcon>
                <NaturePeopleTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Contracts and Rates"/>
        </ListItem>
        <ListItem button onClick={props.selectMenu} onContextMenu={props.selectMenu}>
            <ListItemIcon>
                <NatureIcon />
            </ListItemIcon>
            <ListItemText primary="Wood Supply Chain"/>
        </ListItem>
        <ListItem button onClick={props.selectMenu} onContextMenu={props.selectMenu}>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Labor Resource Management"/>
        </ListItem>
        <ListItem button onClick={props.selectMenu} onContextMenu={props.selectMenu}>
            <ListItemIcon>
                <PermDataSettingTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Data Administration"/>
        </ListItem>

    </div>

)};
