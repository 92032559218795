import React, {useState} from 'react';

import Grid from "@material-ui/core/Grid";
import Report from "../Report/Report";
import HiredCrewPayments from "../HiredCrewPayments/HiredCrewPayments";
import Co1Export from "../Co1Export/Co1Export";
import InterCompanyExport from "../InterCompanyExport/InterCompanyExport";
import ImportTickets from "../ImportTickets/ImportTickets";

export default function Reporting() {

    const [reportName, setReportName] = useState('');
    const [hiredCrewPayments, setHiredCrewPayments] = useState('');
    const [co1Export, setCo1Export] = useState('');
    const [interCompanyExport, setInterCompanyExport] = useState('');
    const [gardnerLiveImport, setGardnerLiveImport] = useState('');

    const handleReportClick = async (e) => {
        setReportName(e.target.innerText.replace(/\s/g, '').toLowerCase());
        setHiredCrewPayments('');
        setCo1Export('');
        setInterCompanyExport('');
        setGardnerLiveImport('');
    };

    const handleHiredCrewPaymentsClick = async (e) => {
        setReportName('');
        setHiredCrewPayments(1);
        setCo1Export('');
        setInterCompanyExport('');
        setGardnerLiveImport('');
    };

    const handleCo1ExportClick = async (e) => {
        setReportName('');
        setCo1Export(1);
        setHiredCrewPayments('');
        setInterCompanyExport('');
        setGardnerLiveImport('');
    };

    const handleInterCompanyExportClick = async (e) => {
        setReportName('');
        setInterCompanyExport(1);
        setHiredCrewPayments('');
        setCo1Export('');
        setGardnerLiveImport('');
    };

    const handleLocalTicketImportClick = async (e) => {
        setReportName('');
        setInterCompanyExport('');
        setHiredCrewPayments('');
        setCo1Export('');
        setGardnerLiveImport('Local');
    };
    const handleWoodsTicketImportClick = async (e) => {
        setReportName('');
        setInterCompanyExport('');
        setHiredCrewPayments('');
        setCo1Export('');
        setGardnerLiveImport('Woods');
    };

    const handleGoBack = async (e) => {
        setReportName('');
        setHiredCrewPayments('');
        setCo1Export('');
        setInterCompanyExport('');
        setGardnerLiveImport('');
    };

    if ((reportName.length === 0) && (hiredCrewPayments.length === 0)
        && (co1Export.length === 0) && (interCompanyExport.length === 0) && (gardnerLiveImport.length === 0)) {
        return (
            <main>
                <h1>Reporting</h1>
                <Grid container spacing={3} direction="row" alignItems="stretch" justify="flex-end">

                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <h3><u>Payments and Settlements</u></h3>
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Hired Crew Settlement Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Hired Driver Settlement Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Crane Operator Settlement Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Internal Crew Settlement Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Internal Trip Driver Settlement Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Internal Hourly Driver Settlement Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            InterCompany Settlement Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            InterCompany Receivables Export Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Company Payments Summary Report
                        </Grid>
                    </Grid>

                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <h3><u>Import/Export Utilities</u></h3>
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleHiredCrewPaymentsClick(e)}>
                            Hired Crew Payments Form
                        </Grid>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        <Grid item xs={12}>
                            <h5><u>Gardner Live Ticket Imports</u></h5>
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleLocalTicketImportClick(e)}>
                            Outbound (Local) Tickets
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleWoodsTicketImportClick(e)}>
                            Woods (Crane) Tickets
                        </Grid>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        <Grid item xs={12}>
                            <h5><u>Receivables Reports and Export</u></h5>
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Company #1: Generate Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleCo1ExportClick(e)}>
                            Company #1: Great Plains Export
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            InterCompany: Generate Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleInterCompanyExportClick(e)}>
                            InterCompany: Great Plains Export
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <h3><u>Delivery Reports</u></h3>
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Stumpage Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            GWYLO Stumpage Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Stumpage Summary Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Mill Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Weekly Delivery Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Wood Accounting Receivables Report by Product
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Wood Accounting Receivables Report by Ticket
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            X Query Delivery Report
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <h3><u>Table Reports</u></h3>
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Incomplete Ticket Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Land Owners Report
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Wood Lots Report
                        </Grid>
                        <Grid item xs={12}>
                            TBD Report
                        </Grid>
                        <Grid item xs={12}>
                            TBD Report
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={12}>
                            <h3><u>Production Reports</u></h3>
                        </Grid>
                        <Grid item xs={12} onClick={(e) => handleReportClick(e)}>
                            Land Owner Production
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
            </main>
        );
    } else {
        if (hiredCrewPayments == 1) {
            return (
                <main>
                    <HiredCrewPayments handleGoBack={handleGoBack}/>
                </main>
            );
        } else if (co1Export == 1) {
            return (
                <main>
                    <Co1Export handleGoBack={handleGoBack}/>
                </main>
            );
        } else if (interCompanyExport == 1) {
            return (
                <main>
                    <InterCompanyExport handleGoBack={handleGoBack}/>
                </main>
            );
        } else if (gardnerLiveImport === 'Local' || gardnerLiveImport === 'Woods') {
            return (
                <main>
                    <ImportTickets gardnerLiveImport={gardnerLiveImport} handleGoBack={handleGoBack}/>
                </main>
            );
        } else {
            return (
                <main>
                    <Report reportName={reportName} handleGoBack={handleGoBack}/>
                </main>
            );

        }
    }
}