import React, { Component } from "react";
import config from "../../config/config.json";

async function loginUser(credentials) {


    console.log ("URL: " + config.wacct_api_url);
    console.log ("Port: " + config.wacct_api_port);
    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + '/auth', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-api-username': credentials.username,
            'x-wtg-api-password': credentials.password
        }
    })
        .then(data => data.json())
        .catch( error => {
            console.log("ERROR connecting to WACCT API: " + error)
        })
}


export default class Login extends Component {

    state = {
        username: null,
        password: null,
        roles: null,
        message: null
    };

    handleSubmit = async e => {

        e.preventDefault();

        if (this.state.username && this.state.password) {

            const token = await loginUser({
               username: this.state.username,
               password: this.state.password
            });

            console.log("Token:" + JSON.stringify(token));

            if (token && token.success) {
                let role_csv = '';
                for (let role of token.success) {
                    console.log("Role Name: " + role.name);
                    role_csv = role_csv + ',' + role.name
                }

                this.setState({ roles: role_csv });

                let encoded_user_details = this.state.username + role_csv;
                console.log("Token to be encoded: " + encoded_user_details);

                // Roles for basic application access required
                let regex = /USER|ADMIN|SUPER/;

                if (encoded_user_details.match(regex)) {

                    let buff = new Buffer(encoded_user_details);
                    let token_base64 = buff.toString('base64');
                    sessionStorage.setItem("wacct_token", token_base64);
                    this.setState({"message": "Logging in ...."});
                    setTimeout(function () {
                        window.location.reload();
                    }, 3000);

                } else {
                    this.setState({"message": "You do not have the proper role(s) to access the Dashboard. Please contact I/T Support."});
                }
            } else if (token && token.error) {
                this.setState({"message": token.error});
            } else {
                this.setState({"message": "Problem connecting to WACCT API. Please contact I/T Support."});
            }
        }
    };

    handleUserChange = async e => {
        this.setState({"username": e.target.value});
    };

    handlePasswordChange = async e => {
        this.setState({"password": e.target.value});
    };


    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <h3>Wood Accounting Sign In</h3>
                <p>{this.state.message}</p>
                <div className="form-group">
                    <label>User Name</label>
                    <input type="text" className="form-control" placeholder="Enter your user name" onChange={this.handleUserChange} />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input type="password" className="form-control" placeholder="Enter password" onChange={this.handlePasswordChange}/>
                </div>

                <button type="submit" className="btn btn-success btn-block">Submit</button>
            </form>
        );
    }
}