import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import queryString from "query-string";



// Menu Components required
import Menu from "../Menu/Menu";
import Dashboard from "../Dashboard/Dashboard";
import TicketManagement from "../TicketManagement/TicketManagement";
import CompleteTicket from "../CompleteTicket/CompleteTicket";
import Reporting from "../Reporting/Reporting";
import ContractManagement from "../ContractManagement/ContractManagement";
import WoodSupplyChain from "../WoodSupplyChain/WoodSupplyChain";
import DataAdministration from "../DataAdministration/DataAdministration";
import LaborResourceManagement from "../LaborResourceManagement/LaborResourceManagement";

class Layout extends Component {

    componentMap = {
        'Dashboard': <Dashboard/>,
        'Contracts and Rates': <ContractManagement user_details={this.props.user_details}/>,
        'Wood Supply Chain': <WoodSupplyChain user_details={this.props.user_details}/>,
        'Tickets': <TicketManagement user_details={this.props.user_details}/>,
        'Complete Ticket': <CompleteTicket user_details={this.props.user_details}/>,
        'Labor Resource Management': <LaborResourceManagement user_details={this.props.user_details}/>,
        'Reporting': <Reporting/>,
        'Data Administration': <DataAdministration user_details={this.props.user_details}/>,
    };

    state = {
        menuItem: (queryString.parse(window.location.search).tab ? this.componentMap[queryString.parse(window.location.search).tab]: <Dashboard/>),
        tabName: null,
        openTab: false
    };

    selectMenu = (e) => {
        e.preventDefault();

        const menuItem = e.target.outerText;
        if (e.type === 'click') {
            this.setState({menuItem: this.componentMap[menuItem],  tabName: null, openTab: false});
        } else {
            let tabName = menuItem;
            this.setState({menuItem: this.state.menuItem, tabName: tabName, openTab: true});
        }
    };

    openTab = (url) => {
        window.open(url, '_blank');
    };

    render() {

        if (!this.state.openTab) {
            return (
                <Router>
                <div className="grid-container">
                    <div className="grid-child">
                        <Menu selectMenu={this.selectMenu}/>
                    </div>
                    <main className="grid-child">
                        {this.state.menuItem}
                    </main>
                </div>
                </Router>
            );
        } else {
            return (
                <Router>
                    <div className="grid-container">
                        <div className="grid-child">
                            <Menu selectMenu={this.selectMenu}/>
                        </div>
                        <main className="grid-child">
                            {this.state.menuItem}
                            {this.openTab('/?tab=' + this.state.tabName)}
                        </main>
                    </div>
                </Router>

            );
        }
    }
}

export default Layout;
