import React, {useEffect, useState} from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import config from "../../config/config";
import Grid from "@material-ui/core/Grid";
import {Button, Checkbox, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import ResetFormIcon from '@mui/icons-material/RestartAlt';


async function getData(endPoint, queryString, orderBy) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-querystring': queryString,
            'x-wtg-orderby': orderBy
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function insertData(endPoint, postData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(postData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

export default function HiredCrewPayments(props) {

    const [companies, setCompanies] = useState('');

    useEffect(() => {
        console.log("Use effect");
        getCompanies();
    }, []);

    const [companyId, setCompanyId] = useState('');
    const [weekEnding, setWeekEnding] = useState('');
    const [deliveredWood, setDeliveredWood] = useState('');
    const [expenses, setExpenses] = useState('');
    const [payment, setPayment] = useState('');
    const [checkIssued, setCheckIssued] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [statusMessageColor, setStatusMessageColor] = useState('');


    const getCompanies = async () => {

        let hiredCrews = await getData('/lookupcompanies',
            '{"companyTypeId": 2}', '[["name", "ASC"]]');

        setCompanies(hiredCrews.success);

    };

    const resetForm = async () => {
        setCompanyId('');
        setWeekEnding('');
        setDeliveredWood('');
        setExpenses('');
        setPayment('');
        setCheckIssued('');
        setStatusMessage('');
        setStatusMessageColor('');
    };


    const handleChange = async (input, e) => {
        //e.preventDefault();
        switch (input) {
            case 'companyId':
                setCompanyId(e.target.value);
                break;
            case 'weekEnding':
                setWeekEnding(e.target.value);
                break;
            case 'deliveredWood':
                setDeliveredWood(e.target.value);
                break;
            case 'expenses':
                setExpenses(e.target.value);
                break;
            case 'payment':
                setPayment(e.target.value);
                break;
            case 'checkIssued':
                setCheckIssued(e.target.value);
                break;
            default:
                console.log("Handle form change input not found.")
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        let formData = {}, endPoint = "payments";

        formData.companyId = companyId;
        formData.weekEnding = weekEnding;
        formData.deliveredWood = deliveredWood;
        formData.expenses = expenses;
        formData.payment = payment;
        formData.checkIssued = checkIssued;

        console.log("Request Form Data: " + JSON.stringify(formData));

        let result;

        try {

            console.log("Inserting new data ...");
            result = await insertData('/companyPayments', formData);

            if (result.success) {
                console.log("Insert success: " + JSON.stringify(result.success));
                setStatusMessage("Insert success: " + JSON.stringify(result.success));
                setStatusMessageColor("green");
                setCompanyId('');
                setWeekEnding('');
                setDeliveredWood('');
                setExpenses('');
                setPayment('');
                setCheckIssued('');
            } else {
                console.log("Insert failed: " + JSON.stringify(result.error));
                setStatusMessage("Insert failed: " + JSON.stringify(result.error));
                setStatusMessageColor("red");
                setTimeout(
                    () => {
                        setStatusMessage('');
                        setStatusMessageColor('');
                    },
                    3000
                );
            }

        } catch (error) {
            console.log("Insert failed: " + JSON.stringify(error));
            setStatusMessage("Insert failed: " + JSON.stringify(error));
            setStatusMessageColor("red");
            setTimeout(
                () => {
                    setStatusMessage('');
                    setStatusMessageColor('');
                },
                3000
            );
        }

    }; // End handleSubmit();

    return (
        <>
            <h3><ArrowBackIcon onClick={(e) => props.handleGoBack(e)}/> Hired Crew Payments</h3>


            <form onSubmit={handleSubmit} id={"create-hiredcrewpayment-form"} autoComplete="off">
                <Grid container spacing={3} direction="row" alignItems="flex-start"
                      justify="flex-start"
                      style={{padding: 60}}>
                    <Grid item xs={12}>
                        <h2><u>Add Hired Crew Payment</u></h2>
                        <p style={{color: statusMessageColor}}>{statusMessage}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <ResetFormIcon onClick={resetForm} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="weekending"
                            label="Week Ending"
                            required="true"
                            type="date"
                            value={weekEnding}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => handleChange("weekEnding", e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel id="hiredcrew-select">Hired Crew</InputLabel>
                        <Select labelId="hiredcrew-label" required="true" id="select-hiredcrew" value={companyId}
                                onChange={(e) => handleChange("companyId", e)}>
                            {companies.length > 0 && companies.map((el, index) =>
                                <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="delivered-wood"
                            label="Delivered Wood"
                            variant="outlined"
                            value={deliveredWood}
                            onChange={(e) => handleChange("deliveredWood", e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="expenses"
                            label="Expenses"
                            variant="outlined"
                            value={expenses}
                            onChange={(e) => handleChange("expenses", e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="payment"
                            label="Payment"
                            variant="outlined"
                            value={payment}
                            onChange={(e) => handleChange("payment", e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="check-issued"
                            label="Check Issued"
                            variant="outlined"
                            value={checkIssued}
                            onChange={(e) => handleChange("checkIssued", e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained"
                                style={{color: "green"}}>Add</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}
