//import React, {useState} from 'react';
import React from 'react';
import useState from 'react-usestateref';

import config from "../../config/config.json";
import './ag-grid.css';

// Material-UI Components
import {Tooltip, ButtonGroup, Button, Checkbox, InputLabel, Select, MenuItem, TextField} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import BorderClearIcon from '@material-ui/icons/BorderClear';

// AG Grid Components
import {AgGridReact, AgGridColumn} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


// Date/Time Support
import moment from 'moment';
import 'moment-timezone';
import Modal from "react-modal";

async function getAllData(endPoint) {
    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function lookupData(endPoint, queryString, orderBy) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-querystring': queryString,
            'x-wtg-orderby': orderBy
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function setLookups(endPoint, queryString, orderBy) {
    let result, data = [];

    result = await lookupData('/' + endPoint, queryString, orderBy);

    if (result.success && result.success.length > 0) {

        if (endPoint === 'lookuplandownercontractgroups') {
            for (const el of result.success) {
                data.push({"id": el.landOwnerId, "name": el.LandOwner.name})
            }
        }
        return (data);
    } else {
        return [];
    }

}

async function insertData(endPoint, postData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(postData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function updateData(endPoint, putData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(putData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}


export default function ContractManagement(props) {

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [tableHeading, setTableHeading] = useState('');
    const [tableError, setTableError] = useState('');
    const [menuColors, setMenuColors] = useState(['white', 'white', 'white', 'white']);
    const [menuIndex, setMenuIndex] = useState('');
    const [displayGrid, setDisplayGrid] = useState(false);
    const [hideContractGroups, setHideContractGroups] = useState(true);
    const [hideCompanies, setHideCompanies] = useState(true);
    const [hideLandOwners, setHideLandOwners] = useState(true);
    const [hideReceivingSites, setHideReceivingSites] = useState(true);
    const [hideWoodLots, setHideWoodLots] = useState(true);
    const [hideLaborResourceTypes, setHideLaborResourceTypes] = useState(true);
    const [contractGroupsLookup, setContractGroupsLookup] = useState([]);
    const [landOwnersLookup, setLandOwnersLookup] = useState([]);
    const [laborResourceTypesLookup, setLaborResourceTypesLookup] = useState([]);
    const [laborResourceSubTypesLookup, setLaborResourceSubTypesLookup] = useState([]);
    const [laborResourcesLookup, setLaborResourcesLookup] = useState([]);
    const [receivingSitesLookup, setReceivingSitesLookup] = useState([]);
    const [companiesLookup, setCompaniesLookup] = useState([]);
    const [companyTypesLookup, setCompanyTypesLookup] = useState([]);
    const [woodLotsLookup, setWoodLotsLookup] = useState([]);
    const [productsLookup, setProductsLookup] = useState([]);
    const [speciesLookup, setSpeciesLookup] = useState([]);
    const [gradesLookup, setGradesLookup] = useState([]);
    const [lengthsLookup, setLengthsLookup] = useState([]);
    const [unitsLookup, setUnitsLookup] = useState([]);
    const [customersLookup, setCustomersLookup] = useState([]);
    const [glAccountsLookup, setGlAccountsLookup] = useState([]);
    const [filtersSet, setFiltersSet] = useState(false);
    const [queryFilters, setQueryFilters, queryFiltersRef] = useState({});
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [modalNoFilters, setModalNoFilters] = useState(false);

    // Modal Forms
    const [menuSelected, setMenuSelected] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Modal Form data
    const [contractGroupName, setContactGroupName] = useState('');
    const [exportGLive, setExportGLive] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [woodLotId, setWoodLotId] = useState('');
    const [productId, setProductId] = useState('');
    const [specieId, setSpecieId] = useState('');
    const [gradeId, setGradeId] = useState('');
    const [lengthId, setLengthId] = useState('');
    const [unitId, setUnitId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [glAccountId, setGlAccountId] = useState('');
    const [companyTypeId, setCompanyTypeId] = useState('');
    const [laborResourceSubTypeId, setLaborResourceSubTypeId] = useState('');
    const [receivingSiteId, setReceivingSiteId] = useState('');
    const [contractGroupId, setContractGroupId] = useState('');
    const [landOwnerId, setLandOwnerId] = useState('');
    const [laborResourceId, setLaborResourceId] = useState('');
    const [rate, setRate] = useState('');
    const [effDate, setEffDate] = useState('');
    const [expDate, setExpDate] = useState('');
    const [userName, setUserName] = useState(props.user_details.split(',')[0]);
    const [millPct, setMillPct] = useState('');
    const [contractId, setContractId] = useState('');
    const [certifiedLine1, setCertifiedLine1] = useState('');
    const [certifiedLine2, setCertifiedLine2] = useState('');
    const [gardnerLiveMill, setGardnerLiveMill] = useState('');
    const [coNumToPay, setCoNumToPay] = useState('');

    const customStyles = {
        content: {
            position: 'absolute',
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '10%',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '5%'
        },
    };

    const openModal = (e) => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);

        /*

        setContactGroupName('');
        setExportGLive(false);
        setWoodLotId('');
        setProductId('');
        setSpecieId('');
        setGradeId('');
        setLengthId('');
        setUnitId('');
        setCustomerId('');
        setGlAccountId('');
        setEffDate('');
        setExpDate('');
        setContractId('');
        setCertifiedLine1('');
        setCertifiedLine2('');
        setGardnerLiveMill('');
        setMillPct('');
        setCompanyId('');
        setRate('');

         */


    };

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    };

    const clearFormData = () => {

        setContactGroupName('');
        setExportGLive(false);
        setWoodLotId('');
        setProductId('');
        setSpecieId('');
        setGradeId('');
        setLengthId('');
        setUnitId('');
        setCustomerId('');
        setGlAccountId('');
        setEffDate('');
        setExpDate('');
        setContractId('');
        setCertifiedLine1('');
        setCertifiedLine2('');
        setGardnerLiveMill('');
        setCoNumToPay('');
        setMillPct('');
        setCompanyId('');
        setRate('');
        setCompanyTypeId('');
        setLaborResourceSubTypeId('');
        setLaborResourceId('');
        setReceivingSiteId('');
        setContractGroupId('');
        setLandOwnerId('');
        setModalNoFilters(false);

    };

    // Support clearing field on modal
    const clearFieldData = (field, e) => {

        if (field === 'companytype') {
            setCompanyTypeId('');
        }
        if (field === 'laborresourcesubtype') {
            setLaborResourceSubTypeId('');
        }
        if (field === 'laborresource') {
            setLaborResourceId('');
        }
        if (field === 'receivingsite') {
            setReceivingSiteId('');
        }
        if (field === 'landowner') {
            setLandOwnerId('');
        }

        if (field === 'woodlot') {
            setWoodLotId('');
        }
        if (field === 'product') {
            setProductId('');
        }
        if (field === 'specie') {
            setSpecieId('');
        }
        if (field === 'grade') {
            setGradeId('');
        }
        if (field === 'length') {
            setLengthId('');
        }

    };


    const _transformResult = (result, endPoint) => {

        // Special case for Wood Contracts
        if (endPoint === "woodcontracts") {
            result["success"].forEach((el) => {
                el.ContractGroup = el.ContractGroup.name;
                el.Product = el.Product.name;
                el.Specie = el.Specie.name;
                el.Unit = el.Unit.name;
                el.ReceivingSite = el.ReceivingSite.name;
                el.Customer = el.Customer.name;
                el.GlAccount = el.GlAccount.name;

                // May or may not be set
                if (el.LandOwner) {
                    el.LandOwner = el.LandOwner.name;
                }
                if (el.Grade) {
                    el.Grade = el.Grade.name;
                }
                if (el.Length) {
                    el.Length = el.Length.name;
                }
                if (el.WoodLot) {
                    el.WoodLot = el.WoodLot.name;
                }
                if (el.effDate) {
                    el.effDate = moment(el.effDate).format('M/D/YYYY');
                }
                if (el.expDate) {
                    el.expDate = moment(el.expDate).format('M/D/YYYY');
                }


                delete el.contractGroupId;
                delete el.ContractGroupId;
                delete el.productId;
                delete el.ProductId;
                delete el.specieId;
                delete el.SpecieId;
                delete el.gradeId;
                delete el.GradeId;
                delete el.lengthId;
                delete el.LengthId;
                delete el.unitId;
                delete el.UnitId;
                delete el.woodLotId;
                delete el.WoodLotId;
                delete el.receivingSiteId;
                delete el.ReceivingSiteId;
                delete el.landOwnerId;
                delete el.LandOwnerId;
                delete el.customerId;
                delete el.CustomerId;
                delete el.glAccountId;
                delete el.GlAccountId;

            });
        }

        if (endPoint === "laborrates") {


            result["success"].forEach((el) => {
                el.LandOwner = el.LandOwner.name;

                if (el.CompanyType) {
                    el.CompanyType = el.CompanyType.name
                }

                if (el.LaborResource) {
                    el.LaborResource = el.LaborResource.name
                }

                if (el.LaborResourceType) {
                    el.LaborResourceType = el.LaborResourceType.name
                }

                if (el.LaborResourceSubType) {
                    el.LaborResourceSubType = el.LaborResourceSubType.name
                }

                if (el.WoodLot) {
                    el.WoodLot = el.WoodLot.name
                }

                if (el.Product) {
                    el.Product = el.Product.name;
                }
                if (el.Specie) {
                    el.Specie = el.Specie.name;
                }
                if (el.Grade) {
                    el.Grade = el.Grade.name;
                }
                if (el.Length) {
                    el.Length = el.Length.name;
                }
                if (el.ReceivingSite) {
                    el.ReceivingSite = el.ReceivingSite.name;
                }
                if (el.Unit) {
                    el.Unit = el.Unit.name;
                }
                if (el.effDate) {
                    el.effDate = moment(el.effDate).format('M/D/YYYY')
                }
                if (el.expDate) {
                    el.expDate = moment(el.expDate).format('M/D/YYYY')
                }


                delete el.productId;
                delete el.ProductId;
                delete el.specieId;
                delete el.SpecieId;
                delete el.gradeId;
                delete el.GradeId;
                delete el.lengthId;
                delete el.LengthId;
                delete el.unitId;
                delete el.UnitId;
                delete el.landOwnerId;
                delete el.LandOwnerId;
                delete el.woodLotId;
                delete el.WoodLotId;
                delete el.laborResourceId;
                delete el.LaborResourceId;
                delete el.laborResourceTypeId;
                delete el.LaborResourceTypeId;
                delete el.laborResourceSubTypeId;
                delete el.LaborResourceSubTypeId;
                delete el.receivingSiteId;
                delete el.ReceivingSiteId;
                delete el.companyTypeId;
                delete el.CompanyTypeId;

            });
        }

        if (endPoint === "stumpagerates") {
            result["success"].forEach((el) => {
                el.LandOwner = el.LandOwner.name;

                if (el.WoodLot) {
                    el.WoodLot = el.WoodLot.name
                }

                if (el.Product) {
                    el.Product = el.Product.name;
                }
                if (el.Specie) {
                    el.Specie = el.Specie.name;
                }
                if (el.Grade) {
                    el.Grade = el.Grade.name;
                }
                if (el.Length) {
                    el.Length = el.Length.name;
                }
                if (el.Unit) {
                    el.Unit = el.Unit.name;
                }
                if (el.effDate) {
                    el.effDate = moment(el.effDate).format('M/D/YYYY')
                }
                if (el.expDate) {
                    el.expDate = moment(el.expDate).format('M/D/YYYY')
                }

                delete el.productId;
                delete el.ProductId;
                delete el.specieId;
                delete el.SpecieId;
                delete el.gradeId;
                delete el.GradeId;
                delete el.lengthId;
                delete el.LengthId;
                delete el.unitId;
                delete el.UnitId;
                delete el.landOwnerId;
                delete el.LandOwnerId;
                delete el.woodLotId;
                delete el.WoodLotId;
            });
        }

        // Special case for Inter Company Rates
        if (endPoint === "intercompanyrates") {
            result["success"].forEach((el) => {
                if (el.Product) {
                    el.Product = el.Product.name;
                }
                if (el.Specie) {
                    el.Specie = el.Specie.name;
                }
                el.Unit = el.Unit.name;
                el.ReceivingSite = el.ReceivingSite.name;

                // May or may not be set
                if (el.LandOwner) {
                    el.LandOwner = el.LandOwner.name;
                }
                if (el.Customer) {
                    el.Customer = el.Customer.name;
                }
                if (el.GlAccount) {
                    el.GlAccount = el.GlAccount.name;
                }

                if (el.Company) {
                    el.Company = el.Company.name;
                }

                if (el.Grade) {
                    el.Grade = el.Grade.name;
                }
                if (el.Length) {
                    el.Length = el.Length.name;
                }
                if (el.WoodLot) {
                    el.WoodLot = el.WoodLot.name;
                }
                if (el.effDate) {
                    el.effDate = moment(el.effDate).format('M/D/YYYY')
                }
                if (el.expDate) {
                    el.expDate = moment(el.expDate).format('M/D/YYYY')
                }


                delete el.companyId;
                delete el.CompanyId;
                delete el.productId;
                delete el.ProductId;
                delete el.specieId;
                delete el.SpecieId;
                delete el.gradeId;
                delete el.GradeId;
                delete el.lengthId;
                delete el.LengthId;
                delete el.unitId;
                delete el.UnitId;
                delete el.woodLotId;
                delete el.WoodLotId;
                delete el.receivingSiteId;
                delete el.ReceivingSiteId;
                delete el.landOwnerId;
                delete el.LandOwnerId;
                delete el.customerId;
                delete el.CustomerId;
                delete el.glAccountId;
                delete el.GlAccountId;

            });
        }


    };

    let handleKeyDown = async (menuIndex, e) => {
        // Add modal directly using +
        if (e.key === '+') {
            console.log("Popping up modal input form");
            //clearFormData();
            setModalNoFilters(true);

            let cgroups = await getAllData('/contractgroups');
            setContractGroupsLookup(cgroups.success);

            openModal(e);


        }
    };

    let handleClick = async (menuIndex, e) => {
        try {

            // Clear all form data if menu item is selected manually
            if (e && e.target && e.target.innerText) {
                clearFormData();
            }

            let colors = ["white", "white", "white", "white", "white"];
            colors[menuIndex] = "#90ee90";

            setMenuColors(colors);
            setMenuIndex(menuIndex);

            if (e && e.target && e.target.innerText) {
                setTableHeading(e.target.innerText.toUpperCase());
            } else {
                setTableHeading(e.toUpperCase());
            }

            let result;
            let columnDefs = [];
            let endPoint;

            if (e && e.target && e.target.innerText) {
                endPoint = e.target.innerText.toLowerCase().replace(/\s/g, '');
            } else {
                endPoint = e;
            }

            let contractGroups = [], contractGroupsResult;
            let laborResources = [], laborResourcesResult;
            let laborResourceTypes = [], laborResourceTypesResult;
            let laborResourceSubTypes = [], laborResourceSubTypesResult;
            let companies = [], companiesResult;
            let companyTypes = [], companyTypesResult;
            let units = [], unitsResult;
            let products = [], productsResult;
            let species = [], speciesResult;
            let grades = [], gradesResult;
            let lengths = [], lengthsResult;
            let landOwners = [], landOwnersResult;
            let woodLots = [], woodLotsResult;
            let receivingSites = [], receivingSitesResult;
            let customers = [], customersResult;
            let glAccounts = [], glAccountsResult;

            switch (endPoint) {
                case 'contractgroups':
                    console.log("Contract Groups selected");
                    setDisplayGrid(true);
                    setQueryFilters({});
                    setMenuSelected('contractgroups');
                    break;
                case 'woodcontracts':

                    if (e && e.target && e.target.innerText) {
                        console.log("Wood Contracts selected");
                        setQueryFilters({
                            name: endPoint,
                            selected: true,
                            contractGroupId: '',
                            contractGroupName: '',
                            landOwnerId: '',
                            landOwnerName: '',
                            receivingSiteId: '',
                            receivingSiteName: ''
                        });
                        setMenuSelected('woodcontracts');
                        setDisplayGrid(false);
                        setHideReceivingSites(false);
                        setHideContractGroups(true);
                        setHideLandOwners(true);

                        let rcvs = await getAllData('/receivingsites');
                        console.log("rcvs: " + JSON.stringify(rcvs));
                        setReceivingSitesLookup(rcvs.success);
                    } else {

                        setHideReceivingSites(true);
                        setHideContractGroups(true);
                        setHideLandOwners(true);
                    }

                    break;
                case 'stumpagerates':
                    if (e && e.target && e.target.innerText) {

                        console.log("Stumpage Rates selected");
                        setQueryFilters({
                            name: endPoint, selected: true, landOwnerId: '',
                            landOwnerName: '', woodLotId: '', woodLotName: ''
                        });
                        setMenuSelected('stumpagerates');
                        setDisplayGrid(false);
                        setHideReceivingSites(true);
                        setHideContractGroups(true);
                        setHideLandOwners(false);
                        setHideWoodLots(true);

                        let lO1s = await getAllData('/landowners');
                        console.log("LOs: " + JSON.stringify(lO1s));

                        setLandOwnersLookup(lO1s.success);

                    } else {
                        setHideReceivingSites(true);
                        setHideContractGroups(true);
                        setHideLandOwners(true);
                        setHideWoodLots(true);
                    }


                    break;
                case 'laborrates':
                    if (e && e.target && e.target.innerText) {

                        console.log("Labor Rates selected");
                        setQueryFilters({
                            name: endPoint,
                            selected: true,
                            laborResourceId: '',
                            laborResourceName: '',
                            landOwnerId: '',
                            landOwnerName: '',
                            woodLotId: '',
                            woodLotName: ''
                        });
                        setMenuSelected('laborrates');
                        setDisplayGrid(false);
                        setHideReceivingSites(true);
                        setHideContractGroups(true);
                        setHideLandOwners(true);
                        setHideWoodLots(true);
                        setHideLaborResourceTypes(false);

                        let lrTypes = await getAllData('/laborresourcetypes');
                        console.log("Labor Resource Types: " + JSON.stringify(lrTypes));

                        setLaborResourceTypesLookup(lrTypes.success);

                    } else {
                        setHideReceivingSites(true);
                        setHideContractGroups(true);
                        setHideLandOwners(true);
                        setHideWoodLots(true);
                        setHideLaborResourceTypes(true);
                    }

                    break;
                case 'intercompanyrates':

                    if (e && e.target && e.target.innerText) {
                        console.log("Inter Company Rates selected");
                        setQueryFilters({
                            name: endPoint,
                            selected: true,
                            landOwnerId: '',
                            landOwnerName: '',
                            woodLotId: '',
                            woodLotName: '',
                            receivingSiteId: '',
                            receivingSiteName: ''
                        })
                        setMenuSelected('intercompanyrates');
                        setDisplayGrid(false);
                        setHideReceivingSites(false);
                        setHideLandOwners(true);
                        setHideWoodLots(true);

                        let rcvs = await getAllData('/receivingsites');
                        console.log("rcvs: " + JSON.stringify(rcvs));
                        setReceivingSitesLookup(rcvs.success);
                    } else {

                        setHideReceivingSites(true);
                        setHideContractGroups(true);
                        setHideLandOwners(true);
                        setHideWoodLots(true);
                    }

                    break;
                default:
                    console.log("Endpoint: " + endPoint + " not found.");
                    return;
            }


            if (e && e.target && e.target.innerText) {
                if (endPoint === 'contractgroups') {
                    result = await getAllData('/' + endPoint);
                } else {
                    result = await lookupData('/lookup' + endPoint, '{"id": "1"}', '[["id", "ASC"]]');
                }
            } else {


                if (endPoint === 'woodcontracts') {
                    console.log("Query Filters Ref: " + JSON.stringify(queryFiltersRef));
                    let filter;
                    if (queryFiltersRef.current.landOwnerId) {
                        filter = '{"contractGroupId": "' + queryFiltersRef.current.contractGroupId + '",' +
                            '"receivingSiteId": "' + queryFiltersRef.current.receivingSiteId + '",' +
                            '"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}';
                    } else {
                        filter = '{"contractGroupId": "' + queryFiltersRef.current.contractGroupId + '",' +
                            '"receivingSiteId": "' + queryFiltersRef.current.receivingSiteId + '"}';
                    }
                    let orderBy = '[["effDate", "DESC"]]';

                    result = await lookupData('/lookupwoodcontracts', filter, orderBy);

                    console.log("Wood Contracts Result: " + JSON.stringify(result));

                } else if (endPoint === 'stumpagerates') {

                    console.log("Query Filters Ref: " + JSON.stringify(queryFiltersRef));
                    let filter;
                    if (queryFiltersRef.current.landOwnerId && !queryFiltersRef.current.woodLotId) {
                        filter = '{"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}';
                    } else {
                        filter = '{"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '",' +
                            '"woodLotId": "' + queryFiltersRef.current.woodLotId + '"}';
                    }

                    let orderBy = '[["effDate", "DESC"]]';

                    result = await lookupData('/lookupstumpagerates', filter, orderBy);

                    console.log("Stumpage Rates Result: " + JSON.stringify(result));

                } else if (endPoint === 'laborrates') {

                    console.log("Query Filters Ref: " + JSON.stringify(queryFiltersRef));
                    let filter;
                    if (queryFiltersRef.current.landOwnerId && !queryFiltersRef.current.woodLotId) {
                        filter = '{"laborResourceTypeId": "' + queryFiltersRef.current.laborResourceTypeId +
                            '","landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}';
                    } else {
                        filter = '{"laborResourceTypeId": "' + queryFiltersRef.current.laborResourceTypeId +
                            '","landOwnerId": "' + queryFiltersRef.current.landOwnerId + '",' +
                            '"woodLotId": "' + queryFiltersRef.current.woodLotId + '"}';
                    }

                    let orderBy = '[["effDate", "DESC"]]';

                    result = await lookupData('/lookuplaborrates', filter, orderBy);

                    //console.log("Labor Rates Result: " + JSON.stringify(result));

                } else if (endPoint === 'intercompanyrates') {
                    console.log("Query Filters Ref: " + JSON.stringify(queryFiltersRef));
                    let filter;
                    if (queryFiltersRef.current.woodLotId) {
                        filter = '{"woodLotId": "' + queryFiltersRef.current.woodLotId + '",' +
                            '"receivingSiteId": "' + queryFiltersRef.current.receivingSiteId + '",' +
                            '"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}';
                    } else {
                        filter = '{"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '",' +
                            '"receivingSiteId": "' + queryFiltersRef.current.receivingSiteId + '"}';
                    }
                    let orderBy = '[["effDate", "DESC"]]';

                    result = await lookupData('/lookupintercompanyrates', filter, orderBy);

                    console.log("Inter Company Rates Result: " + JSON.stringify(result));

                }
            }

            if (result.error) {
                console.dir(e);
                setRowData([{"error": "Unable to fetch data from table! " + result.error}]);
                columnDefs.push({field: "error"});
                setColumnDefs(columnDefs);
            } else {


                _transformResult(result, endPoint);

                // Get Lookup data to apply to columns
                if (endPoint === 'woodcontracts' || endPoint === 'laborrates' || endPoint === 'stumpagerates' ||
                    endPoint === 'intercompanyrates') {

                    if (queryFiltersRef.current.contractGroupId) {

                        contractGroupsResult = await lookupData('/lookupcontractgroups',
                            '{"id": "' + queryFiltersRef.current.contractGroupId + '"}', '[["name", "ASC"]]');
                        for (const contractgroup of contractGroupsResult.success) {
                            if (!contractgroup.inactive) {
                                contractGroups.push(contractgroup.name);
                            }
                        }

                    } else {

                        contractGroupsResult = await getAllData('/contractgroups');
                        for (const contractgroup of contractGroupsResult.success) {
                            if (!contractgroup.inactive) {
                                contractGroups.push(contractgroup.name);
                            }
                        }
                    }

                    contractGroups = contractGroups.sort();

                    if (endPoint === 'intercompanyrates') {
                        companiesResult = await lookupData('/lookupcompanies', '{"companyTypeId": 1}',
                            '[["name", "ASC"]]');
                    } else {
                        companiesResult = await getAllData('/companies');
                    }

                    for (const company of companiesResult.success) {
                        if (!company.inactive) {
                            companies.push(company.name);
                        }
                    }

                    companies = companies.sort();

                    if (queryFiltersRef.current.contractGroupId) {

                        if (!queryFiltersRef.current.landOwnerId) {
                            let lcResult = await lookupData('/lookuplandownercontractgroups',
                                '{"contractGroupId": "' + queryFiltersRef.current.contractGroupId + '"}', '[["id", "ASC"]]');
                            for (const lcObj of lcResult.success) {
                                if (!lcObj.LandOwner.inactive) {
                                    landOwners.push(lcObj.LandOwner.name);
                                }
                            }
                        } else {

                            let loResult = await lookupData('/lookuplandowners',
                                '{"id": "' + queryFiltersRef.current.landOwnerId + '"}', '[["name", "ASC"]]');

                            console.log("loResult: " + JSON.stringify(loResult));

                            for (const loObj of loResult.success) {
                                if (!loObj.inactive) {
                                    landOwners.push(loObj.name);
                                }
                            }
                        }

                    } else {

                        if (queryFiltersRef.current.landOwnerId) {

                            let loResult = await lookupData('/lookuplandowners',
                                '{"id": "' + queryFiltersRef.current.landOwnerId + '"}', '[["name", "ASC"]]');
                            for (const loObj of loResult.success) {
                                if (!loObj.inactive) {
                                    landOwners.push(loObj.name);
                                }
                            }
                        } else {

                            landOwnersResult = await getAllData('/lookuplandowners');
                            for (const landOwner of landOwnersResult.success) {
                                if (!landOwner.inactive) {
                                    landOwners.push(landOwner.name);
                                }
                            }
                        }
                    }


                    landOwners = landOwners.sort();
                    landOwners.push("NONE");

                    laborResourceTypesResult = await getAllData('/laborresourcetypes');
                    for (const laborResourceType of laborResourceTypesResult.success) {
                        laborResourceTypes.push(laborResourceType.name);
                    }

                    laborResourceTypes = laborResourceTypes.sort();

                    companyTypesResult = await getAllData('/companytypes');
                    for (const companyType of companyTypesResult.success) {
                        companyTypes.push(companyType.name);
                    }

                    companyTypes = companyTypes.sort();
                    companyTypes.push("NONE");

                    laborResourceSubTypesResult = await getAllData('/laborresourcesubtypes');
                    for (const laborResourceSubType of laborResourceSubTypesResult.success) {
                        laborResourceSubTypes.push(laborResourceSubType.name);
                    }

                    laborResourceSubTypes = laborResourceSubTypes.sort();
                    laborResourceSubTypes.push("NONE");

                    unitsResult = await getAllData('/units');
                    setUnitsLookup(unitsResult.success);
                    for (const unit of unitsResult.success) {
                        units.push(unit.name);
                    }

                    units = units.sort();

                    productsResult = await getAllData('/products');
                    setProductsLookup(productsResult.success);
                    for (const product of productsResult.success) {
                        products.push(product.name);
                    }

                    products = products.sort();
                    if (endPoint === 'laborrates' || endPoint === 'intercompanyrates') {
                        products.push("NONE");
                    }

                    speciesResult = await getAllData('/species');
                    setSpeciesLookup(speciesResult.success);
                    for (const specie of speciesResult.success) {
                        species.push(specie.name);
                    }

                    species = species.sort();
                    if (endPoint === 'laborrates' || endPoint == 'intercompanyrates') {
                        species.push("NONE");
                    }

                    gradesResult = await getAllData('/grades');
                    setGradesLookup(gradesResult.success);
                    for (const grade of gradesResult.success) {
                        grades.push(grade.name);
                    }

                    grades = grades.sort();
                    grades.push("NONE");

                    lengthsResult = await getAllData('/lengths');
                    setLengthsLookup(lengthsResult.success);
                    for (const length of lengthsResult.success) {
                        lengths.push(length.name);
                    }

                    lengths = lengths.sort();
                    lengths.push("NONE");

                    customersResult = await getAllData('/customers');
                    setCustomersLookup(customersResult.success);
                    for (const customer of customersResult.success) {
                        customers.push(customer.name);
                    }
                    customers = customers.sort();

                    glAccountsResult = await getAllData('/glaccounts');
                    setGlAccountsLookup(glAccountsResult.success);
                    for (const glAccount of glAccountsResult.success) {
                        glAccounts.push(glAccount.name);
                    }
                    glAccounts = glAccounts.sort();


                    //if (queryFiltersRef.current.landOwnerId && endPoint !== 'intercompanyrates') {
                    if (queryFiltersRef.current.landOwnerId) {

                        if (!queryFiltersRef.current.woodLotId) {
                            let wlResult = await lookupData('/lookupwoodlots',
                                '{"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}', '[["name", "ASC"]]');
                            for (const wlObj of wlResult.success) {
                                if (!wlObj.inactive) {
                                    woodLots.push(wlObj.name);
                                }
                            }
                        } else {
                            let wlResult = await lookupData('/lookupwoodlots',
                                '{"id": "' + queryFiltersRef.current.woodLotId + '"}', '[["name", "ASC"]]');
                            for (const wlObj of wlResult.success) {
                                if (!wlObj.inactive) {
                                    woodLots.push(wlObj.name);
                                }
                            }
                        }

                    } else {

                        woodLotsResult = await getAllData('/woodlots');
                        for (const woodLot of woodLotsResult.success) {
                            woodLots.push(woodLot.name);
                        }
                    }

                    woodLots = woodLots.sort();
                    woodLots.push("NONE");

                    if (queryFiltersRef.current.receivingSiteId) {

                        let rsResult = await lookupData('/lookupreceivingsites',
                            '{"id": "' + queryFiltersRef.current.receivingSiteId + '"}', '[["name", "ASC"]]');
                        for (const rsObj of rsResult.success) {
                            if (!rsObj.inactive) {
                                receivingSites.push(rsObj.name);
                            }
                        }

                    } else {

                        receivingSitesResult = await getAllData('/receivingsites');
                        for (const receivingSite of receivingSitesResult.success) {
                            receivingSites.push(receivingSite.name);
                        }
                    }

                    receivingSites = receivingSites.sort();
                    receivingSites.push("NONE");

                    if (queryFiltersRef.current.laborResourceTypeId) {

                        let lrResult = await lookupData('/lookuplaborresources',
                            '{"laborResourceTypeId": "' + queryFiltersRef.current.laborResourceTypeId + '"}', '[["name", "ASC"]]');
                        for (const rsObj of lrResult.success) {
                            if (!rsObj.inactive) {
                                laborResources.push(rsObj.name);
                            }
                        }

                    } else {

                        let lrResult = await getAllData('/laborresources');
                        for (const rsObj of lrResult.success) {
                            laborResources.push(rsObj.name);
                        }
                    }

                    laborResources = laborResources.sort();
                    laborResources.push("NONE");
                }

                if (result.success.length < 1) {
                    console.log("NO DATA: No data available from query");
                    setRowData([{"NO DATA": "No data available from query"}]);
                    setColumnDefs([{field: "NO DATA"}]);
                    return;
                }


                let resultObj = [...result.success];
                setRowData(resultObj);

                let columnFields = Object.keys(resultObj[0]);
                for (let col in columnFields) {

                    if (columnFields[col] == 'ContractGroup' && queryFiltersRef.current.contractGroupId) {
                        continue;
                    }

                    if (columnFields[col] == 'ReceivingSite' && queryFiltersRef.current.receivingSiteId) {
                        continue;
                    }

                    if (columnFields[col] == 'LandOwner' && queryFiltersRef.current.landOwnerId) {
                        continue;
                    }

                    if (columnFields[col] == 'WoodLot' && queryFiltersRef.current.woodLotId) {
                        continue;
                    }

                    if (columnFields[col] == 'LaborResourceType' && queryFiltersRef.current.laborResourceTypeId) {
                        continue;
                    }

                    if ((columnFields[col] !== "createdAt") && (columnFields[col] !== "updatedAt")) {
                        if (columnFields[col] === 'id') {
                            columnDefs.push({
                                field: columnFields[col],
                                sortable: true,
                                pinned: "left",
                                flex: "initial",
                                maxWidth: 100
                            })
                        } else if (columnFields[col] === 'inactive') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                flex: "initial",
                                filter: true,
                                pinned: "right",
                                maxWidth: 100,
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: ['false', 'true']}
                            })
                        } else if (columnFields[col] === 'exportGLive') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                filter: true,
                                sortable: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: ['false', 'true']}
                            })
                        } else if (columnFields[col] === 'ContractGroup') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                filter: true,
                                sortable: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: contractGroups}
                            })
                        } else if (columnFields[col] === 'Company') {

                            if (endPoint === 'intercompanyrates') {

                                columnDefs.push({
                                    field: columnFields[col],
                                    editable: true,
                                    sortable: true,
                                    pinned: "left",
                                    filter: true,
                                    flex: "initial",
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {values: companies}
                                })
                            } else {
                                columnDefs.push({
                                    field: columnFields[col],
                                    editable: true,
                                    sortable: true,
                                    filter: true,
                                    flex: "initial",
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {values: companies}
                                })
                            }
                        } else if (columnFields[col] === 'CompanyType') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: companyTypes}
                            })
                        } else if (columnFields[col] === 'LandOwner') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: landOwners}
                            })
                        } else if (columnFields[col] === 'LaborResource') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                filter: true,
                                sortable: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: laborResources}
                            })
                        } else if (columnFields[col] === 'LaborResourceType') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: laborResourceTypes}
                            })
                        } else if (columnFields[col] === 'LaborResourceSubType') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: laborResourceSubTypes}
                            })
                        } else if (columnFields[col] === 'Unit') {
                            columnDefs.push({
                                field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: units}
                            })
                        } else if (columnFields[col] === 'Product') {
                            if (endPoint === 'woodcontracts' || endPoint === 'stumpagerates') {
                                columnDefs.push({
                                    field: columnFields[col],
                                    pinned: "left",
                                    filter: true,
                                    editable: true,
                                    sortable: true,
                                    flex: "initial",
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {values: products}
                                })

                            } else {
                                columnDefs.push({
                                    field: columnFields[col],
                                    filter: true,
                                    editable: true,
                                    sortable: true,
                                    flex: "initial",
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {values: products}
                                })
                            }
                        } else if (columnFields[col] === 'Specie') {
                            if (endPoint === 'woodcontracts' || endPoint === 'stumpagerates') {
                                columnDefs.push({
                                    field: columnFields[col],
                                    pinned: "left",
                                    filter: true,
                                    editable: true,
                                    sortable: true,
                                    flex: "initial",
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {values: species}
                                })
                            } else {
                                columnDefs.push({
                                    field: columnFields[col],
                                    filter: true,
                                    editable: true,
                                    sortable: true,
                                    flex: "initial",
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {values: species}
                                })
                            }
                        } else if (columnFields[col] === 'Grade') {
                            columnDefs.push({
                                field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: grades}
                            })
                        } else if (columnFields[col] === 'Length') {
                            columnDefs.push({
                                field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: lengths}
                            })
                        } else if (columnFields[col] === 'WoodLot') {
                            columnDefs.push({
                                field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: woodLots}
                            })
                        } else if (columnFields[col] === 'ReceivingSite') {
                            columnDefs.push({
                                field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: receivingSites}
                            })
                        } else if (columnFields[col] === 'Customer') {
                            columnDefs.push({
                                field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: customers}
                            })
                        } else if (columnFields[col] === 'GlAccount') {
                            columnDefs.push({
                                field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: glAccounts}
                            })
                        } else {
                            columnDefs.push({
                                field: columnFields[col],
                                filter: true,
                                editable: true,
                                sortable: true,
                                flex: "initial"
                            })
                        }
                    }
                }

                setColumnDefs(columnDefs);

            }
        } catch (error) {
            console.log("ERROR: Unable to fetch data from table! " + error)
            setRowData([{"error": "Unable to fetch data from table! " + error}]);
            setColumnDefs([{field: "error"}]);
        }

    };

    let handleEdit = async e => {
        try {

            // Update updated at
            e.data.updatedAt = Date.now();

            // Add required attributes (missing due to filtering)

            if (queryFiltersRef.current.laborResourceTypeName) {
                e.data.LaborResourceType = queryFiltersRef.current.laborResourceTypeName;
            }

            if (queryFiltersRef.current.receivingSiteName) {
                e.data.ReceivingSite = queryFiltersRef.current.receivingSiteName;
            }

            if (queryFiltersRef.current.contractGroupName) {
                e.data.ContractGroup = queryFiltersRef.current.contractGroupName;
            }
            if (queryFiltersRef.current.landOwnerName) {
                e.data.LandOwner = queryFiltersRef.current.landOwnerName;
            }

            if (queryFiltersRef.current.woodLotName && queryFiltersRef.current.woodLotId != 0) {
                e.data.WoodLot = queryFiltersRef.current.woodLotName.split(':')[0];
            }


            console.log("Cell data to be changed: " + JSON.stringify(e.data));

            let result;
            let endPoint = tableHeading.toLowerCase().replace(/\s/g, '');

            if (e.data.id) {
                result = await updateData('/' + endPoint, e.data);
                if (result.error) {
                    console.log("Update failed: " + result.error);
                    setTableError(result.error);
                    e.api.undoCellEditing();
                    setTimeout(
                        () => setTableError(''),
                        5000
                    );
                }
            } else {
                e.data.createdAt = Date.now();
                e.data.updatedAt = e.data.createdAt;


                result = await insertData('/' + endPoint, e.data);

                if (result.success) {

                    let refreshResult, filter, orderBy;

                    if (endPoint === 'woodcontracts' && queryFiltersRef.current.contractGroupId && queryFiltersRef.current.receivingSiteId) {
                        if (queryFiltersRef.current.landOwnerId) {
                            filter = '{"contractGroupId": "' + queryFiltersRef.current.contractGroupId + '",' +
                                '"receivingSiteId": "' + queryFiltersRef.current.receivingSiteId + '",' +
                                '"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}';
                        } else {
                            filter = '{"contractGroupId": "' + queryFiltersRef.current.contractGroupId + '",' +
                                '"receivingSiteId": "' + queryFiltersRef.current.receivingSiteId + '"}';
                        }

                        orderBy = '[["effDate", "DESC"]]';

                        refreshResult = await lookupData('/lookupwoodcontracts', filter, orderBy);

                    } else if (endPoint == 'laborrates' && queryFiltersRef.current.laborResourceTypeId &&
                        queryFiltersRef.current.landOwnerId) {
                        if (queryFiltersRef.current.woodLotId) {
                            filter = '{"laborResourceTypeId": "' + queryFiltersRef.current.laborResourceTypeId + '",' +
                                '"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '",' +
                                '"woodLotId": "' + queryFiltersRef.current.woodLotId + '"}';
                        } else {
                            filter = '{"laborResourceTypeId": "' + queryFiltersRef.current.laborResourceTypeId + '",' +
                                '"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}';
                        }

                        orderBy = '[["effDate", "DESC"]]';
                        refreshResult = await lookupData('/lookuplaborrates', filter, orderBy);

                    } else if (endPoint == 'stumpagerates' && queryFiltersRef.current.landOwnerId &&
                        queryFiltersRef.current.woodLotId) {
                        if (queryFiltersRef.current.woodLotId) {
                            filter = '{"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '",' +
                                '"woodLotId": "' + queryFiltersRef.current.woodLotId + '"}';
                        } else {
                            filter = '{"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}';
                        }

                        orderBy = '[["effDate", "DESC"]]';

                        refreshResult = await lookupData('/lookupstumpagerates', filter, orderBy);

                    } else if (endPoint == 'intercompanyrates' && queryFiltersRef.current.receivingSiteId &&
                        queryFiltersRef.current.landOwnerId) {

                        if (queryFiltersRef.current.woodLotId) {

                            filter = '{"receivingSiteId": "' + queryFiltersRef.current.receivingSiteId + '",' +
                                '"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '",' +
                                '"woodLotId": "' + queryFiltersRef.current.woodLotId + '"}';


                        } else {
                            filter = '{"receivingSiteId": "' + queryFiltersRef.current.receivingSiteId + '",' +
                                '"landOwnerId": "' + queryFiltersRef.current.landOwnerId + '"}';
                        }

                        orderBy = '[["effDate", "DESC"]]';
                        refreshResult = await lookupData('/lookupintercompanyrates', filter, orderBy);

                    } else {

                        refreshResult = await getAllData('/' + endPoint);
                    }


                    _transformResult(refreshResult, endPoint);

                    let newRowData = [...refreshResult.success];
                    setRowData(newRowData);
                } else {
                    console.log("Insert failed: " + result.error);
                    setTableError(result.error);
                    e.api.undoCellEditing();
                    setTimeout(
                        () => setTableError(''),
                        5000
                    );
                }
            }

        } catch (error) {
            console.log("Failed to update cell data: " + error);
            setTableError(error);
            e.api.undoCellEditing();
        }
    };

    let handleChange = async (input, rate, e) => {

        let filter, orderBy, newObj = {}, los, cgroups, wls, nameString;

        switch (rate) {
            case 'woodcontracts':

                newObj[input] = e.target.value;
                nameString = input.substr(0, input.length - 2) + "Name";
                newObj[nameString] = e.nativeEvent.target.innerText;
                setQueryFilters({...queryFilters, ...newObj});

                if (input === 'contractGroupId') {
                    filter = '{"contractGroupId": ' + e.target.value + '}';
                    orderBy = '[["id", "ASC"]]';

                    los = await setLookups('lookuplandownercontractgroups', filter, orderBy);

                    console.log("los: " + JSON.stringify(los));
                    los.push({id: 0, name: 'NONE'});

                    setLandOwnersLookup(los);

                    setHideContractGroups(true);
                    setHideLandOwners(false);
                    setHideReceivingSites(true);

                } else if (input === 'receivingSiteId') {

                    cgroups = await getAllData('/contractgroups');

                    setContractGroupsLookup(cgroups.success);

                    setHideReceivingSites(true);
                    setHideContractGroups(false);
                    setHideLandOwners(true);
                } else {
                    console.log("Done setting conditions for Wood Contract Query");

                    // Lookup Wood Lots by either Land Owner or Contract Group
                    if (queryFiltersRef.current.landOwnerId) {
                        filter = '{"landOwnerId": ' + queryFiltersRef.current.landOwnerId + '}';
                        orderBy = '[["name", "ASC"]]';

                        let wls = await lookupData('/lookupwoodlots', filter, orderBy);

                        setWoodLotsLookup(wls.success);
                    }

                    setHideReceivingSites(true);
                    setHideContractGroups(true);
                    setHideLandOwners(true);
                    setDisplayGrid(true);

                    handleClick(1, 'woodcontracts')

                }
                break;
            case 'stumpagerates':

                newObj[input] = e.target.value;
                nameString = input.substr(0, input.length - 2) + "Name";
                newObj[nameString] = e.nativeEvent.target.innerText;
                setQueryFilters({...queryFilters, ...newObj});

                if (input === 'landOwnerId') {
                    filter = '{"landOwnerId": ' + e.target.value + '}';
                    orderBy = '[["name", "ASC"]]';

                    wls = await lookupData('/lookupwoodlots', filter, orderBy);

                    console.log("wls: " + JSON.stringify(wls.success));
                    wls.success.push({id: 0, name: 'NONE', description: 'No Filter'});

                    setWoodLotsLookup(wls.success);

                    setHideContractGroups(true);
                    setHideLandOwners(true);
                    setHideWoodLots(false);
                    setHideReceivingSites(true);

                } else {
                    console.log("Done setting conditions for Stumpage Rate Query");


                    setHideReceivingSites(true);
                    setHideContractGroups(true);
                    setHideLandOwners(true);
                    setDisplayGrid(true);

                    handleClick(3, 'stumpagerates')

                }
                break;
            case 'laborrates':
                newObj[input] = e.target.value;
                nameString = input.substr(0, input.length - 2) + "Name";
                newObj[nameString] = e.nativeEvent.target.innerText;
                setQueryFilters({...queryFilters, ...newObj});

                if (input === 'laborResourceTypeId') {
                    //filter = '{"laborResourceTypeId": ' + e.target.value + '}';
                    filter = '{}';
                    orderBy = '[["name", "ASC"]]';

                    los = await lookupData('/lookuplandowners', filter, orderBy);

                    console.log("los: " + JSON.stringify(los.success));

                    setLandOwnersLookup(los.success);

                    setHideContractGroups(true);
                    setHideLandOwners(false);
                    setHideLaborResourceTypes(true);
                    setHideWoodLots(true);
                    setHideReceivingSites(true);

                } else if (input === 'landOwnerId') {
                    filter = '{"landOwnerId": ' + e.target.value + '}';
                    orderBy = '[["name", "ASC"]]';

                    wls = await lookupData('/lookupwoodlots', filter, orderBy);

                    console.log("wls: " + JSON.stringify(wls.success));
                    wls.success.push({id: 0, name: 'NONE', description: 'No Filter'});

                    setWoodLotsLookup(wls.success);

                    setHideContractGroups(true);
                    setHideLandOwners(true);
                    setHideWoodLots(false);
                    setHideReceivingSites(true);

                } else {
                    console.log("Done setting conditions for Labor Rate Query");

                    // Lookup Wood Lots by either Land Owner
                    if (queryFiltersRef.current.landOwnerId) {
                        filter = '{"landOwnerId": ' + queryFiltersRef.current.landOwnerId + '}';
                        orderBy = '[["name", "ASC"]]';

                        let wls = await lookupData('/lookupwoodlots', filter, orderBy);
                        setWoodLotsLookup(wls.success);
                    }

                    // Set CompanyTypes Lookup
                    let ctypes = await lookupData('/companytypes');
                    setCompanyTypesLookup(ctypes.success);

                    // Set Receiving Sites Lookup
                    let rsites = await lookupData('/receivingsites');
                    setReceivingSitesLookup(rsites.success);

                    // Set Labor Resource Subtypes
                    let lrstypes = await lookupData('/laborresourcesubtypes');
                    setLaborResourceSubTypesLookup(lrstypes.success);

                    // Set Labor Resources

                    filter = '{"laborResourceTypeId": ' + queryFiltersRef.current.laborResourceTypeId + '}';
                    orderBy = '[["name", "ASC"]]';
                    let lrs = await lookupData('/lookuplaborresources', filter, orderBy);
                    setLaborResourcesLookup(lrs.success);

                    setHideReceivingSites(true);
                    setHideContractGroups(true);
                    setHideLandOwners(true);
                    setDisplayGrid(true);

                    handleClick(2, 'laborrates')

                }


                break;
            case 'intercompanyrates':
                newObj[input] = e.target.value;
                nameString = input.substr(0, input.length - 2) + "Name";
                newObj[nameString] = e.nativeEvent.target.innerText;
                setQueryFilters({...queryFilters, ...newObj});

                if (input === 'landOwnerId') {
                    filter = '{"landOwnerId": ' + e.target.value + '}';
                    orderBy = '[["name", "ASC"]]';

                    wls = await lookupData('/lookupwoodlots', filter, orderBy);

                    console.log("wls: " + JSON.stringify(wls.success));
                    wls.success.push({id: 0, name: 'NONE', description: 'No Filter'});

                    setWoodLotsLookup(wls.success);

                    setHideContractGroups(true);
                    setHideLandOwners(true);
                    setHideWoodLots(false);
                    setHideReceivingSites(true);

                } else if (input === 'receivingSiteId') {

                    los = await getAllData('/landowners');

                    setLandOwnersLookup(los.success);

                    setHideReceivingSites(true);
                    setHideContractGroups(true);
                    setHideLandOwners(false);

                } else {
                    console.log("Done setting conditions for Inter Company Rates Query");

                    // Set Company Lookups
                    filter = '{"companyTypeId": 1}';
                    orderBy = '[["name", "ASC"]]';

                    let cs = await lookupData('/lookupcompanies', filter, orderBy);
                    setCompaniesLookup(cs.success);

                    setHideReceivingSites(true);
                    setHideContractGroups(true);
                    setHideLandOwners(true);
                    setDisplayGrid(true);

                    handleClick(4, 'intercompanyrates')

                }


                break;
            default:
                console.log("Invalid rate: " + rate);
                return;
        }

    };

    const onGridReady = (params) => {

        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        params.api.showLoadingOverlay();

        switch (menuIndex) {
            case 1: // Wood Ticket Contracts
                params.columnApi.moveColumns(
                    ['Product', 'Specie', 'Grade', 'Length', 'rate', 'Unit',
                        'effDate', 'expDate', 'Customer', 'GlAccount', 'contractId', 'certifiedLine1',
                        'certifiedLine2', 'isGardnerLiveMill', 'LandOwner', 'WoodLot', 'userName'], 1);
                break;
            case 2: // Labor Rates
                if (queryFiltersRef.current.laborResourceTypeId === 1) {
                    params.columnApi.setColumnPinned('LaborResource', 'left');
                    params.columnApi.moveColumns(
                        ['Product', 'Specie', 'Grade', 'Length', 'rate', 'Unit',
                            'effDate', 'expDate', 'WoodLot', 'ReceivingSite'], 1);

                } else {
                    params.columnApi.setColumnPinned('LaborResourceSubType', 'left');
                    params.columnApi.moveColumns(
                        ['ReceivingSite', 'rate', 'Unit', 'effDate', 'expDate',
                            'WoodLot', 'Product', 'Specie', 'Grade', 'Length'], 1);
                }
                break;
            case 3: // Stumpage Rates
                params.columnApi.moveColumns(
                    ['Product', 'Specie', 'Grade', 'Length', 'rate', 'Unit',
                        'effDate', 'expDate', 'WoodLot', 'millPct'], 1);
                break;
            case 4: // InterCompany Rates
                params.columnApi.moveColumns(
                    ['Product', 'Specie', 'Grade', 'Length', 'rate', 'Unit',
                        'effDate', 'expDate', 'Customer', 'GlAccount', 'Company', 'WoodLot'], 1);
                break;
            default:

        } // End switch
    };

    const handleFormChange = async (input, e) => {
        //e.preventDefault();

        switch (input) {
            case 'contractGroupName':
                setContactGroupName(e.target.value);
                break;
            case 'exportGLive':
                setExportGLive(e.target.checked);
                break;
            case 'company':
                setCompanyId(e.target.value);
                break;
            case 'companytype':
                setCompanyTypeId(e.target.value);
                break;
            case 'woodlot':
                setWoodLotId(e.target.value);
                break;
            case 'product':
                setProductId(e.target.value);
                break;
            case 'specie':
                setSpecieId(e.target.value);
                break;
            case 'grade':
                setGradeId(e.target.value);
                break;
            case 'length':
                setLengthId(e.target.value);
                break;
            case 'rate':
                setRate(e.target.value);
                break;
            case 'unit':
                setUnitId(e.target.value);
                break;
            case 'effDate':
                setEffDate(e.target.value);
                break;
            case 'expDate':
                setExpDate(e.target.value);
                break;
            case 'userName':
                setUserName(e.target.value);
                break;
            case 'contractId':
                setContractId(e.target.value);
                break;
            case 'certifiedLine1':
                setCertifiedLine1(e.target.value);
                break;
            case 'certifiedLine2':
                setCertifiedLine2(e.target.value);
                break;
            case 'gardnerLiveMill':
                setGardnerLiveMill(e.target.value);
                break;
            case 'coNumToPay':
                setCoNumToPay(e.target.value);
                break;
            case 'millPct':
                setMillPct(e.target.value);
                break;
            case 'customer':
                setCustomerId(e.target.value);
                break;
            case 'glAccount':
                setGlAccountId(e.target.value);
                break;
            case 'laborresourcesubtype':
                setLaborResourceSubTypeId(e.target.value);
                break;
            case 'laborresource':
                setLaborResourceId(e.target.value);
                break;
            case 'receivingsite':
                setReceivingSiteId(e.target.value);
                break;
            case 'contractgroup':
                setContractGroupId(e.target.value);

                let landOwner = {}, landOwners = [];
                let locgs = await lookupData('/lookuplandownercontractgroups',
                    '{"contractGroupId": "' + e.target.value + '"}', '[["landOwnerId", "ASC"]]');

                for (const locg of locgs.success) {

                    landOwner.id = locg.landOwnerId;
                    landOwner.name = locg.LandOwner.name;
                    landOwners.push(JSON.parse(JSON.stringify(landOwner)));
                }
                setLandOwnersLookup(landOwners);
                setWoodLotsLookup([]);

                break;
            case 'landowner':
                setLandOwnerId(e.target.value);

                let woodLots = await lookupData('/lookupwoodlots',
                    '{"landOwnerId": "' + e.target.value + '"}', '[["name", "ASC"]]');

                if (woodLots && woodLots.success) {
                    setWoodLotsLookup(woodLots.success);
                } else {
                    setWoodLotsLookup([]);
                }
                break;
            default:
                console.log("Handle form change input not found.")
        }

    };


    let handleSubmit = async (e) => {
        e.preventDefault();

        let formData = {}, endPoint;

        if (e.target.id === 'create-contractgroup-form') {
            endPoint = 'contractgroups';
            formData.name = contractGroupName;
            formData.exportGLive = exportGLive;
        } else if (e.target.id === 'create-woodcontract-form') {

            endPoint = 'woodcontracts';
            formData.contractGroupId = queryFiltersRef.current.contractGroupId;
            formData.receivingSiteId = queryFiltersRef.current.receivingSiteId;
            if (queryFiltersRef.current.landOwnerId && queryFiltersRef.current.landOwnerId > 0) {
                formData.landOwnerId = queryFiltersRef.current.landOwnerId;
            }
            if (woodLotId && woodLotId > 0) {
                formData.woodLotId = woodLotId;
            }

            formData.productId = productId;
            formData.specieId = specieId;
            if (gradeId && gradeId > 0) {
                formData.gradeId = gradeId;
            }
            if (lengthId && lengthId > 0) {
                formData.lengthId = lengthId;
            }

            formData.unitId = unitId;
            formData.rate = parseFloat(rate).toFixed(2);
            formData.effDate = effDate;
            formData.expDate = expDate;
            formData.userName = userName;
            formData.contractId = contractId;
            formData.certifiedLine1 = certifiedLine1;
            formData.certifiedLine2 = certifiedLine2;
            formData.gardnerLiveMill = gardnerLiveMill;
            formData.customerId = customerId;
            formData.glAccountId = glAccountId;

        } else if (e.target.id === 'create-woodcontract-unfilteredform') {
            endPoint = 'woodcontracts';
            formData.contractGroupId = contractGroupId;
            formData.receivingSiteId = receivingSiteId;
            if (landOwnerId && landOwnerId > 0) {
                formData.landOwnerId = landOwnerId;
            }
            if (woodLotId && woodLotId > 0) {
                formData.woodLotId = woodLotId;
            }

            formData.productId = productId;
            formData.specieId = specieId;
            if (gradeId && gradeId > 0) {
                formData.gradeId = gradeId;
            }
            if (lengthId && lengthId > 0) {
                formData.lengthId = lengthId;
            }

            formData.unitId = unitId;
            formData.rate = parseFloat(rate).toFixed(2);
            formData.effDate = effDate;
            formData.expDate = expDate;
            formData.userName = userName;
            formData.contractId = contractId;
            formData.certifiedLine1 = certifiedLine1;
            formData.certifiedLine2 = certifiedLine2;
            formData.gardnerLiveMill = gardnerLiveMill;
            formData.customerId = customerId;
            formData.glAccountId = glAccountId;

        } else if (e.target.id === 'create-laborrate-form') {

            endPoint = 'laborrates';
            formData.laborResourceTypeId = queryFiltersRef.current.laborResourceTypeId;
            formData.landOwnerId = queryFiltersRef.current.landOwnerId;
            if (queryFiltersRef.current.woodLotId && queryFiltersRef.current.woodLotId > 0) {
                formData.woodLotId = queryFiltersRef.current.woodLotId;
            }

            if (productId && productId > 0) {
                formData.productId = productId;
            }
            if (specieId && specieId > 0) {
                formData.specieId = specieId;
            }
            if (gradeId && gradeId > 0) {
                formData.gradeId = gradeId;
            }
            if (lengthId && lengthId > 0) {
                formData.lengthId = lengthId;
            }

            formData.unitId = unitId;
            formData.rate = parseFloat(rate).toFixed(2);
            formData.effDate = effDate;
            formData.expDate = expDate;

            if (companyTypeId && companyTypeId > 0) {
                formData.companyTypeId = companyTypeId;
            }
            if (laborResourceSubTypeId && laborResourceSubTypeId > 0) {
                formData.laborResourceSubTypeId = laborResourceSubTypeId;
            }
            if (laborResourceId && laborResourceId > 0) {
                formData.laborResourceId = laborResourceId;
            }
            if (receivingSiteId && receivingSiteId > 0) {
                formData.receivingSiteId = receivingSiteId;
            }


        } else if (e.target.id === 'create-stumpagerate-form') {

            endPoint = 'stumpagerates';
            formData.landOwnerId = queryFiltersRef.current.landOwnerId;
            if (queryFiltersRef.current.woodLotId && queryFiltersRef.current.woodLotId > 0) {
                formData.woodLotId = queryFiltersRef.current.woodLotId;
            }

            formData.productId = productId;
            formData.specieId = specieId;

            if (gradeId && gradeId > 0) {
                formData.gradeId = gradeId;
            }
            if (lengthId && lengthId > 0) {
                formData.lengthId = lengthId;
            }

            formData.unitId = unitId;
            formData.rate = parseFloat(rate).toFixed(2);
            formData.effDate = effDate;
            formData.expDate = expDate;

            if (millPct && millPct.length > 0) {
                formData.millPct = parseFloat(millPct).toFixed(2);
            }

        } else { //InterCompany Rates

            endPoint = 'intercompanyrates';
            formData.companyId = companyId;
            formData.landOwnerId = queryFiltersRef.current.landOwnerId;
            formData.receivingSiteId = queryFiltersRef.current.receivingSiteId;
            if (queryFiltersRef.current.woodLotId && queryFiltersRef.current.woodLotId > 0) {
                formData.woodLotId = queryFiltersRef.current.woodLotId;
            }

            if (productId && productId > 0) {
                formData.productId = productId;
            }
            if (specieId && specieId > 0) {
                formData.specieId = specieId;
            }
            if (gradeId && gradeId > 0) {
                formData.gradeId = gradeId;
            }
            if (lengthId && lengthId > 0) {
                formData.lengthId = lengthId;
            }

            formData.unitId = unitId;
            formData.rate = parseFloat(rate).toFixed(2);
            formData.effDate = effDate;
            formData.expDate = expDate;
            formData.customerId = customerId;
            formData.glAccountId = glAccountId;
            formData.coNumToPay = coNumToPay;

        }

        console.log("Request Form Data: " + JSON.stringify(formData));

        let result, action = "Inserted";

        try {

            console.log("Inserting new data ...");
            closeModal();
            result = await insertData('/' + endPoint, formData);

            if (result.success) {
                console.log("Insert success: " + JSON.stringify(result.success));

                if (e.target.id === 'create-woodcontract-form') {
                    handleClick(1, 'woodcontracts')
                }

                if (e.target.id === 'create-woodcontract-unfilteredform') {

                    setModalNoFilters(true);

                    let cgroups = await getAllData('/contractgroups');
                    setContractGroupsLookup(cgroups.success);

                    setTimeout(
                        () => {
                            openModal()
                        },
                        2000
                    );
                }

                if (e.target.id === 'create-laborrate-form') {
                    handleClick(2, 'laborrates')
                }

                if (e.target.id === 'create-stumpagerate-form') {
                    handleClick(3, 'stumpagerates')
                }

                if (e.target.id === 'create-intercompanyrate-form') {
                    handleClick(4, 'intercompanyrates')
                }

            } else {
                console.log(action + " failed: " + JSON.stringify(result.error));
                setTableError(result.error);
                setTimeout(
                    () => setTableError(''),
                    5000
                );
            }
        } catch (error) {
            console.log(action + " failed: " + JSON.stringify(error));
            setTableError("ERROR: " + error);
            setTimeout(
                () => setTableError(''),
                5000
            );
        }

    };

    // Uncomment for debugging
    //console.log(JSON.stringify(rowData));
    //console.log(JSON.stringify(columnDefs));

    // Role for Data Admin Access Required
    let regex = /ADMIN|SUPER/;

    if (props.user_details.match(regex)) {
        if (!modalIsOpen) {
            return (
                <main>
                    <form>
                        <h1>Contract and Rate Management</h1>
                        <Grid container>
                            <Grid item xs={3}>
                                <p>Please select a table to edit.</p>
                                <ButtonGroup orientation="vertical" size="large"
                                             aria-label="outlined primary button group">
                                    <Button style={{background: menuColors[0]}} onClick={(e) => handleClick(0, e)}>Contract
                                        Groups</Button>
                                    <Button style={{background: menuColors[1]}} onClick={(e) => handleClick(1, e)}
                                            onKeyPress={(e) => handleKeyDown(1, e)}>Wood
                                        Contracts</Button>
                                    <Button style={{background: menuColors[2]}} onClick={(e) => handleClick(2, e)}>Labor
                                        Rates</Button>
                                    <Button style={{background: menuColors[3]}} onClick={(e) => handleClick(3, e)}>Stumpage
                                        Rates</Button>
                                    <Button style={{background: menuColors[4]}} onClick={(e) => handleClick(4, e)}>InterCompany
                                        Rates</Button>
                                </ButtonGroup>
                            </Grid>

                            {displayGrid &&

                            <Grid item xs={9}>
                                <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                    <h2 className="grid-table-heading">{tableHeading}</h2>
                                    <div style={{
                                        color: "red",
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        padding: "10px"
                                    }}>{tableError}</div>

                                    <div>
                                        {queryFiltersRef.current.laborResourceTypeName &&
                                        '   Labor Resource Type: ' + queryFiltersRef.current.laborResourceTypeName}
                                    </div>
                                    <div>
                                        {queryFiltersRef.current.receivingSiteName &&
                                        '   Receiving Site: ' + queryFiltersRef.current.receivingSiteName}
                                    </div>
                                    <div>
                                        {queryFiltersRef.current.contractGroupName &&
                                        '   Contract Group: ' + queryFiltersRef.current.contractGroupName}
                                    </div>
                                    <div>
                                        {queryFiltersRef.current.landOwnerName &&
                                        '   Land Owner: ' + queryFiltersRef.current.landOwnerName}
                                    </div>
                                    <div>
                                        {queryFiltersRef.current.woodLotName &&
                                        '   Wood Lot: ' + queryFiltersRef.current.woodLotName}
                                    </div>
                                    <br/>
                                    <AddIcon hidden={!menuSelected} onClick={(e) => openModal(e)}/>
                                    <AgGridReact undoRedoCellEditing={true} rowData={rowData}
                                                 columnDefs={columnDefs}
                                                 onGridReady={onGridReady} onCellValueChanged={handleEdit}/>
                                </div>
                            </Grid>
                            }


                            {!displayGrid && queryFilters.name === 'woodcontracts' &&

                            <Grid item xs={9} hidden={hideContractGroups}>
                                <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                    <h2 className="grid-table-heading">{tableHeading}</h2>
                                    <div style={{
                                        color: "red",
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        padding: "10px"
                                    }}>{tableError}</div>

                                    <InputLabel id="contractgroup-label">Contract Group</InputLabel>
                                    <Select labelId="contractgroup-label" id="select-contractgroup"
                                            value={queryFilters.contractGroupId}
                                            onChange={(e) => handleChange("contractGroupId", queryFilters.name, e)}>
                                        {contractGroupsLookup.length > 0 && contractGroupsLookup.map((el, index) =>
                                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                        )}
                                    </Select>
                                </div>
                            </Grid>
                            }

                            {!displayGrid && queryFilters.name === 'woodcontracts' &&

                            <Grid item xs={9} hidden={hideLandOwners}>
                                <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                    <h2 className="grid-table-heading">{tableHeading}</h2>
                                    <div style={{
                                        color: "red",
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        padding: "10px"
                                    }}>{tableError}</div>

                                    <InputLabel id="landowner-label">Land Owner</InputLabel>
                                    <Select labelId="landowner-label" id="select-landowner"
                                            value={queryFilters.landOwnerId}
                                            onChange={(e) => handleChange("landOwnerId", queryFilters.name, e)}>
                                        {landOwnersLookup.length > 0 && landOwnersLookup.map((el, index) =>
                                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                        )}
                                    </Select>
                                </div>
                            </Grid>
                            }

                            {!displayGrid && (queryFilters.name === 'woodcontracts' || queryFilters.name === 'intercompanyrates') &&

                            <Grid item xs={9} hidden={hideReceivingSites}>
                                <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                    <h2 className="grid-table-heading">{tableHeading}</h2>
                                    <div style={{
                                        color: "red",
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        padding: "10px"
                                    }}>{tableError}</div>

                                    <InputLabel id="receivingsite-label">Receiving Site</InputLabel>
                                    <Select labelId="receivingsite-label" id="select-receivingsite"
                                            value={queryFilters.receivingSiteId}
                                            onChange={(e) => handleChange("receivingSiteId", queryFilters.name, e)}>
                                        {receivingSitesLookup.length > 0 && receivingSitesLookup.map((el, index) =>
                                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                        )}
                                    </Select>
                                </div>
                            </Grid>
                            }

                            {!displayGrid && (queryFilters.name === 'stumpagerates' || queryFilters.name === 'laborrates' ||
                                queryFilters.name === 'intercompanyrates') &&

                            <Grid item xs={9} hidden={hideLandOwners}>
                                <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                    <h2 className="grid-table-heading">{tableHeading}</h2>
                                    <div style={{
                                        color: "red",
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        padding: "10px"
                                    }}>{tableError}</div>

                                    <InputLabel id="landowner-label">Land Owner</InputLabel>
                                    <Select labelId="landowner-label" id="select-landowner"
                                            value={queryFilters.landOwnerId}
                                            onChange={(e) => handleChange("landOwnerId", queryFilters.name, e)}>
                                        {landOwnersLookup.length > 0 && landOwnersLookup.map((el, index) =>
                                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                        )}
                                    </Select>
                                </div>
                            </Grid>
                            }
                            {!displayGrid && (queryFilters.name === 'stumpagerates' || queryFilters.name === 'laborrates' ||
                                queryFilters.name === 'intercompanyrates') &&

                            <Grid item xs={9} hidden={hideWoodLots}>
                                <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                    <h2 className="grid-table-heading">{tableHeading}</h2>
                                    <div style={{
                                        color: "red",
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        padding: "10px"
                                    }}>{tableError}</div>

                                    <InputLabel id="woodlot-label">Wood Lot</InputLabel>
                                    <Select labelId="woodlot-label" id="select-woodlot"
                                            value={queryFilters.woodLotId}
                                            onChange={(e) => handleChange("woodLotId", queryFilters.name, e)}>
                                        {woodLotsLookup.length > 0 && woodLotsLookup.map((el, index) =>
                                            <MenuItem key={index}
                                                      value={el.id}>{el.name + ": " + el.description}</MenuItem>
                                        )}
                                    </Select>
                                </div>
                            </Grid>
                            }
                            {!displayGrid && (queryFilters.name === 'laborrates') &&

                            <Grid item xs={9} hidden={hideLaborResourceTypes}>
                                <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                    <h2 className="grid-table-heading">{tableHeading}</h2>
                                    <div style={{
                                        color: "red",
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        padding: "10px"
                                    }}>{tableError}</div>

                                    <InputLabel id="laborresourcetype-label">Labor Resource Type</InputLabel>
                                    <Select labelId="laborresourcetype-label" id="select-laborresourcetype"
                                            value={queryFilters.laborResourceTypeId}
                                            onChange={(e) => handleChange("laborResourceTypeId", queryFilters.name, e)}>
                                        {laborResourceTypesLookup.length > 0 && laborResourceTypesLookup.map((el, index) =>
                                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                        )}
                                    </Select>
                                </div>
                            </Grid>
                            }

                        </Grid>
                    </form>
                </main>
            );
        } else {
            if (menuSelected === 'contractgroups') {
                return (
                    <main>
                        <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Enter Contract Group"
                        >
                            <form onSubmit={handleSubmit} id={"create-contractgroup-form"} autoComplete="off">
                                <Grid container spacing={3} direction="row" alignItems="flex-start"
                                      justify="flex-start"
                                      style={{padding: 60}}>
                                    <Grid item xs={12}>
                                        <h2>Add Contract Group</h2>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="contractgroup-name-text"
                                            label="Contract Group Name"
                                            required="true"
                                            onChange={(e) => handleFormChange("contractGroupName", e)}
                                            value={contractGroupName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p>Export Gardner Live?</p>
                                        <Checkbox
                                            id="contractgroup-exportglive-checkbox"
                                            onChange={(e) => handleFormChange("exportGLive", e)}
                                            value={exportGLive}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained"
                                                style={{color: "green"}}>Add</Button>
                                    </Grid>

                                </Grid>
                            </form>
                        </Modal>
                    </main>

                );
            } else if (menuSelected === 'woodcontracts') {
                if (modalNoFilters) {
                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter Wood Contract"
                            >
                                <form onSubmit={handleSubmit} id={"create-woodcontract-unfilteredform"}
                                      autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add Wood Contract</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="receivingsite-label">Receiving Site</InputLabel>
                                            <Select labelId="receivingsite-label" id="select-receivingsite"
                                                    required="true"
                                                    value={receivingSiteId}
                                                    onChange={(e) => handleFormChange("receivingsite", e)}>
                                                {receivingSitesLookup.length > 0 && receivingSitesLookup.map((el, index) =>
                                                    <MenuItem key={index}
                                                              value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="contractgroup-label">Contract Group</InputLabel>
                                            <Select labelId="contractgroup-label" id="select-contractgroup"
                                                    required="true"
                                                    value={contractGroupId}
                                                    onChange={(e) => handleFormChange("contractgroup", e)}>
                                                {contractGroupsLookup.length > 0 && contractGroupsLookup.map((el, index) =>
                                                    <MenuItem key={index}
                                                              value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="landowner-label"
                                                            onClick={(e) => clearFieldData('landowner', e)}>Land
                                                    Owner</InputLabel>
                                            </Tooltip>
                                            <Select labelId="landowner-label" id="select-landowner"
                                                    value={landOwnerId}
                                                    onChange={(e) => handleFormChange("landowner", e)}>
                                                {landOwnersLookup.length > 0 && landOwnersLookup.map((el, index) =>
                                                    <MenuItem key={index}
                                                              value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="woodlot-label"
                                                            onClick={(e) => clearFieldData('woodlot', e)}>Wood
                                                    Lot</InputLabel>
                                            </Tooltip>
                                            <Select labelId="woodlot-label" id="select-woodlot"
                                                    value={woodLotId}
                                                    onChange={(e) => handleFormChange("woodlot", e)}>
                                                {woodLotsLookup.length > 0 && woodLotsLookup.map((el, index) =>
                                                    <MenuItem key={index}
                                                              value={el.id}>{el.name + ': ' + el.description}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="product-label">Product</InputLabel>
                                            <Select labelId="product-label" required="true" id="select-product"
                                                    value={productId}
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="species-label">Species</InputLabel>
                                            <Select labelId="species-label" required="true" id="select-species"
                                                    value={specieId}
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>
                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="contractid-text"
                                                label="Contract Id"
                                                value={contractId}
                                                onChange={(e) => handleFormChange("contractId", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="certified1-text"
                                                label="Certified Line 1"
                                                value={certifiedLine1}
                                                onChange={(e) => handleFormChange("certifiedLine1", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="certified2-text"
                                                label="Certified Line 2"
                                                value={certifiedLine2}
                                                onChange={(e) => handleFormChange("certifiedLine2", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="gardnerlivemill-text"
                                                label="Gardner Live Mill"
                                                value={gardnerLiveMill}
                                                onChange={(e) => handleFormChange("gardnerLiveMill", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="customer-label">Customer</InputLabel>
                                            <Select labelId="customer-label" id="select-customer"
                                                    value={customerId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("customer", e)}>
                                                {customersLookup.length > 0 && customersLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="glaccount-label">GL Account</InputLabel>
                                            <Select labelId="glaccount-label" id="select-glaccount"
                                                    value={glAccountId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("glAccount", e)}>
                                                {glAccountsLookup.length > 0 && glAccountsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );
                } else if (queryFiltersRef.current.landOwnerId) {
                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter Wood Contract"
                            >
                                <form onSubmit={handleSubmit} id={"create-woodcontract-form"} autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                            <BorderClearIcon
                                                id="clearformdata-icon"
                                                onClick={clearFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add Wood Contract</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="receivingsite-label">Receiving Site</InputLabel>
                                            <TextField
                                                id="receivingsite-text"
                                                label={queryFiltersRef.current.receivingSiteName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="contractgroup-label">Contract Group</InputLabel>
                                            <TextField
                                                id="contractgroup-text"
                                                label={queryFiltersRef.current.contractGroupName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="landowner-label">Land Owner</InputLabel>
                                            <TextField
                                                id="landowner-text"
                                                label={queryFiltersRef.current.landOwnerName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="woodlot-label"
                                                            onClick={(e) => clearFieldData('woodlot', e)}>Wood
                                                    Lot</InputLabel>
                                            </Tooltip>
                                            <Select labelId="woodlot-label" id="select-woodlot"
                                                    value={woodLotId}
                                                    onChange={(e) => handleFormChange("woodlot", e)}>
                                                {woodLotsLookup.length > 0 && woodLotsLookup.map((el, index) =>
                                                    <MenuItem key={index}
                                                              value={el.id}>{el.name + ': ' + el.description}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="product-label">Product</InputLabel>
                                            <Select labelId="product-label" required="true" id="select-product"
                                                    value={productId}
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="species-label">Species</InputLabel>
                                            <Select labelId="species-label" required="true" id="select-species"
                                                    value={specieId}
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>
                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="contractid-text"
                                                label="Contract Id"
                                                value={contractId}
                                                onChange={(e) => handleFormChange("contractId", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="certified1-text"
                                                label="Certified Line 1"
                                                value={certifiedLine1}
                                                onChange={(e) => handleFormChange("certifiedLine1", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="certified2-text"
                                                label="Certified Line 2"
                                                value={certifiedLine2}
                                                onChange={(e) => handleFormChange("certifiedLine2", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="gardnerlivemill-text"
                                                label="Gardner Live Mill"
                                                value={gardnerLiveMill}
                                                onChange={(e) => handleFormChange("gardnerLiveMill", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="customer-label">Customer</InputLabel>
                                            <Select labelId="customer-label" id="select-customer"
                                                    value={customerId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("customer", e)}>
                                                {customersLookup.length > 0 && customersLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="glaccount-label">GL Account</InputLabel>
                                            <Select labelId="glaccount-label" id="select-glaccount"
                                                    value={glAccountId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("glAccount", e)}>
                                                {glAccountsLookup.length > 0 && glAccountsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );

                } else {

                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter Wood Contract"
                            >
                                <form onSubmit={handleSubmit} id={"create-woodcontract-form"} autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                            <BorderClearIcon
                                                id="clearformdata-icon"
                                                onClick={clearFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add Wood Contract</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="receivingsite-label">Receiving Site</InputLabel>
                                            <TextField
                                                id="receivingsite-text"
                                                label={queryFiltersRef.current.receivingSiteName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="contractgroup-label">Contract Group</InputLabel>
                                            <TextField
                                                id="contractgroup-text"
                                                label={queryFiltersRef.current.contractGroupName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="product-label">Product</InputLabel>
                                            <Select labelId="product-label" required="true" id="select-product"
                                                    value={productId}
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="species-label">Species</InputLabel>
                                            <Select labelId="species-label" required="true" id="select-species"
                                                    value={specieId}
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>

                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="contractid-text"
                                                label="Contract Id"
                                                value={contractId}
                                                onChange={(e) => handleFormChange("contractId", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="certified1-text"
                                                label="Certified Line 1"
                                                value={certifiedLine1}
                                                onChange={(e) => handleFormChange("certifiedLine1", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="certified2-text"
                                                label="Certified Line 2"
                                                value={certifiedLine2}
                                                onChange={(e) => handleFormChange("certifiedLine2", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="gardnerlivemill-text"
                                                label="Gardner Live Mill"
                                                value={gardnerLiveMill}
                                                onChange={(e) => handleFormChange("gardnerLiveMill", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="customer-label">Customer</InputLabel>
                                            <Select labelId="customer-label" id="select-customer"
                                                    value={customerId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("customer", e)}>
                                                {customersLookup.length > 0 && customersLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="glaccount-label">GL Account</InputLabel>
                                            <Select labelId="glaccount-label" id="select-glaccount"
                                                    value={glAccountId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("glAccount", e)}>
                                                {glAccountsLookup.length > 0 && glAccountsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );
                }
            } else if (menuSelected === 'stumpagerates') {
                if (queryFiltersRef.current.woodLotId) {
                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter Stumpage Rate"
                            >
                                <form onSubmit={handleSubmit} id={"create-stumpagerate-form"} autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                            <BorderClearIcon
                                                id="clearformdata-icon"
                                                onClick={clearFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add Stumpage Rate</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="landowner-label">Land Owner</InputLabel>
                                            <TextField
                                                id="landowner-text"
                                                label={queryFiltersRef.current.landOwnerName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="woodlot-label">Wood Lot</InputLabel>
                                            <TextField
                                                id="woodlot-text"
                                                label={queryFiltersRef.current.woodLotName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="product-label">Product</InputLabel>
                                            <Select labelId="product-label" id="select-product"
                                                    value={productId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="species-label">Species</InputLabel>
                                            <Select labelId="species-label" id="select-species"
                                                    value={specieId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>
                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="millpct-text"
                                                label="Mill Percentage"
                                                type="number"
                                                onChange={(e) => handleFormChange("millPct", e)}
                                                value={millPct}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );
                } else {
                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter Stumpage Rate"
                            >
                                <form onSubmit={handleSubmit} id={"create-stumpagerate-form"} autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                            <BorderClearIcon
                                                id="clearformdata-icon"
                                                onClick={clearFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add Stumpage Rate</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="landowner-label">Land Owner</InputLabel>
                                            <TextField
                                                id="landowner-text"
                                                label={queryFiltersRef.current.landOwnerName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="product-label">Product</InputLabel>
                                            <Select labelId="product-label" id="select-product"
                                                    value={productId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="species-label">Species</InputLabel>
                                            <Select labelId="species-label" id="select-species"
                                                    value={specieId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>
                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="millpct-text"
                                                label="Mill Percentage"
                                                type="number"
                                                onChange={(e) => handleFormChange("millPct", e)}
                                                value={millPct}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );

                }

            } else if (menuSelected === 'laborrates') {
                if (queryFiltersRef.current.woodLotId) {
                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter Labor Rate"
                            >
                                <form onSubmit={handleSubmit} id={"create-laborrate-form"} autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                            <BorderClearIcon
                                                id="clearformdata-icon"
                                                onClick={clearFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add Labor Rate</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="companytype-label"
                                                            onClick={(e) => clearFieldData('companytype', e)}>
                                                    Company Type</InputLabel>
                                            </Tooltip>
                                            <Select labelId="companytype-label" id="select-companytype"
                                                    value={companyTypeId}
                                                    onChange={(e) => handleFormChange("companytype", e)}>
                                                {companyTypesLookup.length > 0 && companyTypesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="laborresourcetype-label">Labor Resource
                                                Type</InputLabel>
                                            <TextField
                                                id="laborresourcetype-text"
                                                label={queryFiltersRef.current.laborResourceTypeName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="laborresourcesubtype-label"
                                                            onClick={(e) => clearFieldData('laborresourcesubtype', e)}>Labor
                                                    Resource SubType</InputLabel>
                                            </Tooltip>

                                            <Select labelId="laborresourcesubtype-label"
                                                    id="select-laborresourcesubtype"
                                                    value={laborResourceSubTypeId}
                                                    onChange={(e) => handleFormChange("laborresourcesubtype", e)}>
                                                {laborResourceSubTypesLookup.length > 0 && laborResourceSubTypesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="laborresource-label"
                                                            onClick={(e) => clearFieldData('laborresource', e)}>
                                                    Labor Resource</InputLabel>
                                            </Tooltip>
                                            <Select labelId="laborresource-label"
                                                    id="select-laborresource"
                                                    value={laborResourceId}
                                                    onChange={(e) => handleFormChange("laborresource", e)}>
                                                {laborResourcesLookup.length > 0 && laborResourcesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="landowner-label">Land Owner</InputLabel>
                                            <TextField
                                                id="landowner-text"
                                                label={queryFiltersRef.current.landOwnerName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="woodlot-label">Wood Lot</InputLabel>
                                            <TextField
                                                id="woodlot-text"
                                                label={queryFiltersRef.current.woodLotName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="receivingsite-label"
                                                            onClick={(e) => clearFieldData('receivingsite', e)}>
                                                    Recieving Site</InputLabel>
                                            </Tooltip>
                                            <Select labelId="receivingsite-label" id="select-receivingsite"
                                                    value={receivingSiteId}
                                                    onChange={(e) => handleFormChange("receivingsite", e)}>
                                                {receivingSitesLookup.length > 0 && receivingSitesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="product-label"
                                                            onClick={(e) => clearFieldData('product', e)}>Product</InputLabel>
                                            </Tooltip>
                                            <Select labelId="product-label" id="select-product"
                                                    value={productId}
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="species-label"
                                                            onClick={(e) => clearFieldData('specie', e)}>Species</InputLabel>
                                            </Tooltip>
                                            <Select labelId="species-label" id="select-species"
                                                    value={specieId}
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>
                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );
                } else {
                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter Labor Rate"
                            >
                                <form onSubmit={handleSubmit} id={"create-laborrate-form"} autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                            <BorderClearIcon
                                                id="clearformdata-icon"
                                                onClick={clearFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add Labor Rate</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="companytype-label"
                                                            onClick={(e) => clearFieldData('companytype', e)}>
                                                    Company Type</InputLabel>
                                            </Tooltip>
                                            <Select labelId="companytype-label" id="select-companytype"
                                                    value={companyTypeId}
                                                    onChange={(e) => handleFormChange("companytype", e)}>
                                                {companyTypesLookup.length > 0 && companyTypesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="laborresourcetype-label">Labor Resource
                                                Type</InputLabel>
                                            <TextField
                                                id="laborresourcetype-text"
                                                label={queryFiltersRef.current.laborResourceTypeName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="laborresourcesubtype-label"
                                                            onClick={(e) => clearFieldData('laborresourcesubtype', e)}>Labor
                                                    Resource SubType</InputLabel>
                                            </Tooltip>
                                            <Select labelId="laborresourcesubtype-label"
                                                    id="select-laborresourcesubtype"
                                                    value={laborResourceSubTypeId}
                                                    onChange={(e) => handleFormChange("laborresourcesubtype", e)}>
                                                {laborResourceSubTypesLookup.length > 0 && laborResourceSubTypesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="laborresource-label"
                                                            onClick={(e) => clearFieldData('laborresource', e)}>
                                                    Labor Resource</InputLabel>
                                            </Tooltip>
                                            <Select labelId="laborresource-label"
                                                    id="select-laborresource"
                                                    value={laborResourceId}
                                                    onChange={(e) => handleFormChange("laborresource", e)}>
                                                {laborResourcesLookup.length > 0 && laborResourcesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="landowner-label">Land Owner</InputLabel>
                                            <TextField
                                                id="landowner-text"
                                                label={queryFiltersRef.current.landOwnerName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="receivingsite-label"
                                                            onClick={(e) => clearFieldData('receivingsite', e)}>
                                                    Recieving Site</InputLabel>
                                            </Tooltip>
                                            <Select labelId="receivingsite-label" id="select-receivingsite"
                                                    value={receivingSiteId}
                                                    onChange={(e) => handleFormChange("receivingsite", e)}>
                                                {receivingSitesLookup.length > 0 && receivingSitesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="product-label"
                                                            onClick={(e) => clearFieldData('product', e)}>Product</InputLabel>
                                            </Tooltip>
                                            <Select labelId="product-label" id="select-product"
                                                    value={productId}
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="species-label"
                                                            onClick={(e) => clearFieldData('specie', e)}>Species</InputLabel>
                                            </Tooltip>
                                            <Select labelId="species-label" id="select-species"
                                                    value={specieId}
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>
                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );
                }
            } else if (menuSelected === 'intercompanyrates') {
                if (queryFiltersRef.current.woodLotId) {
                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter InterCompany Rate"
                            >
                                <form onSubmit={handleSubmit} id={"create-intercompanyrate-form"} autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                            <BorderClearIcon
                                                id="clearformdata-icon"
                                                onClick={clearFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add InterCompany Rate</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="company-label">Company</InputLabel>
                                            <Select labelId="company-label" id="select-company"
                                                    value={companyId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("company", e)}>
                                                {companiesLookup.length > 0 && companiesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="receivingsite-label">Receiving Site</InputLabel>
                                            <TextField
                                                id="receivingsite-text"
                                                label={queryFiltersRef.current.receivingSiteName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="landowner-label">Land Owner</InputLabel>
                                            <TextField
                                                id="landowner-text"
                                                label={queryFiltersRef.current.landOwnerName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="woodlot-label">Wood Lot</InputLabel>
                                            <TextField
                                                id="woodlot-text"
                                                label={queryFiltersRef.current.woodLotName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="product-label"
                                                            onClick={(e) => clearFieldData('product', e)}>Product</InputLabel>
                                            </Tooltip>
                                            <Select labelId="product-label" id="select-product"
                                                    value={productId}
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="species-label"
                                                            onClick={(e) => clearFieldData('specie', e)}>Species</InputLabel>
                                            </Tooltip>
                                            <Select labelId="species-label" id="select-species"
                                                    value={specieId}
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>
                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="customer-label">Customer</InputLabel>
                                            <Select labelId="customer-label" id="select-customer"
                                                    value={customerId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("customer", e)}>
                                                {customersLookup.length > 0 && customersLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="glaccount-label">GL Account</InputLabel>
                                            <Select labelId="glaccount-label" id="select-glaccount"
                                                    value={glAccountId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("glAccount", e)}>
                                                {glAccountsLookup.length > 0 && glAccountsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="conumtopay-text"
                                                label="Company Num to Pay"
                                                value={coNumToPay}
                                                onChange={(e) => handleFormChange("coNumToPay", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );
                } else {
                    return (
                        <main>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Enter InterCompany Rate"
                            >
                                <form onSubmit={handleSubmit} id={"create-intercompanyrate-form"} autoComplete="off">
                                    <Grid container spacing={3} direction="row" alignItems="flex-start"
                                          justify="flex-start"
                                          style={{padding: 60}}>
                                        <Grid item xs={3}>
                                            <BorderClearIcon
                                                id="clearformdata-icon"
                                                onClick={clearFormData}
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <h2>Add InterCompany Rate</h2>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="company-label">Company</InputLabel>
                                            <Select labelId="company-label" id="select-company"
                                                    value={companyId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("company", e)}>
                                                {companiesLookup.length > 0 && companiesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="receivingsite-label">Receiving Site</InputLabel>
                                            <TextField
                                                id="receivingsite-text"
                                                label={queryFiltersRef.current.receivingSiteName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="landowner-label">Land Owner</InputLabel>
                                            <TextField
                                                id="landowner-text"
                                                label={queryFiltersRef.current.landOwnerName}
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="product-label"
                                                            onClick={(e) => clearFieldData('product', e)}>Product</InputLabel>
                                            </Tooltip>
                                            <Select labelId="product-label" id="select-product"
                                                    value={productId}
                                                    onChange={(e) => handleFormChange("product", e)}>
                                                {productsLookup.length > 0 && productsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="species-label"
                                                            onClick={(e) => clearFieldData('specie', e)}>Species</InputLabel>
                                            </Tooltip>
                                            <Select labelId="species-label" id="select-species"
                                                    value={specieId}
                                                    onChange={(e) => handleFormChange("specie", e)}>
                                                {speciesLookup.length > 0 && speciesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="grade-label"
                                                            onClick={(e) => clearFieldData('grade', e)}>Grade</InputLabel>
                                            </Tooltip>
                                            <Select labelId="grade-label" id="select-grade"
                                                    value={gradeId}
                                                    onChange={(e) => handleFormChange("grade", e)}>
                                                {gradesLookup.length > 0 && gradesLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Tooltip title="Click to clear" placement="top">
                                                <InputLabel id="length-label"
                                                            onClick={(e) => clearFieldData('length', e)}>Length</InputLabel>
                                            </Tooltip>
                                            <Select labelId="length-label" id="select-length"
                                                    value={lengthId}
                                                    onChange={(e) => handleFormChange("length", e)}>
                                                {lengthsLookup.length > 0 && lengthsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select labelId="unit-label" id="select-unit"
                                                    value={unitId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("unit", e)}>
                                                {unitsLookup.length > 0 && unitsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="rate-text"
                                                label="Rate"
                                                type="number"
                                                required="true"
                                                onChange={(e) => handleFormChange("rate", e)}
                                                value={rate}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="eff-text"
                                                label="Effective Date"
                                                required="true"
                                                type="date"
                                                value={effDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("effDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="exp-text"
                                                label="Expiration Date"
                                                required="true"
                                                type="date"
                                                value={expDate}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleFormChange("expDate", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="customer-label">Customer</InputLabel>
                                            <Select labelId="customer-label" id="select-customer"
                                                    value={customerId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("customer", e)}>
                                                {customersLookup.length > 0 && customersLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <InputLabel id="glaccount-label">GL Account</InputLabel>
                                            <Select labelId="glaccount-label" id="select-glaccount"
                                                    value={glAccountId}
                                                    required="true"
                                                    onChange={(e) => handleFormChange("glAccount", e)}>
                                                {glAccountsLookup.length > 0 && glAccountsLookup.map((el, index) =>
                                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                id="conumtopay-text"
                                                label="Company Num to Pay"
                                                value={coNumToPay}
                                                onChange={(e) => handleFormChange("coNumToPay", e)}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained"
                                                    style={{color: "green"}}>Add</Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </Modal>
                        </main>

                    );

                }
            }
        }

    } else {
        return (
            <main>
                <h1>Contract and Rate Management</h1>
                <p>NOTE: You do not have privilege to access this menu.</p>
            </main>
        );
    }
}