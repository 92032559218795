import React, {useState} from 'react';

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Button, TextField, Checkbox} from "@material-ui/core";
import config from "../../config/config";

async function importData(endPoint, postData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(postData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })

} // End importData()


export default function ImportTickets(props) {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [importType, setImportType] = useState(props.gardnerLiveImport);
    const [apiResults, setApiResults] = useState('');
    const [tickets, setTickets] = useState('');
    const [noSetImported, setNoSetImported] = useState(false);
    const [ticketsValidationSuccess, setTicketsValidationSuccess] = useState([]);
    const [ticketsValidationFail, setTicketsValidationFail] = useState([]);
    const [ticketsImportSuccess, setTicketsImportSuccess] = useState([]);
    const [ticketsImportFail, setTicketsImportFail] = useState([]);

    const handleChange = async (data, e) => {

        if (data === 'startdate') {
            setStartDate(e.target.value);
        } else if (data === 'enddate')  {
            setEndDate(e.target.value);
        } else if (data === 'tickets') {
            setTickets(e.target.value);
        } else {
            setNoSetImported(e.target.checked);
        }
    };

    const handleImport = async (importType, e) => {

        let importTypeId;
        let postData;
        let importResults;

        if (importType === 'Local') {
            importTypeId = 1;
        } else {
            importTypeId = 2;
        }

        postData = {
            startDate: startDate,
            endDate: endDate,
            importType: importTypeId,
            tickets: tickets,
            noSetImported: noSetImported
        };


        console.log("Post Data: " + JSON.stringify(postData));

        importResults = await importData('/importTickets', postData);
        console.log("Import Results: " + JSON.stringify(importResults));

        setApiResults(importResults);

        if (importResults.success) {
            setTicketsValidationSuccess(JSON.stringify(importResults.success.ticketsValidationSuccess));
            setTicketsValidationFail(JSON.stringify(importResults.success.ticketsValidationFail));
            setTicketsImportSuccess(JSON.stringify(importResults.success.ticketsImportSuccess));
            setTicketsImportFail(JSON.stringify(importResults.success.ticketsImportFail));
        }

    };

    if (!apiResults) {
        return (
            <main>
                <h2><ArrowBackIcon onClick={(e) => props.handleGoBack(e)}/>Import {importType} Tickets</h2>
                <Grid container spacing={3} direction="row" alignItems="center" justify="left">
                    <Grid item xs={3}>
                        <br/><br/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="import-startedate-text"
                            label="Start Date"
                            type="date"
                            value={startDate}
                            required="false"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => handleChange("startdate", e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="import-enddate-text"
                            label="End Date"
                            type="date"
                            value={endDate}
                            required="false"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => handleChange("enddate", e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <h6>DO NOT Set as Imported in Gardner Live?</h6>
                        <Checkbox
                            id="import-nosetimported-text"
                            value={noSetImported}
                            required="false"
                            color="green"
                            defaultChecked={noSetImported}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => handleChange("nosetimported", e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3}>
                        <h4>-- OR --</h4>
                    </Grid>
                    <Grid item xs={6}>
                        <h6>Tickets</h6>
                        <TextField
                            id="import-tickets-text"
                            type="text"
                            value={tickets}
                            required="false"
                            helperText="Use commas to separate multiple tickets. Will import tickets marked as imported or not in Gardner Live."
                            InputLabelProps={{
                                shrink: false,
                            }}
                            onChange={(e) => handleChange("tickets", e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={9}>
                        <Button onClick={(e) => handleImport(importType, e)}>Import Tickets</Button>
                    </Grid>

                </Grid>
            </main>
        );

    } else {
        if (apiResults.success) {
            return (
                <main>
                    <h2><ArrowBackIcon onClick={(e) => props.handleGoBack(e)}/>Import {importType} Tickets</h2>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                        <Grid item xs={3}>
                            <h4>Import Tickets Results:</h4>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12}>
                                <br/>
                                <b>Validation Success:</b> {ticketsValidationSuccess}
                                <br/>
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                                <b>Validation Fail:</b> {ticketsValidationFail}
                                <br/>
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                                <b>Import Success:</b> {ticketsImportSuccess}
                                <br/>
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                                <b>Import Fail:</b> {ticketsImportFail}
                                <br/>
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
            );
        } else {
            return (
                <main>
                    <h2><ArrowBackIcon onClick={(e) => props.handleGoBack(e)}/>Import {importType} Tickets</h2>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                        <Grid item xs={3}>
                            <h4>Import Tickets ERROR:</h4>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12}>
                                {apiResults.error}
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
            );
        }
    }
}