import React, {useState, useEffect} from 'react';

import WoodTicketRate from "../WoodTicketRate/WoodTicketRate";

import config from "../../config/config";

import Grid from '@material-ui/core/Grid';
import {Button, MenuItem, Select, TextField} from "@material-ui/core";

import Tooltip from '@mui/material/Tooltip'
import SaveRatesIcon from '@mui/icons-material/AddTask';
import GetSavedRatesIcon from '@mui/icons-material/SettingsBackupRestore';
import GetTableRatesIcon from '@mui/icons-material/TableView';

import moment from 'moment';
import 'moment-timezone';

import LoadingOverlay from 'react-loading-overlay';


async function getData(endPoint, queryString, orderBy) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-querystring': queryString,
            'x-wtg-orderby': orderBy
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function matchRates(endPoint) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function updateData(endPoint, putData) {

    console.log("Put Data: " + JSON.stringify(putData));
    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(putData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}


async function setLookups(endPoint, queryString, orderBy) {
    let result, data = [];

    result = await getData('/' + endPoint, queryString, orderBy);

    if (result.success && result.success.length > 0) {

        if (endPoint === "lookupwoodticketrates" || endPoint === "lookupwoodticketratesnn") {

            for (const el of result.success) {
                if (!el.rateId) {
                    el.rateId = null;
                }

                data.push({
                    "woodTicketId": el.woodTicketId,
                    "rateTypeId": el.rateTypeId,
                    "unitPrice": el.unitPrice,
                    "unitId": el.unitId,
                    "totalPrice": el.totalPrice,
                    "rateId": el.rateId,
                    "companyId": el.companyId,
                    "laborResourceId": el.laborResourceId,
                    "woodTicketProductId": el.woodTicketProductId
                });
            }
        } else if (endPoint === "lookupwoodtickets") {
            data.push(result.success[0]);
        } else if ((endPoint === "lookupwoodcontracts") || (endPoint === "lookupstumpagerates")) {
            data.push(result.success);
        } else if (endPoint === "lookuplaborrates") {
            data = [...result.success];
        } else if (endPoint === "lookupintercompanyrates") {
            data.push(result.success);
        } else {
            for (const el of result.success) {
                data.push({
                    "id": el.id,
                    "name": el.name,
                });
            }
        }

        return (data);
    } else {
        return [];
    }

}


export default function WoodTicketRates(props) {

    useEffect(() => {
        console.log("Use effect");
        handleGetSavedRates();
    }, [props.getProductIdSelected()]);

    const [woodTicketRates, setWoodTicketRates] = useState([]);
    const [rateTypes, setRateTypes] = useState([]);
    const [unitTypes, setUnitTypes] = useState([]);
    const [asyncOpRunning, setAsyncOpRunning] = useState(false);


    // Get applicable rates from WoodTicketRates
    const handleGetSavedRates = async () => {
        let ticketId = props.getTicketId();
        let newTicketRate = {}, newTicketRates = [];

        setWoodTicketRates([]);

        if (!ticketId) {
            console.log("Ticket Id is not set");
            return;
        }

        console.log("TicketId: " + ticketId);

        setAsyncOpRunning(true);

        let woodTicketProductId = props.getProductIdSelected();

        // Make sure a Wood Ticket product is selected
        if (!woodTicketProductId) {
            return;
        }
        let unitTypes = await setLookups('units');
        setUnitTypes(unitTypes);
        let rateTypes = await setLookups('ratetypes');
        setRateTypes(rateTypes);

        let laborResources = props.getLaborResources();

        let ticketRates = await setLookups('lookupwoodticketratesnn',
            '{"woodTicketId": ' + ticketId + ', "woodTicketProductId": ' + woodTicketProductId + '}', '[["rateTypeId", "ASC"]]');

        console.log("Ticket Rates:" + JSON.stringify(ticketRates));

        if (ticketRates.length > 0) {

            // Add additional info to Ticket Rates
            for (const ticketRate of ticketRates) {

                newTicketRate = {};

                let rt = rateTypes.find(x => x.id == ticketRate.rateTypeId);
                let ut = unitTypes.find(x => x.id == ticketRate.unitId);

                if (ticketRate.rateTypeId === 3 ) {
                    console.log("Labor Resoures" + JSON.stringify(laborResources));
                    let laborResource = laborResources.find(x => x.id == ticketRate.laborResourceId);
                    if (laborResource) {
                        newTicketRate.rateTypeName = laborResource.name;
                        newTicketRate.laborResourceId = ticketRate.laborResourceId;
                    }
                } else if (ticketRate.rateTypeId === 4) {
                    let companies = await getData('/lookupcompanies',
                        '{"id": ' + ticketRate.companyId + '}', '[["id", "ASC"]]');
                    for (const company of companies.success) {
                        newTicketRate.rateTypeName = company.name;
                        newTicketRate.companyId = ticketRate.companyId;
                    }
                } else {
                    newTicketRate.rateTypeName = rt.name;
                }
                newTicketRate.woodTicketId = ticketId;
                newTicketRate.woodTicketProductId = woodTicketProductId;
                newTicketRate.unitTypeName = ut.name;
                newTicketRate.unitPrice = ticketRate.unitPrice;
                newTicketRate.unitId = ticketRate.unitId;
                newTicketRate.totalPrice = ticketRate.totalPrice;
                newTicketRate.rateId = ticketRate.rateId;
                newTicketRate.rateTypeId = ticketRate.rateTypeId;

                newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));
            }

            setWoodTicketRates([]);
            setWoodTicketRates([...newTicketRates]);
        }

        setAsyncOpRunning(false);

    };

    const handleFieldChange = (e) => {
        let splitString = e.target.id.split('-');
        let index = splitString[splitString.length - 1];
        console.log("ID of changed element: " + e.target.id);
        console.log("Index of element: " + index);

        console.dir(e);
        if (e.key === 'Enter') {


            let newWoodTicketRates = [...woodTicketRates];

            switch (e.target.id) {
                case "rate-unit-price-" + index:
                    newWoodTicketRates[index].unitPrice = e.target.value.replace(',', '');
                    newWoodTicketRates[index].totalPrice = (e.target.value.replace(',', '') * props.getConvertedNet());
                    break;
                case "rate-unit-" + index:
                    let x = unitTypes.find(x => x.name.toLowerCase() == e.target.value.toLowerCase());
                    console.log("X: " + JSON.stringify(x));
                    if (x && x.id) {
                        newWoodTicketRates[index].unitId = x.id;
                    }
                    break;
                case "rate-total-price-" + index:
                    newWoodTicketRates[index].totalPrice = (e.target.value.replace(',', '') * props.getConvertedNet());
                    break;
                default:
                    break;
            }

            setWoodTicketRates([...newWoodTicketRates]);

            e.target.blur();

        }

    };

    const handleFieldBlur = (e) => {
        let splitString = e.target.id.split('-');
        let index = splitString[splitString.length - 1];
        console.log("ID of changed element: " + e.target.id);
        console.log("Index of element: " + index);

        let newWoodTicketRates = [...woodTicketRates];

        switch (e.target.id) {
            case "rate-unit-price-" + index:
                newWoodTicketRates[index].unitPrice = e.target.value.replace(',', '');
                newWoodTicketRates[index].totalPrice = (e.target.value.replace(',', '') * props.getConvertedNet());
                break;
            case "rate-unit-" + index:
                let x = unitTypes.find(x => x.name.toLowerCase() == e.target.value.toLowerCase());
                console.log("X: " + JSON.stringify(x));
                if (x && x.id) {
                    newWoodTicketRates[index].unitId = x.id;
                }
                break;
            case "rate-total-price-" + index:
                newWoodTicketRates[index].totalPrice = (e.target.value.replace(',', '') * props.getConvertedNet());
                break;
            default:
                break;
        }

        setWoodTicketRates([...newWoodTicketRates]);

    };

    //Get rates from Rate Tables
    const handleGetTableRates = async () => {

        console.log("Setting to Table Rates");

        let ticketId = props.getTicketId();
        if (!ticketId) {
            console.log("Ticket Id is not set");
            return;
        }


        setAsyncOpRunning(true);

        let woodTicketProductId = props.getProductIdSelected();

        let newTicketRate = {}, newTicketRates = [];

        let rateMatch = await matchRates('/matchWoodContractRates?woodTicketProductId=' + woodTicketProductId);

        newTicketRate.rateTypeName = "MILL";
        if (rateMatch && rateMatch.success && rateMatch.success.rateId > 0) {
            newTicketRate.woodTicketId = ticketId;
            newTicketRate.woodTicketProductId = woodTicketProductId;
            newTicketRate.unitPrice = rateMatch.success.unitPrice;
            newTicketRate.unitId = rateMatch.success.unitId;
            newTicketRate.unitTypeName = rateMatch.success.unitTypeName;
            newTicketRate.totalPrice = rateMatch.success.totalPrice;
            newTicketRate.rateId = rateMatch.success.rateId;
            newTicketRate.rateTypeId = 1;
        } else {
            newTicketRate.woodTicketId = ticketId;
            newTicketRate.woodTicketProductId = woodTicketProductId;
            newTicketRate.unitPrice = 0.00;
            newTicketRate.unitId = 1;
            newTicketRate.unitTypeName = 'TON';
            newTicketRate.totalPrice = 0.00;
            newTicketRate.rateId = null;
            newTicketRate.rateTypeId = 1;
        }

        newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));

        newTicketRate = {};
        rateMatch = await matchRates('/matchStumpageRates?woodTicketProductId=' + woodTicketProductId);

        newTicketRate.rateTypeName = "STUMPAGE";
        if (rateMatch && rateMatch.success && rateMatch.success.rateId > 0) {
            newTicketRate.woodTicketId = ticketId;
            newTicketRate.woodTicketProductId = woodTicketProductId;
            newTicketRate.unitPrice = rateMatch.success.unitPrice;
            newTicketRate.unitId = rateMatch.success.unitId;
            newTicketRate.unitTypeName = rateMatch.success.unitTypeName;
            newTicketRate.totalPrice = rateMatch.success.totalPrice;
            newTicketRate.rateId = rateMatch.success.rateId;
            newTicketRate.rateTypeId = 2;
        } else {
            newTicketRate.woodTicketId = ticketId;
            newTicketRate.woodTicketProductId = woodTicketProductId;
            newTicketRate.unitPrice = 0.00;
            newTicketRate.unitId = 1;
            newTicketRate.unitTypeName = 'TON';
            newTicketRate.totalPrice = 0.00;
            newTicketRate.rateId = null;
            newTicketRate.rateTypeId = 2;
        }

        newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));

        let laborResources = props.getLaborResources();
        for (const laborResource of laborResources) {

            newTicketRate = {};

            rateMatch = await matchRates('/matchLaborRates?woodTicketProductId=' +
                woodTicketProductId + '&laborResourceId=' + laborResource.id);

            newTicketRate.rateTypeName = laborResource.name;
            if (rateMatch && rateMatch.success && rateMatch.success.rateId > 0) {
                newTicketRate.woodTicketId = ticketId;
                newTicketRate.woodTicketProductId = woodTicketProductId;
                newTicketRate.unitPrice = rateMatch.success.unitPrice;
                newTicketRate.unitId = rateMatch.success.unitId;
                newTicketRate.unitTypeName = rateMatch.success.unitTypeName;
                newTicketRate.totalPrice = rateMatch.success.totalPrice;
                newTicketRate.rateId = rateMatch.success.rateId;
                newTicketRate.rateTypeId = 3;
                newTicketRate.laborResourceId = laborResource.id;
            } else {
                newTicketRate.woodTicketId = ticketId;
                newTicketRate.woodTicketProductId = woodTicketProductId;
                newTicketRate.unitPrice = 0.00;
                newTicketRate.unitId = 1;
                newTicketRate.unitTypeName = 'TON';
                newTicketRate.totalPrice = 0.00;
                newTicketRate.rateId = null;
                newTicketRate.rateTypeId = 3;
                newTicketRate.laborResourceId = laborResource.id;
            }

            newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));
        }

        let companies = await getData('/lookupcompanies',
            '{"companyTypeId": 1}', '[["id", "ASC"]]');

        for (const company of companies.success) {

            newTicketRate = {};

            let rateMatch = await matchRates('/matchInterCompanyRates?woodTicketProductId=' +
                woodTicketProductId + '&companyId=' + company.id);

            if (rateMatch && rateMatch.success && rateMatch.success.rateId > 0) {
                newTicketRate.woodTicketId = ticketId;
                newTicketRate.woodTicketProductId = woodTicketProductId;
                newTicketRate.rateTypeName = company.name;
                newTicketRate.unitPrice = rateMatch.success.unitPrice;
                newTicketRate.unitId = rateMatch.success.unitId;
                newTicketRate.unitTypeName = rateMatch.success.unitTypeName;
                newTicketRate.totalPrice = rateMatch.success.totalPrice;
                newTicketRate.rateId = rateMatch.success.rateId;
                newTicketRate.rateTypeId = 4;
                newTicketRate.companyId = company.id;

                newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));
            }

        }

        setWoodTicketRates([]);
        setWoodTicketRates([...newTicketRates]);

        setAsyncOpRunning(false);

    };

    //Save Rates
    const handleSubmit = async (e) => {
        e.preventDefault();

        setAsyncOpRunning(true);

        let newWoodTicketRates = [...woodTicketRates];
        let filteredNewTicketRates = [];

        for (const newTicketRate of newWoodTicketRates) {
            if (newTicketRate.rateId) {
                newTicketRate.unitPrice = parseFloat(newTicketRate.unitPrice.toString().replace(',', ''));
                filteredNewTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));
            }
        }

        if (filteredNewTicketRates.length > 0) {
            console.log("Wood Ticket Rates to Upsert: " + JSON.stringify(filteredNewTicketRates));
            await updateData('/woodticketrates', filteredNewTicketRates)
        } else {
            console.log("No rates to set since all rateIds are empty");
        }

        setAsyncOpRunning(false);
    };

    return (

        <div>

            <LoadingOverlay
                active={asyncOpRunning}
                spinner
                text="Loading .."
            >

                <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-start">
                    <form onSubmit={handleSubmit} id={"create-woodticketrates-form"} autoComplete="off">
                        <Grid container spacing={3} direction="row" alignItems="flex-start" justify="space-evenly">
                            <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-end">
                                <Grid item xs={3}>
                                    <Tooltip title="Get Saved Rates" placement="top-start">
                                        <GetSavedRatesIcon onClick={handleGetSavedRates}/>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tooltip title="Get Table Rates" placement="top-start">
                                        <GetTableRatesIcon onClick={handleGetTableRates}/>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tooltip title="Save Rates" placement="top-start">
                                        <Button type="submit" variant="text" style={{color: "black"}}>
                                            <SaveRatesIcon/>
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {woodTicketRates.length > 0 && woodTicketRates.map((el, index) =>
                                    <WoodTicketRate key={index} index={index} woodTicketRate={el}
                                                    handleFieldChange={handleFieldChange}
                                                    handleFieldBlur={handleFieldBlur}>
                                    </WoodTicketRate>
                                )}
                            </Grid>
                        </Grid>

                    </form>

                </Grid>
            </LoadingOverlay>
        </div>

    )
}
;
