import React, {useState} from 'react';
import config from "../../config/config.json";
import './ag-grid.css';

// Material-UI Components
import {ButtonGroup, Button, TextField, InputLabel, Select, MenuItem} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';


// AG Grid Components
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// Modal Support
import Modal from 'react-modal';
import WoodTicketProducts from "../WoodTicketProducts/WoodTicketProducts";
import LaborResources from "../LaborResources/LaborResources";

async function getAllData(endPoint) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function insertData(endPoint, postData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(postData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function updateData(endPoint, putData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(putData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}


export default function LaborResourceManagement(props) {

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [tableHeading, setTableHeading] = useState('');
    const [tableError, setTableError] = useState('');
    const [menuColors, setMenuColors] = useState(['white', 'white', 'white', 'white']);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [menuSelected, setMenuSelected] = useState('');

    const [companyTypeId, setCompanyTypeId] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [contact, setContact] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [coNum, setCoNum] = useState('');
    const [laborResourceName, setLaborResourceName] = useState('');
    const [laborResourceTypeId, setLaborResourceTypeId] = useState('');
    const [laborResourceSubTypeId, setLaborResourceSubTypeId] = useState('');

    const [companyTypes, setCompanyTypes] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [laborResourceTypes, setLaborResourceTypes] = useState([]);
    const [laborResourceSubTypes, setLaborResourceSubTypes] = useState([]);

    const customStyles = {
        content: {
            position: 'absolute',
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '10%',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '5%'
        },
    };

    const openModal = (e) => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCompanyId('');
        setCompanyTypeId('');
        setCompanyName('');
        setAddressLine1('');
        setAddressLine2('');
        setCity('');
        setState('');
        setZip('');
        setContact('');
        setPhone('');
        setEmail('');
        setNotes('');
        setCoNum('');
        setLaborResourceName('');
        setLaborResourceTypeId('');
        setLaborResourceSubTypeId('');
    };

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    };


    const _transformResult = (result, endPoint) => {

        // Special case for Wood Contracts
        if (endPoint === "companies") {
            result["success"].forEach((el) => {
                el.CompanyType = el.CompanyType.name;

                delete el.companyTypeId;
                delete el.CompanyTypeId;
            });
        } else if (endPoint === "laborresources") {
            result["success"].forEach((el) => {
                el.Company = el.Company.name;
                el.LaborResourceType = el.LaborResourceType.name;

                if (el.LaborResourceSubType && el.LaborResourceSubType.name) {
                    el.LaborResourceSubType = el.LaborResourceSubType.name;
                }
            });
        } else {
            return;
        }
    };


    let handleClick = async (menuIndex, e) => {
        try {

            switch (menuIndex) {
                case 0:
                    setMenuSelected("laborresources");
                    break;
                case 1:
                    setMenuSelected("companies");
                    break;
                default:
                    setMenuSelected('');
            }

            let colors = ["white", "white", "white", "white"];
            colors[menuIndex] = "#90ee90";

            setMenuColors(colors);

            let result;
            let columnDefs = [];
            let endPoint = e.target.innerText.toLowerCase().replace(/\s/g, '');

            let _companyTypes = [], _companyTypeNames = [], companyTypesResult;
            let _companies = [], _companyNames = [], companiesResult;
            let _laborResourceTypes = [], _laborResourceTypeNames = [],  laborResourceTypesResult;
            let _laborResourceSubTypes = [], _laborResourceSubTypeNames = [], laborResourceSubTypesResult;


            result = await getAllData('/' + endPoint);

            if (result.error) {
                console.dir(e);
                setRowData([{"error": "Unable to fetch data from table! " + result.error}]);
                columnDefs.push({field: "error"});
                setColumnDefs(columnDefs);
            } else {

                _transformResult(result, endPoint);

                // Get Lookup data to apply to columns
                if (endPoint === 'companies') {

                    companyTypesResult = await getAllData('/companytypes');
                    for (const companytype of companyTypesResult.success) {
                        _companyTypeNames.push(companytype.name);
                        _companyTypes.push(companytype);
                    }

                    _companyTypeNames = _companyTypeNames.sort();
                    _companyTypes = _companyTypes.sort((a,b) => a.name > b.name && 1 || -1);

                    setCompanyTypes([..._companyTypes]);
                }

                // Get Lookup data to apply to columns
                if (endPoint === 'laborresources') {

                    companiesResult = await getAllData('/companies');
                    for (const company of companiesResult.success) {
                        _companies.push(company);
                        _companyNames.push(company.name);
                    }

                    _companyNames = _companyNames.sort();
                    _companies = _companies.sort((a,b) => a.name > b.name && 1 || -1);

                    setCompanies([..._companies]);

                    laborResourceTypesResult = await getAllData('/laborresourcetypes');
                    for (const lrt of laborResourceTypesResult.success) {
                        _laborResourceTypeNames.push(lrt.name);
                        _laborResourceTypes.push(lrt);
                    }

                    _laborResourceTypeNames = _laborResourceTypeNames.sort();
                    _laborResourceTypes = _laborResourceTypes.sort((a,b) => a.name > b.name && 1 || -1);

                    setLaborResourceTypes([..._laborResourceTypes]);

                    laborResourceSubTypesResult = await getAllData('/laborresourcesubtypes');
                    for (const lrst of laborResourceSubTypesResult.success) {
                        _laborResourceSubTypeNames.push(lrst.name);
                        _laborResourceSubTypes.push(lrst);
                    }

                    _laborResourceSubTypeNames = _laborResourceSubTypeNames.sort();
                    _laborResourceSubTypes = _laborResourceSubTypes.sort((a,b) => a.name > b.name && 1 || -1);

                    setLaborResourceSubTypes([..._laborResourceSubTypes]);
                }

                setRowData(result.success);

                let columnFields = Object.keys(result.success[0]);
                for (let col in columnFields) {

                    if ((columnFields[col] !== "createdAt") && (columnFields[col] !== "updatedAt")) {
                        if (columnFields[col] === 'id') {
                            columnDefs.push({
                                field: columnFields[col],
                                sortable: true,
                                flex: "initial",
                                pinned: "left",
                                maxWidth: 100
                            })
                        } else if (columnFields[col] === 'inactive') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                flex: "initial",
                                filter: true,
                                pinned: "right",
                                maxWidth: 100,
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: ['false', 'true']}
                            })
                        } else if (columnFields[col] === 'name' && endPoint === "companies") {
                            columnDefs.push({
                                field: columnFields[col],
                                sortable: true,
                                editable: true,
                                filter: true,
                                flex: "initial",
                                pinned: "left",
                            })
                        } else if (columnFields[col] === 'name' && endPoint === "laborresources") {
                            columnDefs.push({
                                field: columnFields[col],
                                sortable: true,
                                filter: true,
                                editable: true,
                                flex: "initial",
                                pinned: false,
                            })
                        } else if (columnFields[col] === 'CompanyType') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                filter: true,
                                sortable: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: _companyTypeNames}
                            })
                        } else if (columnFields[col] === 'Company') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                pinned: "left",
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: _companyNames}
                            })
                        } else if (columnFields[col] === 'LaborResourceType') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: _laborResourceTypeNames}
                            })
                        } else if (columnFields[col] === 'LaborResourceSubType') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: _laborResourceSubTypeNames}
                            })
                        } else {
                            columnDefs.push({field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial"})
                        }
                    }
                }

                setColumnDefs(columnDefs);
                setTableHeading(e.target.innerText.toUpperCase());

            }
        } catch (error) {
            console.log("ERROR: Unable to fetch data from table! " + error)
            setRowData([{"error": "Unable to fetch data from table! " + error}]);
            setColumnDefs([{field: "error"}]);
        }
    };

    let handleEdit = async e => {
        try {
            // Update updated at
            console.dir(e);
            e.data.updatedAt = Date.now();

            console.log("Cell data to be changed: " + JSON.stringify(e.data));

            let result;
            let endPoint = tableHeading.toLowerCase().replace(/\s/g, '');

            if (e.data.id) {
                result = await updateData('/' + endPoint, e.data);
                if (result.error) {
                    console.log("Update failed: " + result.error);
                    setTableError(result.error);
                    e.api.undoCellEditing();
                    setTimeout(
                        () => setTableError(''),
                        5000
                    );
                }
            } else {
                e.data.createdAt = Date.now();
                e.data.updatedAt = e.data.createdAt;
                result = await insertData('/' + endPoint, e.data);

                if (result.success) {

                    let refreshResult = await getAllData('/' + endPoint);
                    _transformResult(refreshResult, endPoint);
                    let newRowData = [...refreshResult.success];
                    setRowData(newRowData);

                } else {
                    console.log("Insert failed: " + result.error);
                    setTableError(result.error);
                    e.api.undoCellEditing();
                    setTimeout(
                        () => setTableError(''),
                        5000
                    );
                }
            }

        } catch (error) {
            console.log("Failed to update cell data: " + error);
            setTableError(error);
            e.api.undoCellEditing();
        }
    };
    const handleChange = async (input, e) => {
        //e.preventDefault();

        switch (input) {
            case 'company':
                setCompanyId(e.target.value);
                break;
            case 'companytype':
                setCompanyTypeId(e.target.value);
                break;
            case 'laborresourcename':
                setLaborResourceName(e.target.value);
                break;
            case 'laborresourcetype':
                setLaborResourceTypeId(e.target.value);
                break;
            case 'laborresourcesubtype':
                setLaborResourceSubTypeId(e.target.value);
                break;
            case 'companyname':
                setCompanyName(e.target.value);
                break;
            case 'addressline1':
                setAddressLine1(e.target.value);
                break;
            case 'addressline2':
                setAddressLine2(e.target.value);
                break;
            case 'city':
                setCity(e.target.value);
                break;
            case 'state':
                setState(e.target.value);
                break;
            case 'zip':
                setZip(e.target.value);
                break;
            case 'contact':
                setContact(e.target.value);
                break;
            case 'email':
                setEmail(e.target.value);
                break;
            case 'phone':
                setPhone(e.target.value);
                break;
            case 'notes':
                setNotes(e.target.value);
                break;
            case 'coNum':
                setCoNum(e.target.value);
                break;
            default:
                console.log("Handle change input not found.")
        }
    };


    let handleSubmit = async (e) => {
        e.preventDefault();

        let formData = {}, endPoint;

        if (e.target.id === 'create-laborresource-form') {
            endPoint = 'laborresources';
            formData.companyId = companyId;
            formData.name = laborResourceName;
            formData.laborResourceTypeId = laborResourceTypeId;
            formData.laborResourceSubTypeId = laborResourceSubTypeId;
            formData.addressLine1 = addressLine1;
            formData.addressLine2 = addressLine2;
            formData.city = city;
            formData.state = state;
            formData.zip = zip;

        } else { // Company
            endPoint = 'companies';
            formData.companyTypeId = companyTypeId;
            formData.name = companyName;
            formData.addressLine1 = addressLine1;
            formData.addressLine2 = addressLine2;
            formData.city = city;
            formData.state = state;
            formData.zip = zip;
            formData.contact = contact;
            formData.phone = phone;
            formData.email = email;
            formData.notes = notes;
            formData.coNum = coNum;
        }

        console.log("Request Form Data: " + JSON.stringify(formData));


        let result, refreshResult, action = "Inserted";

        try {

            console.log("Inserting new data ...");
            closeModal();
             result = await insertData('/' + endPoint, formData);

            if (result.success) {
                console.log("Insert success: " + JSON.stringify(result.success));
                refreshResult = await getAllData('/' + endPoint);
               _transformResult(refreshResult, endPoint);
                let newRowData = [...refreshResult.success];
                setRowData(newRowData);

            } else {
                console.log(action + " failed: " + JSON.stringify(result.error));
                setTableError(result.error);
                setTimeout(
                    () => setTableError(''),
                    5000
                );
            }
        } catch (error) {
            console.log(action + " failed: " + JSON.stringify(error));
            setTableError("ERROR: " + error);
            setTimeout(
                () => setTableError(''),
                5000
            );
        }
    };

    // Uncomment for debugging
    //console.log(JSON.stringify(rowData));
    //console.log(JSON.stringify(columnDefs));

    // Role for Data Admin Access Required
    let regex = /ADMIN|SUPER/;

    if (props.user_details.match(regex)) {
        if (! modalIsOpen) {
            return (
                <main>
                    <h1>Labor Resource Management</h1>
                    <Grid container>
                        <Grid item xs={3}>
                            <p>Please select a table to edit.</p>
                            <ButtonGroup orientation="vertical" size="large" aria-label="outlined primary button group">
                                <Button style={{background: menuColors[1]}} onClick={(e) => handleClick(1, e)}>Companies
                                </Button>
                                <Button style={{background: menuColors[0]}} onClick={(e) => handleClick(0, e)}>Labor
                                    Resources</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                <h2 className="grid-table-heading">{tableHeading}</h2>
                                <div style={{
                                    color: "red",
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    padding: "10px"
                                }}>{tableError}</div>
                                <AddIcon hidden={!menuSelected} onClick={(e) => openModal(e)} />
                                <AgGridReact undoRedoCellEditing={true} rowData={rowData} columnDefs={columnDefs}
                                             onCellValueChanged={handleEdit}/>
                            </div>
                        </Grid>
                    </Grid>
                </main>
            );
        } else {
            if (menuSelected === 'companies') {
                return (
                    <main>
                        <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Enter Company"
                        >
                            <form onSubmit={handleSubmit} id={"create-company-form"} autoComplete="off">
                                <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-start"
                                      style={{padding: 60}}>
                                    <Grid item xs={12}>
                                        <h2>Add Company</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="company-name-text"
                                            label="Company Name"
                                            required="true"
                                            onChange={(e) => handleChange("companyname", e)}
                                            value={companyName}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel id="companytype-label">Company Type</InputLabel>
                                        <Select labelId="companytype-label" required="true" id="select-companytype"
                                                value={companyTypeId}
                                                onChange={(e) => handleChange("companytype", e)} >
                                            {companyTypes.length > 0 && companyTypes.map((el, index) =>
                                                <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="addressline1-text"
                                            label="Address Line 1"
                                            required="true"
                                            onChange={(e) => handleChange("addressline1", e)}
                                            value={addressLine1}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="addressline2-text"
                                            label="Address Line 2"
                                            onChange={(e) => handleChange("addressline2", e)}
                                            value={addressLine2}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="city-text"
                                            label="City"
                                            required="true"
                                            onChange={(e) => handleChange("city", e)}
                                            value={city}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="state-text"
                                            label="State"
                                            required="true"
                                            onChange={(e) => handleChange("state", e)}
                                            value={state}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="zip-text"
                                            label="Zip"
                                            required="true"
                                            onChange={(e) => handleChange("zip", e)}
                                            value={zip}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="contact-text"
                                            label="Contact"
                                            onChange={(e) => handleChange("contact", e)}
                                            value={contact}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="phone-text"
                                            label="Phone"
                                            onChange={(e) => handleChange("phone", e)}
                                            value={phone}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="email-text"
                                            label="Email"
                                            onChange={(e) => handleChange("email", e)}
                                            value={email}
                                        />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            id="notes-text"
                                            label="NOTES"
                                            onChange={(e) => handleChange("notes", e)}
                                            value={notes}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="conum-text"
                                            label="Company Number"
                                            onChange={(e) => handleChange("coNum", e)}
                                            value={coNum}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" style={{color: "green"}}>Add</Button>
                                    </Grid>

                                </Grid>
                            </form>
                        </Modal>
                    </main>

                );

            } else  {
                return (
                    <main>
                        <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Enter Labor Resource"
                        >
                            <form onSubmit={handleSubmit} id={"create-laborresource-form"} autoComplete="off">
                                <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-start"
                                      style={{padding: 60}}>
                                    <Grid item xs={12}>
                                        <h2>Add Labor Resource</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel id="company-label">Company</InputLabel>
                                        <Select labelId="company-label" required="true" id="select-company"
                                                value={companyId}
                                                onChange={(e) => handleChange("company", e)} >
                                            {companies.length > 0 && companies.map((el, index) =>
                                                <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="laborresource-name-text"
                                            label="Labor Resource Name"
                                            required="true"
                                            onChange={(e) => handleChange("laborresourcename", e)}
                                            value={laborResourceName}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel id="laborresourcetype-label">Labor Resource Type</InputLabel>
                                        <Select labelId="laborresourcetype-label" required="true" id="select-laborresourcetype"
                                                value={laborResourceTypeId}
                                                onChange={(e) => handleChange("laborresourcetype", e)} >
                                            {laborResourceTypes.length > 0 && laborResourceTypes.map((el, index) =>
                                                <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel id="laborresourcesubtype-label">Labor Resource SubType</InputLabel>
                                        <Select labelId="laborresourcesubtype-label" required="true" id="select-laborresourcesubtype"
                                                value={laborResourceSubTypeId}
                                                onChange={(e) => handleChange("laborresourcesubtype", e)} >
                                            {laborResourceSubTypes.length > 0 && laborResourceSubTypes.map((el, index) =>
                                                <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="addressline1-text"
                                            label="Address Line 1"
                                            onChange={(e) => handleChange("addressline1", e)}
                                            value={addressLine1}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="addressline2-text"
                                            label="Address Line 2"
                                            onChange={(e) => handleChange("addressline2", e)}
                                            value={addressLine2}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="city-text"
                                            label="City"
                                            onChange={(e) => handleChange("city", e)}
                                            value={city}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="state-text"
                                            label="State"
                                            onChange={(e) => handleChange("state", e)}
                                            value={state}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="zip-text"
                                            label="Zip"
                                            onChange={(e) => handleChange("zip", e)}
                                            value={zip}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" style={{color: "green"}}>Add</Button>
                                    </Grid>

                                </Grid>
                            </form>
                        </Modal>
                    </main>
                );

            }

        }

    } else {
        return (
            <main>
                <h1>Labor Resource Management</h1>
                <p>NOTE: You do not have privilege to access this menu.</p>
            </main>
        );
    }
}