import React from 'react';

export default function Logout() {

    function logout() {
        sessionStorage.clear();
        window.location.reload();
    }

    return(
            <button className="btn btn-success" onClick={logout}>
                Logout
            </button>
    );
}