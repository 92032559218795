import React, {useState} from 'react';

// Material-UI Companents
import AddIcon from '@material-ui/icons/Add';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {InputLabel, MenuItem, Select, TextField, FormControl, FormLabel, RadioGroup} from "@material-ui/core";
import config from "../../config/config";

import Grid from '@material-ui/core/Grid';

import LoadingOverlay from 'react-loading-overlay';


async function getData(endPoint, queryString, orderBy) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-querystring': queryString,
            'x-wtg-orderby': orderBy
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function getCompanyData(endPoint, resourceTypeId, resourceSubTypeId) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-resourcetypeid': resourceTypeId,
            'x-wtg-resourcesubtypeid': resourceSubTypeId
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function setLookups(endPoint, queryString = '{"inactive": "false"}', orderBy = '[["name", "ASC"]]', fields = '[]') {
    let result, landOwnerResult, contractGroupResult, data = [];

    // Add query conditions to further filter products and species to available Mill Contracts
    if (endPoint === "lookupproducts" || endPoint === "lookupspecies") {

        let woodLotId = fields[0].woodLotId;
        let receivingSiteId = fields[0].receivingSiteId;
        let landOwnerId, contractGroupId, woodContractsResult;

        console.log("Wood Lot: " + woodLotId);
        console.log("Receiving Site: " + receivingSiteId);

        // Get the Land Owner from the wood Lot
        landOwnerResult = await getData('/lookupwoodlots', '{"id": "' + woodLotId + '"}', '[["name", "ASC"]]');

        if (landOwnerResult.success[0] && landOwnerResult.success[0].LandOwner) {
            landOwnerId = landOwnerResult.success[0].LandOwner.id;
        } else {
            return [];
        }

        console.log("Land Owner Id: " + landOwnerId);

        contractGroupResult = await getData('/lookuplandownercontractgroups', '{"landOwnerId": "' +
            landOwnerId + '"}', '[["id", "ASC"]]');

        if (contractGroupResult.success[0] && contractGroupResult.success[0].ContractGroup) {
            contractGroupId = contractGroupResult.success[0].ContractGroup.id;
        } else {
            return [];
        }

        console.log("Contract Group Id: " + contractGroupId);


        // Query Mill Contracts for Products and Species

        woodContractsResult = await getData('/lookupwoodcontracts', '{"contractGroupId": "' + contractGroupId
            + '","receivingSiteId": "' + receivingSiteId + '"}', '[["id", "ASC"]]');

        console.log("WoodContractsResult: " + JSON.stringify(woodContractsResult));

        if (woodContractsResult.success && woodContractsResult.success.length > 0) {

            for (const el of woodContractsResult.success) {
                if (endPoint === "lookupproducts") {

                    data.push({"id": el.Product.id, "name": el.Product.name})
                } else {

                    data.push({"id": el.Specie.id, "name": el.Specie.name})
                }
            }

            if (endPoint === "lookupproducts") {
                data.push({"id": fields[0].productId, "name": fields[0].productName})
            } else {
                data.push({"id": fields[0].specieId, "name": fields[0].specieName})
            }

            let set = new Set(data.map(item => JSON.stringify(item)));
            let uniqueData = [...set].map(item => JSON.parse(item));

            // console.log("Unique data: " + JSON.stringify(uniqueData));

            return (uniqueData.sort((a, b) => a.name > b.name ? 1 : -1));

        } else {

            return [];
        }

    }

    result = await getData('/' + endPoint, queryString, orderBy);

    if (result.success && result.success.length > 0) {

        for (const el of result.success) {
            data.push({"id": el.id, "name": el.name})
        }

        if ((endPoint === "lookupgrades") || (endPoint === "lookuplengths")) {

            data.push({"id": 0, "name": "NONE"})
        }

        return (data);
    } else {
        return [];
    }

}

async function setCompanyLookups(endPoint, resourceTypeId, resourceSubTypeId) {
    let result, data = [];

    result = await getCompanyData('/' + endPoint, resourceTypeId, resourceSubTypeId);

    if (result.success && result.success.length > 0) {

        for (const el of result.success) {
            data.push({"id": el.Company.id, "name": el.Company.name})
        }

        return (data);
    } else {
        return [];
    }

}

export default function LaborResources(props) {

    const [productId, setProductId] = useState('');
    const [products, setProducts] = useState('');
    const [specieId, setSpecieId] = useState('');
    const [species, setSpecies] = useState('');
    const [gradeId, setGradeId] = useState('');
    const [grades, setGrades] = useState('');
    const [lengthId, setLengthId] = useState('');
    const [lengths, setLengths] = useState('');

    const [gross, setGross] = useState("");
    const [tare, setTare] = useState(0);
    const [net, setNet] = useState("");
    const [convertedNet, setConvertedNet] = useState("");
    const [unitId, setUnitId] = useState("");
    const [units, setUnits] = useState('');

    const [hideProductLineItem, setHideProductLineItem] = useState(true);
    const [hideAddProductLineItem, setHideAddProductLineItem] = useState(true);
    const [hideAddIcon, setHideAddIcon] = useState(false);
    const [hideModifyIcon, setHideModifyIcon] = useState(false);
    const [hideDeleteIcon, setHideDeleteIcon] = useState(false);
    const [productLineItems, setProductLineItems] = useState(props.products);
    const [productIdSelected, setProductIdSelected] = useState('');
    const [addProduct, setAddProduct] = useState(false);
    const [modifyProduct, setModifyProduct] = useState(false);
    const [asyncOpRunning, setAsyncOpRunning] = useState(false);


    const handleAddClick = async () => {

        setHideProductLineItem(!hideProductLineItem);
        setHideAddProductLineItem(true);
        setProductIdSelected('');

        setAsyncOpRunning(true);

        setProducts(await setLookups("lookupproducts", undefined, undefined,
            [{woodLotId: props.woodLotId, receivingSiteId: props.receivingSiteId}]));
        setSpecies(await setLookups("lookupspecies", undefined, undefined,
            [{woodLotId: props.woodLotId, receivingSiteId: props.receivingSiteId}]));
        setGrades(await setLookups("lookupgrades"));
        setLengths(await setLookups("lookuplengths"));
        setUnits(await setLookups("lookupunits"));

        setProductId('');
        setSpecieId('');
        setGradeId('');
        setLengthId('');
        setUnitId('');
        setGross('');
        setTare(0);
        setNet('');
        setConvertedNet('');
        setHideAddIcon(false);
        setHideModifyIcon(!hideModifyIcon);
        setHideDeleteIcon(!hideDeleteIcon);
        setAddProduct(!addProduct);
        setModifyProduct(false);

        setAsyncOpRunning(false);
    };

    // Method also supports modifyProduct
    const handleAddProduct = async () => {

        let productLine;

        let p = products.find(x => x.id == productId);
        let s = species.find(x => x.id == specieId);

        let g = {};
        if (gradeId) {
            g = grades.find(x => x.id == gradeId);
        } else {
            g.name = null;
        }
        let l = {};
        if (lengthId) {
            l = lengths.find(x => x.id == lengthId);
        } else {
            l.name = null;
        }

        let u = units.find(x => x.id == unitId);

        productLine = {
            "id": productIdSelected,
            "woodTicketId": props.getTicketId(),
            "productId": productId,
            "specieId": specieId,
            "gradeId": gradeId,
            "lengthId": lengthId,
            "unitId": unitId,
            "gross": gross,
            "tare": tare,
            "net": net,
            "convertedNet": convertedNet,
            "Product": {"name": p.name},
            "Specie": {"name": s.name},
            "Grade": {"name": (g && g.name)},
            "Length": {"name": (l && l.name)},
            "Unit": {"name": u.name}
        };

        if (modifyProduct) {
            productLine.modifiedId = productIdSelected;
        }

        setProductLineItems([...productLineItems, ...[JSON.parse(JSON.stringify(productLine))]]);
        props.handleProductChange([...productLineItems, ...[JSON.parse(JSON.stringify(productLine))]]);

        setHideProductLineItem(!hideProductLineItem);
        props.productHasChanged();

        setHideAddIcon(false);
        setHideModifyIcon(false);
        setHideDeleteIcon(false);
        setAddProduct(false);
        setModifyProduct(false);
    };

    const handleDeleteClick = async (index) => {

        let newProductLineItems = [...productLineItems];

        newProductLineItems.splice(index, 1);

        setProductLineItems([...newProductLineItems]);
        props.handleProductChange([...newProductLineItems]);
        props.productHasChanged();
        setAddProduct(false);
        setModifyProduct(false);

    };

    const handleModifyClick = async (index) => {

        setHideProductLineItem(!hideProductLineItem);
        setHideAddProductLineItem(true);
        setHideAddIcon(!hideAddIcon);

        setAsyncOpRunning(true);

        setProducts(await setLookups("lookupproducts", undefined, undefined,
            [{
                "woodLotId": props.woodLotId, "receivingSiteId": props.receivingSiteId,
                "productId": productLineItems[index].productId,
                "productName": productLineItems[index].Product.name,
                "specieId": productLineItems[index].specieId,
                "specieName": productLineItems[index].Specie.name
            }]));
        setSpecies(await setLookups("lookupspecies", undefined, undefined,
            [{
                "woodLotId": props.woodLotId, "receivingSiteId": props.receivingSiteId,
                "productId": productLineItems[index].productId,
                "productName": productLineItems[index].Product.name,
                "specieId": productLineItems[index].specieId,
                "specieName": productLineItems[index].Specie.name
            }]));
        setGrades(await setLookups("lookupgrades"));
        setLengths(await setLookups("lookuplengths"));
        setUnits(await setLookups("lookupunits"));

        setProductId(productLineItems[index].productId);
        setSpecieId(productLineItems[index].specieId);
        if (productLineItems[index].gradeId) {
            setGradeId(productLineItems[index].gradeId);
        } else {
            setGradeId('');
        }
        if (productLineItems[index].lengthId) {
            setLengthId(productLineItems[index].lengthId);
        } else {
            setLengthId('');
        }
        setUnitId(productLineItems[index].unitId);
        setGross(productLineItems[index].gross);
        setTare(productLineItems[index].tare);
        setNet(productLineItems[index].net);
        setConvertedNet(productLineItems[index].convertedNet);

        let newProductLineItems = [...productLineItems];
        newProductLineItems.splice(index, 1);
        setProductLineItems([...newProductLineItems]);

        // Intention to modify product
        // selectedProductId has the Id
        setAddProduct(false);
        setModifyProduct(true);
        setHideDeleteIcon(!hideDeleteIcon);
        setHideModifyIcon(!hideModifyIcon);

        setAsyncOpRunning(false);

    };

    const handleUnModifyClick = async () => {
        setModifyProduct(false);
        setHideProductLineItem(true);
        setProductLineItems(props.products);
        setHideAddIcon(false);
        setHideDeleteIcon(false);
        setHideModifyIcon(false);
    };

    const handleChange = async (input, e) => {

        let netValue;
        let divisor = 2000;
        if (unitId && (unitId != 1)) {
            divisor = 1000;
        }

        switch (input) {
            case 'product':

                if (e.target.value && specieId && unitId && gross) {
                    setHideAddProductLineItem(false);
                }

                setProductId(e.target.value);
                break;
            case 'specie':

                if (e.target.value && productId && unitId && gross) {
                    setHideAddProductLineItem(false);
                }
                setSpecieId(e.target.value);
                break;
            case 'grade':

                if (productId && specieId && unitId && gross) {
                    setHideAddProductLineItem(false);
                }
                setGradeId(e.target.value);
                break;
            case 'length':
                if (productId && specieId && unitId && gross) {
                    setHideAddProductLineItem(false);
                }
                setLengthId(e.target.value);
                break;
            case 'unit':

                if (e.target.value && productId && specieId && gross) {
                    setHideAddProductLineItem(false);
                }

                setUnitId(e.target.value);
                if (e.target.value === 1) {
                    divisor = 2000;
                } else {
                    divisor = 1000
                }

                if (net) {
                    setConvertedNet(parseFloat(net / divisor).toFixed(2));
                }

                break;
            case 'gross':

                if (e.target.value && e.target.value.length > 0 && productId && specieId && unitId) {
                    setHideAddProductLineItem(false);
                } else {

                    setHideAddProductLineItem(true);
                }

                if (e.target.value && e.target.value.length > 0) {
                    setGross(e.target.value);
                    if (tare) {
                        netValue = (parseFloat(e.target.value) - parseFloat(tare)).toFixed(2);
                        setNet(netValue);
                        setConvertedNet(parseFloat(netValue / divisor).toFixed(2));
                    } else {

                        netValue = parseFloat(e.target.value).toFixed(2);
                        setNet(netValue);
                        setConvertedNet(parseFloat(netValue / divisor).toFixed(2));
                    }
                } else {

                    setGross("");
                    setNet("");
                    setConvertedNet("");
                }
                break;
            case 'tare':
                if (e.target.value && e.target.value.length > 0) {
                    setTare(e.target.value);
                    if (gross) {
                        netValue = (parseFloat(gross) - parseFloat(e.target.value)).toFixed(2);
                        setNet(netValue);
                        setConvertedNet(parseFloat(netValue / divisor).toFixed(2));
                    } else {

                        setNet("");
                        setConvertedNet("");
                    }
                } else {

                    setTare(0);
                    netValue = (parseFloat(gross) - 0.00).toFixed(2);
                    setNet(netValue);
                    setConvertedNet(parseFloat(netValue / divisor).toFixed(2));
                }
                break;
            case 'net':
                setNet(e.target.value);
                break;
            case 'convertedNet':
                setConvertedNet(e.target.value);
                break;
            default:
                console.log("Handle change input not found.")

        }
    };

    const handleProductClick = async (id, e) => {

        console.log("Product Id clicked: " + id);

        if (addProduct || modifyProduct) {
            return;
        }

        if (id) {
            setProductIdSelected(id);
            props.setProductIdSelected(id);
        }
    };


    return (


        <div align="left">
            <h5>Product Data</h5>

            <LoadingOverlay
                active={asyncOpRunning}
                spinner
                text="Loading ..."
            >
            <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-start">

                <Grid item xs={12}>
                    <Grid container wrap="nowrap" spacing={3} direction="row" alignItems="flex-start"
                          justify="flex-start">
                        <Grid item xs={3}>
                            <u>Selected?</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Id</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Product</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Species</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Grade</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Length</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Unit</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Gross</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Tare</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Net</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Converted Net</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Modify</u>
                        </Grid>
                        <Grid item xs={3}>
                            <u>Delete</u>
                        </Grid>
                    </Grid>
                    {productLineItems.length > 0 && productLineItems.map((el, index) => {
                        return (
                            <Grid key={index} wrap="nowrap" onClick={(e) => handleProductClick(el.id, e)}
                                  container spacing={3} direction="row" alignItems="flex-start" justify="flex-start">
                                <Grid item xs={3}>
                                    <RadioButtonCheckedIcon hidden={productIdSelected != el.id}/>
                                </Grid>
                                <Grid item xs={3}>
                                    {el.id}
                                </Grid>
                                <Grid item xs={3}>
                                    {el.Product.name}
                                </Grid>
                                <Grid item xs={3}>
                                    {el.Specie.name}
                                </Grid>
                                <Grid item xs={3}>
                                    {(el.Grade && el.Grade.name)}
                                </Grid>
                                <Grid item xs={3}>
                                    {(el.Length && el.Length.name)}
                                </Grid>
                                <Grid item xs={3}>
                                    {el.Unit.name}
                                </Grid>
                                <Grid item xs={3}>
                                    {el.gross}
                                </Grid>
                                <Grid item xs={3}>
                                    {el.tare}
                                </Grid>
                                <Grid item xs={3}>
                                    {el.net}
                                </Grid>
                                <Grid item xs={3}>
                                    {el.convertedNet}
                                </Grid>
                                <Grid item xs={3}>
                                    <TrackChangesIcon hidden={hideModifyIcon} onClick={() => handleModifyClick(index)}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <DeleteIcon hidden={hideDeleteIcon} onClick={() => handleDeleteClick(index)}/>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>

                <Grid item xs={12}>
                    <AddIcon onClick={handleAddClick} hidden={hideAddIcon}/>
                </Grid>

                <Grid item xs={12}>
                    <h6 hidden={!addProduct}><i>Adding NEW Product ...</i></h6>
                    <h6 hidden={!modifyProduct} onClick={handleUnModifyClick}><i><CloseIcon/>&nbsp;&nbsp;Modifying Product
                        Id: {productIdSelected} ...</i></h6>
                </Grid>

                <Grid item xs={12} hidden={hideProductLineItem}>
                    <Grid container wrap="nowrap" spacing={3} direction="row" alignItems="flex-start"
                          justify="flex-start">
                        <Grid item xs={3}>
                            <InputLabel id="product-label">Product</InputLabel>
                            <Select labelId="product-label" required="true" id="select-product" value={productId}
                                    onChange={(e) => handleChange("product", e)}>
                                {products.length > 0 && products.map((el, index) =>
                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="specie-label">Species</InputLabel>
                            <Select labelId="specie-label" required="true" id="select-specie" value={specieId}
                                    onChange={(e) => handleChange("specie", e)}>
                                {species.length > 0 && species.map((el, index) =>
                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="grade-label">Grade</InputLabel>
                            <Select labelId="grade-label" id="select-grade" value={gradeId}
                                    onChange={(e) => handleChange("grade", e)}>
                                {grades.length > 0 && grades.map((el, index) =>
                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="length-label">Length</InputLabel>
                            <Select labelId="length-label" id="select-length" value={lengthId}
                                    onChange={(e) => handleChange("length", e)}>
                                {lengths.length > 0 && lengths.map((el, index) =>
                                    <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="unit-label">Unit</InputLabel>
                            <Select labelId="unit-label" required="true" id="select-unit" value={unitId}
                                    onChange={(e) => handleChange("unit", e)}>
                                {units.length > 0 && units.map((el, index) =>
                                    <MenuItem key={index} value={el.id} name={el.name}>{el.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} hidden={hideProductLineItem}>
                    <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-start">

                        <Grid item xs={3}>
                            <TextField
                                id="gross-weight"
                                label="GROSS"
                                variant="outlined"
                                helperText="Required"
                                required="true"
                                value={gross}
                                onChange={(e) => handleChange("gross", e)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="tare-weight"
                                label="TARE"
                                variant="outlined"
                                helperText="Required"
                                onChange={(e) => handleChange("tare", e)}
                                required="true"
                                value={tare}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="net-weight"
                                label="NET"
                                variant="outlined"
                                helperText="Calculated Value"
                                value={net}
                                disabled="true"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="converted-net-weight"
                                label="Converted NET"
                                variant="outlined"
                                helperText="Calculated Value"
                                value={convertedNet}
                                disabled="true"
                            />
                            &nbsp; &nbsp; &nbsp; &nbsp;<DoneAllIcon hidden={hideAddProductLineItem}
                                                                    onClick={handleAddProduct}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </LoadingOverlay>
        </div>

    )
};