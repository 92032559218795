import React from 'react';

export default function Dashboard() {
    return(
        <main>
            <h1>Dashboard</h1>
            <img src="https://www.cambridgemaths.org/Images/correspondence_1%404x.png" alt="Bar Chart" />
            <br/><br/>
            <p>Coming soon ...</p>
        </main>
    );
}