import React, {useState} from 'react';
import config from "../../config/config.json";
import './ag-grid.css';

// Material-UI Components
import {ButtonGroup, Button, Checkbox, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';


// AG Grid Components
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// Date/Time Support
import moment from 'moment';
import 'moment-timezone';
import Modal from "react-modal";

async function getData(endPoint, queryString, orderBy) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-querystring': queryString,
            'x-wtg-orderby': orderBy
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function insertData(endPoint, postData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(postData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function updateData(endPoint, putData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(putData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}


export default function WoodSupplyChain(props) {

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [tableHeading, setTableHeading] = useState('');
    const [tableError, setTableError] = useState('');
    const [menuColors, setMenuColors] = useState(['white', 'white', 'white', 'white']);
    const [landOwnersLookup, setLandOwnersLookup] = useState([]);
    const [locationsLookup, setLocationsLookup] = useState([]);
    const [adminWoodLotsLookup, setAdminWoodLotsLookup] = useState([]);
    const [menuIndex, setMenuIndex] = useState('');

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    // Modal Forms
    const [menuSelected, setMenuSelected] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Modal Form data
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [landOwnerId, setLandOwnerId] = useState('');
    const [locationId, setLocationId] = useState('');
    const [adminWoodLotId, setAdminWoodLotId] = useState('');
    const [effDate, setEffDate] = useState('');
    const [expDate, setExpDate] = useState('');
    const [FON, setFON] = useState('');
    const [isAdminWoodLot, setIsAdminWoodLot] = useState(false);
    const [twoTrips, setTwoTrips] = useState(false);
    const [ticketText, setTicketText] = useState('');


    const customStyles = {
        content: {
            position: 'absolute',
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '10%',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '5%'
        },
    };

    const openModal = (e) => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setName('');
        setDescription('');
        setLandOwnerId('');
        setAdminWoodLotId('');
        setLocationId('');
        setEffDate('');
        setExpDate('');
        setFON('');
        setIsAdminWoodLot(false);
        setTwoTrips(false);
        setTicketText('');
    };

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    };


    const _transformResult = (result, endPoint) => {


        // Special case for WoodLots table
        if (endPoint === "woodlots") {
            result["success"].forEach((el) => {
                el.LandOwner = el.LandOwner.name;
                el.Location = el.Location.name;

                if (el.lastMFSEffDate) {
                    el.lastMFSEffDate = moment(el.lastMFSEffDate).format('M/D/YYYY')
                }
                if (el.lastMFSExpDate) {
                    el.lastMFSExpDate = moment(el.lastMFSExpDate).format('M/D/YYYY')
                }

                if (el.AdminWoodLot) {
                    el.AdminWoodLot = el.AdminWoodLot.name;
                }

                delete el.landOwnerId;
                delete el.adminWoodLotId;
                delete el.AdminWoodLotId;
                delete el.locationId;
                delete el.LandOwnerId;
                delete el.LocationId;
            });

        }

       // Land Owner Contract Groups
        if (endPoint === "landownercontractgroups") {
            result["success"].forEach((el) => {
                el.LandOwner = el.LandOwner.name;
                el.ContractGroup = el.ContractGroup.name;

                delete el.landOwnerId;
                delete el.contractGroupId;
                delete el.LandOwnerId;
                delete el.ContractGroupId;
            });
        }

        // Special case for Receiving Sites table
        if (endPoint === "receivingsites") {
            result["success"].forEach((el) => {
                el.receivingSiteType = el.ReceivingSiteType.name;
                delete el.ReceivingSiteType;
                delete el.ReceivingSiteTypeId;
                delete el.receivingSiteTypeId;
            });

        }
    } // End _transformResult


    let handleClick = async (mIndex, e) => {
        try {

            let colors = ["white", "white", "white", "white"];
            colors[mIndex] = "#90ee90";

            setMenuColors(colors);
            setMenuIndex(mIndex);

            let result;
            let columnDefs = [];
            let endPoint;

            if (e && e.target && e.target.innerText) {
                endPoint = e.target.innerText.toLowerCase().replace(/\s/g, '');
            } else {
                endPoint = e;
            }

            let landOwners = [], landOwnersResult, locations = [], locationsResult, contractGroupsResult, contractGroups = [];
            let adminWoodLots = [], adminWoodLotsResult;
            let _tLos = [], _tLocs = [], _tAdmins = [];

            result = await getData('/' + endPoint);

            if (result.error) {
                console.dir(e);
                setRowData([{"error": "Unable to fetch data from table! " + result.error}]);
                columnDefs.push({field: "error"});
                setColumnDefs(columnDefs);
            } else {

                _transformResult(result, endPoint);

                // Get Lookup data to apply to columns
                if (endPoint === 'woodlots') {

                    setMenuSelected('woodlots');


                    landOwnersResult = await getData('/landowners');
                    for (const landOwner of landOwnersResult.success) {
                        if (!landOwner.inactive) {
                            landOwners.push(landOwner.name);
                            _tLos.push({id: landOwner.id, name: landOwner.name});
                        }
                    }

                    landOwners = landOwners.sort();
                    setLandOwnersLookup(_tLos);


                    locationsResult = await getData('/locations');
                    setLocationsLookup(locationsResult.success);
                    for (const location of locationsResult.success) {
                        if (!location.inactive) {
                            locations.push(location.name);
                            _tLocs.push({id: location.id, name: location.name});
                        }
                    }

                    locations = locations.sort();
                    setLocationsLookup(_tLocs);


                    adminWoodLotsResult = await getData('/lookupwoodlots', '{"isAdminWoodLot": true}', '[["name", "ASC"]]');
                    for (const adminWoodLot of adminWoodLotsResult.success) {
                        if (!adminWoodLot.inactive) {
                            adminWoodLots.push(adminWoodLot.name);
                            _tAdmins.push({id: adminWoodLot.id, name: adminWoodLot.name, description: adminWoodLot.description});
                        }
                    }
                    adminWoodLots.push("NONE");
                    setAdminWoodLotsLookup(_tAdmins);

                }

                if (endPoint === 'landownercontractgroups') {

                    landOwnersResult = await getData('/landowners');
                    for (const landOwner of landOwnersResult.success) {
                        if (!landOwner.inactive) {
                            landOwners.push(landOwner.name);
                        }
                    }

                    landOwners = landOwners.sort();

                    contractGroupsResult = await getData('/contractgroups');
                    for (const contractGroup of contractGroupsResult.success) {
                        if (!contractGroup.inactive) {
                            contractGroups.push(contractGroup.name);
                        }
                    }

                    contractGroups = contractGroups.sort();

                }


                if (endPoint !== 'woodlots') {
                    result.success.push({...result.success[0]});

                    const keys = Object.keys(result.success[0]);

                    keys.forEach((key, index) => {
                        if (key === 'id') {
                            result.success[result.success.length - 1][key] = null;
                        } else if (key === 'inactive') {
                            result.success[result.success.length - 1][key] = false;
                        } else if (key === 'FON') {
                            result.success[result.success.length - 1][key] = null;
                        } else if (key === 'twoTrips') {
                            result.success[result.success.length - 1][key] = false;
                        } else if (key === 'isAdminWoodLot') {
                            result.success[result.success.length - 1][key] = false;
                        } else if (key === 'ticketText') {
                            result.success[result.success.length - 1][key] = null;
                        } else {
                            result.success[result.success.length - 1][key] = '<NEW VALUE>'
                        }
                    });
                }


                setRowData(result.success);

                let columnFields = Object.keys(result.success[0]);
                for (let col in columnFields) {

                    if ((columnFields[col] !== "createdAt") && (columnFields[col] !== "updatedAt")) {

                        if (columnFields[col] === 'id') {
                            columnDefs.push({
                                field: columnFields[col],
                                sortable: true,
                                pinned: "left",
                                maxWidth: 100,
                                flex: "initial"
                            })
                        } else if (columnFields[col] === 'inactive') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                pinned: "right",
                                filter: true,
                                maxWidth: 100,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: ['false', 'true']}
                            })
                        } else if (columnFields[col] === 'twoTrips') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                maxWidth: 100,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: ['false', 'true']}
                            })
                        } else if (columnFields[col] === 'isAdminWoodLot') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                maxWidth: 100,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: ['false', 'true']}
                            })
                        } else if (columnFields[col] === 'LandOwner') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                pinned: 'left',
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: landOwners}
                            })
                        } else if (columnFields[col] === 'ContractGroup') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: contractGroups}
                            })
                        } else if (columnFields[col] === 'AdminWoodLot') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: adminWoodLots}
                            })
                        } else if (columnFields[col] === 'Location') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: locations}
                            })
                        } else if (columnFields[col] === 'receivingSiteType') {
                            columnDefs.push({
                                field: columnFields[col],
                                editable: true,
                                sortable: true,
                                filter: true,
                                flex: "initial",
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: ['Mill', 'Concentration Yard']}
                            })
                        } else {
                            columnDefs.push({field: columnFields[col], editable: true, filter: true, sortable: true, flex: "initial"})
                        }
                    }
                }

                setColumnDefs(columnDefs);

                if (e && e.target && e.target.innerText) {
                    setTableHeading(e.target.innerText.toUpperCase());
                } else {
                    setTableHeading(e.toUpperCase());
                }



            }
        } catch (error) {
            console.log("ERROR: Unable to fetch data from table! " + error)
            setRowData([{"error": "Unable to fetch data from table! " + error}]);
            setColumnDefs([{field: "error"}]);
        }
    };

    let handleEdit = async e => {
        try {
            // Update updated at
            console.dir(e);
            e.data.updatedAt = Date.now();

            console.log("Cell data to be changed: " + JSON.stringify(e.data));

            // Important Logic.  If any of the values have the string <NEW VALUE> then we do not want to POST until all values are updated,
            // that have <NEW VALUE>

            if (menuSelected != 'woodlots') {

                const keys = Object.keys(e.data);
                let regex = /<NEW VALUE>/;

                let transactRow = true;
                keys.forEach((key, index) => {
                    if (key === 'id') {
                        return;
                    } else if (key === 'inactive') {
                        return;
                    } else if (key === 'createdAt') {
                        return;
                    } else {
                        if (e.data[key]) {
                            if (e.data[key].toString().match(regex)) {
                                transactRow = false;
                            }
                        }
                    }
                });

                if (!transactRow) {
                    console.log('Found NEW DATA. Will not transact.')
                    return;
                }
            }

            let result;
            let endPoint = tableHeading.toLowerCase().replace(/\s/g, '');

            if (e.data.id) {
                result = await updateData('/' + endPoint, e.data);
                if (result.error) {
                    console.log("Update failed: " + result.error);
                    setTableError(result.error);
                    e.api.undoCellEditing();
                    setTimeout(
                        () => setTableError(''),
                        5000
                    );
                }
            } else {
                e.data.createdAt = Date.now();
                e.data.updatedAt = e.data.createdAt;
                result = await insertData('/' + endPoint, e.data);

                if (result.success) {

                    let refreshResult = await getData('/' + endPoint);

                    _transformResult(refreshResult, endPoint);


                    let newRow = {...refreshResult.success[0]};
                    let newRowData = [...refreshResult.success];

                    const keys = Object.keys(newRow);

                    keys.forEach((key, index) => {
                        if (key === 'id') {
                            newRow[key] = null;
                        } else if (key === 'inactive') {
                            newRow[key] = false;
                        } else {
                            newRow[key] = '<NEW VALUE>'
                        }
                    });

                    newRowData.push(newRow);
                    setRowData(newRowData);
                } else {
                    console.log("Insert failed: " + result.error);
                    setTableError(result.error);
                    e.api.undoCellEditing();
                    setTimeout(
                        () => setTableError(''),
                        5000
                    );
                }
            }

        } catch (error) {
            console.log("Failed to update cell data: " + error);
            setTableError(error);
            e.api.undoCellEditing();
        }
    };

    const handleFormChange = async (input, e) => {
        //e.preventDefault();
        switch (input) {
            case 'name':
                setName(e.target.value);
                break;
            case 'description':
                setDescription(e.target.value);
                break;
            case 'landOwner':
                setLandOwnerId(e.target.value);
                break;
            case 'location':
                setLocationId(e.target.value);
                break;
            case 'adminWoodLot':
                setAdminWoodLotId(e.target.value);
                break;
            case 'fon':
                setFON(e.target.value);
                break;
            case 'isAdminWoodLot':
                setIsAdminWoodLot(e.target.checked);
                break;
            case 'twoTrips':
                setTwoTrips(e.target.checked);
                break;
            case 'effDate':
                setEffDate(e.target.value);
                break;
            case 'expDate':
                setExpDate(e.target.value);
                break;
            case 'ticketText':
                setTicketText(e.target.value);
                break;
            default:
                console.log("Handle form change input not found.")
        }

    };

    let handleSubmit = async (e) => {
        e.preventDefault();

        let formData = {}, endPoint;

        if (e.target.id === 'create-woodlot-form') {
            endPoint = 'woodlots';

            formData.name = name;
            formData.description = description;
            formData.landOwnerId = landOwnerId;
            formData.locationId = locationId;
            formData.adminWoodLotId = adminWoodLotId;
            formData.lastMFSEffDate = effDate;
            formData.lastMFSExpDate = expDate;
            formData.FON = FON;
            formData.isAdminWoodLot = isAdminWoodLot;
            formData.twoTrips = twoTrips;
            formData.ticketText = ticketText;
        }

        console.log("Request Form Data: " + JSON.stringify(formData));

        let result, action = "Inserted";

        try {

            console.log("Inserting new data ...");
            closeModal();
            result = await insertData('/' + endPoint, formData);

            if (result.success) {
                console.log("Insert success: " + JSON.stringify(result.success));

                if (e.target.id === 'create-woodlot-form') {
                    handleClick(3, 'woodlots')
                }

            } else {
                console.log(action + " failed: " + JSON.stringify(result.error));
                setTableError(result.error);
                setTimeout(
                    () => setTableError(''),
                    5000
                );
            }
        } catch (error) {
            console.log(action + " failed: " + JSON.stringify(error));
            setTableError("ERROR: " + error);
            setTimeout(
                () => setTableError(''),
                5000
            );
        }

    }; // End handleSubmit();

    const onGridReady = (params) => {

        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

    };

    // Uncomment for debugging
    //console.log(JSON.stringify(rowData));
    //console.log(JSON.stringify(columnDefs));

    // Role for Data Admin Access Required
    let regex = /ADMIN|SUPER/;

    if (props.user_details.match(regex)) {
        if (!modalIsOpen) {
            return (
                <main>
                    <h1>Wood Supply Chain</h1>
                    <Grid container>
                        <Grid item xs={3}>
                            <p>Please select a table to edit.</p>
                            <ButtonGroup orientation="vertical" size="large" aria-label="outlined primary button group">
                                <Button style={{background: menuColors[0]}} onClick={(e) => handleClick(0, e)}>Land
                                    Owners</Button>
                                <Button style={{background: menuColors[1]}} onClick={(e) => handleClick(1, e)}>Land
                                    Owner
                                    Contract Groups</Button>
                                <Button style={{background: menuColors[2]}}
                                        onClick={(e) => handleClick(2, e)}>Locations</Button>
                                <Button style={{background: menuColors[3]}} onClick={(e) => handleClick(3, e)}>Wood
                                    Lots</Button>
                                <Button style={{background: menuColors[4]}} onClick={(e) => handleClick(4, e)}>Receiving
                                    Sites</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={9}>
                            <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                                <h2 className="grid-table-heading">{tableHeading}</h2>
                                <div style={{
                                    color: "red",
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    padding: "10px"
                                }}>{tableError}</div>
                                <AddIcon hidden={!menuSelected} onClick={(e) => openModal(e)}/>
                                <AgGridReact undoRedoCellEditing={true} rowData={rowData} columnDefs={columnDefs}
                                             onGridReady={onGridReady}  onCellValueChanged={handleEdit}/>
                            </div>
                        </Grid>
                    </Grid>
                </main>
            );
        } else {
            return (
                <main>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Enter Wood Lot"
                    >
                        <form onSubmit={handleSubmit} id={"create-woodlot-form"} autoComplete="off">
                            <Grid container spacing={3} direction="row" alignItems="flex-start"
                                  justify="flex-start"
                                  style={{padding: 60}}>
                                <Grid item xs={12}>
                                    <h2>Add Wood Lot</h2>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="name-text"
                                        label="Name"
                                        required="true"
                                        onChange={(e) => handleFormChange("name", e)}
                                        value={name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="description-text"
                                        label="Description"
                                        required="true"
                                        onChange={(e) => handleFormChange("description", e)}
                                        value={description}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="fon-text"
                                        label="FON"
                                        onChange={(e) => handleFormChange("fon", e)}
                                        value={FON}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel id="landowner-label">Land Owner</InputLabel>
                                    <Select labelId="landowner-label" id="select-landowner"
                                            value={landOwnerId}
                                            required="true"
                                            onChange={(e) => handleFormChange("landOwner", e)}>
                                        {landOwnersLookup.length > 0 && landOwnersLookup.map((el, index) =>
                                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel id="location-label">Location</InputLabel>
                                    <Select labelId="location-label" id="select-location"
                                            value={locationId}
                                            required="true"
                                            onChange={(e) => handleFormChange("location", e)}>
                                        {locationsLookup.length > 0 && locationsLookup.map((el, index) =>
                                            <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="eff-text"
                                        label="Last MFS Effective Date"
                                        required="true"
                                        type="date"
                                        value={effDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFormChange("effDate", e)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="exp-text"
                                        label="Last MFS Expiration Date"
                                        required="true"
                                        type="date"
                                        value={expDate}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFormChange("expDate", e)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <p>Is Admin Wood Lot?</p>
                                    <Checkbox
                                        id="woodlot-isadminwoodlot-checkbox"
                                        onChange={(e) => handleFormChange("isAdminWoodLot", e)}
                                        value={isAdminWoodLot}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel id="adminwoodlot-label">Associated Admin Wood Lot</InputLabel>
                                    <Select labelId="adminwoodlot-label" id="select-adminwoodlot"
                                            value={adminWoodLotId}
                                            onChange={(e) => handleFormChange("adminWoodLot", e)}>
                                        {adminWoodLotsLookup.length > 0 && adminWoodLotsLookup.map((el, index) =>
                                            <MenuItem key={index} value={el.id}>{el.name + ': ' + el.description}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={3}>
                                    <p>Two Trips?</p>
                                    <Checkbox
                                        id="woodlot-twotrips-checkbox"
                                        onChange={(e) => handleFormChange("twoTrips", e)}
                                        value={twoTrips}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        id="tickettext-text"
                                        label="Ticket Text"
                                        onChange={(e) => handleFormChange("ticketText", e)}
                                        value={ticketText}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained"
                                            style={{color: "green"}}>Add</Button>
                                </Grid>

                            </Grid>
                        </form>
                    </Modal>
                </main>

            );
        }

    } else {
        return (
            <main>
                <h1>Wood Supply Chain</h1>
                <p>NOTE: You do not have privilege to access this menu.</p>
            </main>
        );
    }
}