import React, {useState, useEffect} from 'react';
import WoodTicketProducts from "../WoodTicketProducts/WoodTicketProducts";
import LaborResources from "../LaborResources/LaborResources";
import WoodTicketRates from "../WoodTicketRates/WoodTicketRates";
import {
    TextField, Button, InputLabel, Select, MenuItem, Accordion,
    AccordionSummary, AccordionDetails, Typography, Checkbox, FormControlLabel
} from '@material-ui/core';

import Tooltip from '@mui/material/Tooltip'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchTicketIcon from '@mui/icons-material/ManageSearch';
import AddTicketIcon from '@mui/icons-material/AddTask';
import UpdateTicketIcon from '@mui/icons-material/Update';
import CopyTicketIcon from '@mui/icons-material/ContentCopy';
import DeleteTicketIcon from '@mui/icons-material/DeleteOutline';
import ResetFormIcon from '@mui/icons-material/RestartAlt';

import LoadingOverlay from 'react-loading-overlay';

import config from "../../config/config";

// Material-UI Components
import Grid from '@material-ui/core/Grid';

// Modal Support
import Modal from "react-modal";
import WoodTicketRate from "../WoodTicketRate/WoodTicketRate";

import moment from 'moment';
import 'moment-timezone';


async function getData(endPoint, queryString, orderBy) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-querystring': queryString,
            'x-wtg-orderby': orderBy
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function getWoodLotsData(endPoint, searchString) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token,
            'x-wtg-searchstring': searchString
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function getAllData(endPoint) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function insertData(endPoint, postData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(postData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function updateData(endPoint, putData) {

    console.log("Put Data: " + JSON.stringify(putData));
    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(putData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function deleteData(endPoint, id) {

    console.log("Delete Wood Ticket Id: " + id);
    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint + '/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function setLookups(endPoint) {
    let result, data = [];

    result = await getData('/' + endPoint, '{"inactive": "false"}', '[["name", "ASC"]]');

    if (result.success && result.success.length > 0) {

        for (const el of result.success) {
            data.push({"id": el.id, "name": el.name, "description": el.description})
        }

        if ((endPoint === "lookupgrades") || (endPoint === "lookuplengths")) {

            data.push({"id": 0, "name": "NONE"})
        }

        return (data);
    } else {
        return [];
    }

}

async function getWoodLot(id) {
    let result, data = [];

    result = await getData('/lookupwoodlots', '{"id": ' + id + '}', '[["name", "ASC"]]');

    if (result.success && result.success.length > 0) {

        for (const el of result.success) {
            data.push({
                "id": el.id,
                "name": el.name,
                "description": el.description,
                "fon": el.FON,
                "landOwner": el.LandOwner.name
            })
        }

        return (data);
    } else {
        return [];
    }

}

async function setWoodLotLookups(endPoint, searchString) {
    let result, result1, result2, data = [];

    result = await getWoodLotsData('/lookupwoodlotsfuzzysearch', searchString);

    if (result.success && result.success.length > 0) {

        for (const woodLot of result.success) {
            result1 = await getData('/lookuplocations', '{"id": "' + woodLot.locationId + '"}', '[["name", "ASC"]]');
            result2 = await getData('/lookuplandowners', '{"id": "' + woodLot.landOwnerId + '"}', '[["name", "ASC"]]');

            data.push({
                "id": woodLot.id,
                "name": woodLot.name,
                "fon": woodLot.FON,
                "description": woodLot.description,
                "location": result1.success[0].name,
                "landOwner": result2.success[0].name
            });
        }

        return (data);
    } else {
        return [];
    }
}

async function matchRates(endPoint) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}


export default function TicketManagement() {

    const [ticketId, setTicketId] = useState('');
    const [ticketDate, setTicketDate] = useState('');
    const [woodLotId, setWoodLotId] = useState('');
    const [woodLots, setWoodLots] = useState('');
    const [woodLotInfo, setWoodLotInfo] = useState('');
    const [receivingSiteId, setReceivingSiteId] = useState('');
    const [receivingSites, setReceivingSites] = useState('');
    const [tripTicket, setTripTicket] = useState('');
    const [millTicket, setMillTicket] = useState('');
    const [clsTripTicket, setClsTripTicket] = useState('');
    const [clsContract, setClsContract] = useState('');
    const [woodLotsSearch, setWoodLotsSearch] = useState('');
    const [actionStatus, setActionStatus] = useState('');
    const [actionStatusStyle, setActionStatusStyle] = useState("green");
    const [laborResources, setLaborResources] = useState('');
    const [resourceLineItems, setResourceLineItems] = useState([]);
    const [laborChanged, setLaborChanged] = useState(false);

    const [products, setProducts] = useState('');
    const [productLineItems, setProductLineItems] = useState([]);
    const [productChanged, setProductChanged] = useState(false);
    const [productId, setProductId] = useState('');
    const [convertedNet, setConvertedNet] = useState(0);
    const [asyncOpRunning, setAsyncOpRunning] = useState(false);

    // Copy Modal
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Copy Modal Form data
    const [copyTicketDate, setCopyTicketDate] = useState('');
    const [copyTripTicket, setCopyTripTicket] = useState('');
    const [copyMillTicket, setCopyMillTicket] = useState('');
    const [copyGross, setCopyGross] = useState('');
    const [copyClsTripTicket, setCopyClsTripTicket] = useState('');
    const [copyClsContract, setCopyClsContract] = useState('');

    // Find Modal data
    const [findMoreThanOneSelected, setFindMoreThanOneSelected] = useState('');
    const [findMoreThanOneData, setFindMoreThanOneData] = useState([]);

    useEffect(async () => {

        setReceivingSites(await setLookups("lookupreceivingsites"));

    }, []);

    const handleChange = async (input, e) => {
        //e.preventDefault();

        switch (input) {
            case 'searchwoodlots':
                setWoodLotsSearch(e.target.value);
                if (e.target.value.length >= 4) {
                    console.log("searchwoodlots value: " + e.target.value);

                    let wlots = await setWoodLotLookups("lookupwoodlots", e.target.value);

                    console.log(" Wood Lots: " + JSON.stringify(wlots));

                    setWoodLots(wlots);

                    if (wlots.length >= 1) {
                        setWoodLotInfo(wlots.length + " Wood Lots found");
                    } else {
                        setWoodLotInfo('');
                    }
                }
                break;
            case 'woodlot':
                setWoodLotId(e.target.value);
                break;
            case 'receivingsite':
                setReceivingSiteId(e.target.value);
                break;
            case 'ticketdate':
                setTicketDate(e.target.value);
                break;
            case 'tripticket':
                setTripTicket(e.target.value);
                break;
            case 'millticket':
                setMillTicket(e.target.value);
                break;
            case 'clstripticket':
                setClsTripTicket(e.target.value);
                break;
            case 'clscontract':
                setClsContract(e.target.value);
                break;
            default:
                console.log("Handle change input not found.")
        }

    };

    const handleFormChange = async (input, e) => {
        //e.preventDefault();
        switch (input) {
            case 'copyTicketDate':
                setCopyTicketDate(e.target.value);
                break;
            case 'copyTripTicket':
                setCopyTripTicket(e.target.value);
                break;
            case 'copyMillTicket':
                setCopyMillTicket(e.target.value);
                break;
            case 'copyGross':
                setCopyGross(e.target.value);
                break;
            case 'copyClsTripTicket':
                setCopyClsTripTicket(e.target.value);
                break;
            case 'copyClsContract':
                setCopyClsContract(e.target.value);
                break;
            default:
                console.log("Handle form change input not found.")
        }
    };

    const handleReset = () => {
        document.getElementById("create-woodticket-form").reset();
        setTicketId('');
        setWoodLotInfo('');
        setWoodLotId('');
        setWoodLots('');
        setReceivingSiteId('');
        setActionStatus('');
        setTripTicket('');
        setMillTicket('');
        setClsTripTicket('');
        setClsContract('');
        setTicketDate('');
        setWoodLotsSearch('');
        setLaborResources('');
        setResourceLineItems([]);
        setLaborChanged(false);
        setProducts('');
        setProductLineItems([]);
        setProductChanged(false);
        setProductId('');
    };

    // Looking for a particular Trip Ticket
    const handleFind = async (e, searchValue, searchType) => {

        setLaborChanged(false);
        setProductChanged(false);
        setProductId('');

        try {
            setTicketId('');
            setAsyncOpRunning(true);
            let result;

            if (searchType === "MILL") {
                result = await getData('/lookupwoodtickets', '{"millTicket":"' + searchValue + '"}', '[["ticketDate", "DESC"]]');
            } else if (searchType === "CLS") {
                result = await getData('/lookupwoodtickets', '{"clsTripTicket":"' + searchValue + '"}', '[["ticketDate", "DESC"]]');
            } else if (searchType === "TICKETID") {
                result = await getData('/lookupwoodtickets', '{"id":"' + searchValue + '"}', '[["ticketDate", "DESC"]]');
            } else {
                result = await getData('/lookupwoodtickets', '{"tripTicket":"' + searchValue + '"}', '[["ticketDate", "DESC"]]');
            }

            setAsyncOpRunning(false);

            if (result.success) {
                if (result.success.length > 0) {

                    if (result.success.length > 1) {
                        let ticketArray = [];
                        for (const ticket of result.success) {
                            ticketArray.push({
                                id: ticket.id,
                                ticketDate: ticket.ticketDate,
                                tripTicket: ticket.tripTicket,
                                millTicket: ticket.millTicket,
                                clsTicket: ticket.clsTripTicket
                            });
                        }

                        console.log("Search result has more than 1 result: " + JSON.stringify(ticketArray));
                        setFindMoreThanOneData(JSON.parse(JSON.stringify(ticketArray)));
                        setModalIsOpen(true);
                    }

                    let woodTicketLaborResources = result.success[0].WoodTicketLaborResource;
                    let woodTicketProducts = result.success[0].WoodTicketProducts;

                    //console.log("Labor Resources: " + JSON.stringify(woodTicketLaborResources));
                    if (woodTicketLaborResources && woodTicketLaborResources.length > 0) {
                        setLaborResources(woodTicketLaborResources);
                    }

                    console.log("Products: " + JSON.stringify(woodTicketProducts));
                    if (woodTicketProducts && woodTicketProducts.length > 0) {
                        setProducts(woodTicketProducts);
                        setProductIdSelected(woodTicketProducts[0].id);
                    }

                    setTicketId(result.success[0].id);

                    //console.log(result.success[0].ticketDate);
                    if (searchType === "MILL") {
                        setActionStatus("Found Mill Ticket: " + millTicket);
                    } else if (searchType === "CLS") {
                        setActionStatus("Found CLS Ticket: " + clsTripTicket);
                    } else {
                        setActionStatus("Found Trip Ticket: " + tripTicket);
                    }

                    setActionStatusStyle("green");

                    let wlots = await getWoodLot(result.success[0].woodLotId);


                    setWoodLots(wlots);
                    setWoodLotInfo(wlots[0].name + " (" + wlots[0].description + ")");

                    setTicketDate(new Date(result.success[0].ticketDate).toISOString().substring(0, 10));
                    setCopyTicketDate(new Date(result.success[0].ticketDate).toISOString().substring(0, 10));

                    setWoodLotId(result.success[0].woodLotId);
                    setReceivingSiteId(result.success[0].receivingSiteId);
                    setTripTicket(result.success[0].tripTicket);
                    setMillTicket(result.success[0].millTicket);
                    setClsTripTicket(result.success[0].clsTripTicket);
                    setClsContract(result.success[0].clsContract);

                    setActionStatus('');

                } else {
                    if (searchType === "MILL") {
                        setActionStatus("Mill Ticket NOT FOUND: " + searchValue);
                    } else if (searchType === "CLS") {
                        setActionStatus("CLS Ticket NOT FOUND: " + searchValue);
                    } else {
                        setActionStatus("Trip Ticket NOT FOUND: " + searchValue);
                    }

                    setActionStatusStyle("red");
                    setLaborResources('');
                    setProducts('');
                    setTimeout(
                        () => {
                            handleReset();
                            setActionStatus('');
                            setTicketId('');
                        },
                        1000
                    );
                }
            } else {
                setActionStatus("Ticket query Error: " + JSON.stringify(result.error));
                setActionStatusStyle("red");
                setLaborResources('');
                setProducts('');
                setTimeout(
                    () => {
                        setActionStatus('');
                        setTicketId('');
                    },
                    5000
                );
            }
        } catch (error) {
            setActionStatus("Ticket query Error: " + error);
            setActionStatusStyle("red");
            setLaborResources('');
            setProducts('');
            setTimeout(
                () => {
                    setActionStatus('');
                    setTicketId('');
                },
                5000
            );
        }
    };


    const handleDelete = async (e) => {

        try {

            let result;

            if (ticketId) {
                console.log("Wood Ticket Id to be deleted: " + ticketId);

                setAsyncOpRunning(true);

                result = await deleteData('/woodtickets', ticketId);

                setAsyncOpRunning(false);

                if (result.success) {

                    console.log("Deleted Wood Ticket Id. " + ticketId);
                    setActionStatus("Deleted Wood Ticket Id: " + ticketId);
                    setActionStatusStyle("green");
                    setLaborResources('');
                    setProducts('');
                    setTimeout(
                        () => handleReset(),
                        1000
                    );
                } else {
                    console.log("Delete was not successful. " + result.error);
                    setActionStatus("Unable to Delete Wood Ticket: " + result.error);
                    setActionStatusStyle("red");
                    setLaborResources('');
                    setProducts('');
                    setTimeout(
                        () => setActionStatus(''),
                        5000
                    );
                }

            } else {
                console.log("Wood Ticket Id is not set.");
                setActionStatus("ERROR: Wood Ticket Id is not set.");
                setActionStatusStyle("red");
                setLaborResources('');
                setProducts('');
                setTimeout(
                    () => setActionStatus(''),
                    5000
                );
            }

        } catch (error) {
            console.log("Delete was not successful. " + error);
            setActionStatus("Unable to Delete Wood Ticket: " + error);
            setActionStatusStyle("red");
            setLaborResources('');
            setProducts('');
            setTimeout(
                () => setActionStatus(''),
                5000
            );
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formData = {};
        formData.ticketDate = ticketDate;
        formData.woodLotId = woodLotId;
        formData.receivingSiteId = receivingSiteId;

        formData.laborResources = resourceLineItems;
        formData.products = productLineItems;
        formData.laborChanged = laborChanged;
        formData.productChanged = productChanged;

        formData.tripTicket = tripTicket;
        formData.millTicket = millTicket;

        formData.clsTripTicket = clsTripTicket;
        formData.clsContract = clsContract;

        //console.log("Request Form Data: " + JSON.stringify(formData));
        //console.log("Ticket Id: " + ticketId);


        let result, action, operation, woodContractRate, stumpageRate, laborRates = [], interCompanyRates = [];

        try {

            setAsyncOpRunning(true);

            if (e.target.id === 'copy-ticket-form') {
                action = "Copied";
                operation = "copy";
                console.log("Copying existing data ...");
                result = await insertData('/copywoodticket',
                    {
                        "woodTicketId": ticketId,
                        "ticketDate": copyTicketDate,
                        "tripTicket": copyTripTicket,
                        "millTicket": copyMillTicket,
                        "gross": copyGross,
                        "clsTripTicket": copyClsTripTicket,
                        "clsContract": copyClsContract

                    }
                );
                closeModal();

            } else if (ticketId) {
                action = "Updated";
                operation = "update";
                console.log("Updating existing data ...");
                formData.id = ticketId;
                result = await updateData('/woodtickets', formData);
            } else {
                action = "Inserted";
                operation = "insert";
                console.log("Inserting new data ...");
                result = await insertData('/woodtickets', formData);
            }

            setAsyncOpRunning(false);

            if (result.success) {
                console.log("Insert success: " + JSON.stringify(result.success));
                setActionStatus(action + " data, id: " + result.success.id);
                setActionStatusStyle("green");
                setLaborResources('');
                setProducts('');

                if (operation === "insert" || operation === "update" || operation === "copy") {

                    // Loop through Wood Ticket Products and match rates
                    let result2 = await getData('/lookupwoodtickets', '{"id":"' +
                        result.success.id + '"}', '[["ticketDate", "DESC"]]');

                    for (const woodTicketProduct of result2.success[0].WoodTicketProducts) {

                        let newTicketRate = {}, newTicketRates = [];

                        woodContractRate = await matchRates('/matchWoodContractRates?woodTicketProductId=' + woodTicketProduct.id);

                        if (woodContractRate && woodContractRate.success && woodContractRate.success.rateId > 0) {
                            newTicketRate.woodTicketId = result.success.id;
                            newTicketRate.woodTicketProductId = woodTicketProduct.id
                            newTicketRate.rateTypeId = 1;
                            newTicketRate.unitPrice = woodContractRate.success.unitPrice;
                            newTicketRate.unitId = woodContractRate.success.unitId;
                            newTicketRate.totalPrice = woodContractRate.success.totalPrice;
                            newTicketRate.rateId = woodContractRate.success.rateId;

                            newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));
                        }


                        stumpageRate = await matchRates('/matchStumpageRates?woodTicketProductId=' + woodTicketProduct.id);

                        if (stumpageRate && stumpageRate.success && stumpageRate.success.rateId > 0) {
                            newTicketRate = {};

                            newTicketRate.woodTicketId = result.success.id;
                            newTicketRate.woodTicketProductId = woodTicketProduct.id
                            newTicketRate.rateTypeId = 2;
                            newTicketRate.unitPrice = stumpageRate.success.unitPrice;
                            newTicketRate.unitId = stumpageRate.success.unitId;
                            newTicketRate.totalPrice = stumpageRate.success.totalPrice;
                            newTicketRate.rateId = stumpageRate.success.rateId;

                            newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));
                        }

                        // Labor Resources
                        for (const laborResource of result2.success[0].WoodTicketLaborResource) {
                            let rateMatch = await matchRates('/matchLaborRates?woodTicketProductId=' +
                                woodTicketProduct.id + '&laborResourceId=' + laborResource.id);

                            if (rateMatch && rateMatch.success && rateMatch.success.rateId > 0) {
                                newTicketRate = {};
                                newTicketRate.woodTicketId = result.success.id;
                                newTicketRate.woodTicketProductId = woodTicketProduct.id;
                                newTicketRate.rateTypeId = 3;
                                newTicketRate.unitPrice = rateMatch.success.unitPrice;
                                newTicketRate.unitId = rateMatch.success.unitId;
                                newTicketRate.totalPrice = rateMatch.success.totalPrice;
                                newTicketRate.rateId = rateMatch.success.rateId;
                                newTicketRate.laborResourceId = laborResource.id;

                                newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));
                            }
                        }

                        // Internal company rates
                        let companies = await getData('/lookupcompanies',
                            '{"companyTypeId": "1"}', '[["id", "ASC"]]');

                        for (const company of companies.success) {
                            let rateMatch = await matchRates('/matchInterCompanyRates?woodTicketProductId=' +
                                woodTicketProduct.id + '&companyId=' + company.id);

                            if (rateMatch && rateMatch.success && rateMatch.success.rateId > 0) {
                                newTicketRate = {};
                                newTicketRate.woodTicketId = result.success.id;
                                newTicketRate.woodTicketProductId = woodTicketProduct.id;
                                newTicketRate.rateTypeId = 4;
                                newTicketRate.unitPrice = rateMatch.success.unitPrice;
                                newTicketRate.unitId = rateMatch.success.unitId;
                                newTicketRate.totalPrice = rateMatch.success.totalPrice;
                                newTicketRate.rateId = rateMatch.success.rateId;
                                newTicketRate.companyId = company.id;

                                newTicketRates.push(JSON.parse(JSON.stringify(newTicketRate)));
                            }
                        }

                        // Save Rates
                        if (newTicketRates.length > 0) {
                            await updateData('/woodticketrates', newTicketRates)
                        }
                    }
                }

                setTimeout(
                    () => {
                        setActionStatus('');
                        handleFind(e, result.success.id, "TICKETID");
                        //handleReset();
                    },
                    1000
                );

            } else {
                closeModal();
                console.log(action + " failed: " + JSON.stringify(result.error));
                setActionStatus(JSON.stringify(result.error));
                setActionStatusStyle("red");
                setLaborResources('');
                setTimeout(
                    () => setActionStatus(''),
                    5000
                );
            }
        } catch (error) {
            closeModal();
            console.log(action + " failed: " + JSON.stringify(error));
            setActionStatus("ERROR: " + error);
            setActionStatusStyle("red");
            setLaborResources('');
            setTimeout(
                () => setActionStatus(''),
                5000
            );
        }
    };

    const handleLaborChange = (e) => {
        console.log(("e: " + JSON.stringify(e)));
        setResourceLineItems([...e]);
    }

    const laborHasChanged = (e) => {
        setLaborChanged(true);
    }

    const handleProductChange = (e) => {
        console.log(("Product change e: " + JSON.stringify(e)));
        setProductLineItems([...e]);
    };

    const setProductIdSelected = async (e) => {
        setProductId(e);

        let woodTicketProducts = await getData('/lookupwoodticketproducts',
            '{"id": ' + e + '}', '[["id", "ASC"]]');

        let woodTicketProduct = woodTicketProducts.success[0];

        setConvertedNet(woodTicketProduct.convertedNet);

    };

    const getProductIdSelected = (e) => {
        return productId;
    };

    const productHasChanged = (e) => {
        setProductChanged(true);
    }

    const getTicketId = (e) => {
        return ticketId;
    }

    const getLaborResources = (e) => {
        return laborResources;
    }

    const getConvertedNet = (e) => {
        return convertedNet;
    };

    const openModal = (e) => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCopyTripTicket('');
        setCopyMillTicket('');
        setCopyGross('');
        setCopyClsTripTicket('');
        setCopyClsContract('');
        setFindMoreThanOneData([]);
    };

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    };

    const customStyles = {
        content: {
            position: 'absolute',
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '10%',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '5%'
        },
    };


    if (!modalIsOpen) {
        return (
            <main>
                <h1>Ticket Management</h1>
                <LoadingOverlay
                    active={asyncOpRunning}
                    spinner
                    text="Loading ...."
                >
                    <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-end">
                        <Grid item xs={12}>
                            <Grid container spacing={3} direction="row" alignItems="center" justify="flex-start"
                                  style={{borderStyle: "none"}}>
                                <Grid item xs={12}>
                                    <Accordion hidden={!productId} defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <h5>Wood Ticket Rates ({productId})</h5>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <WoodTicketRates key={ticketId} getTicketId={(e) => getTicketId(e)}
                                                             getLaborResources={(e) => getLaborResources(e)}
                                                             getConvertedNet={(e) => getConvertedNet(e)}
                                                             getProductIdSelected={(e) => getProductIdSelected(e)}/>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{color: actionStatusStyle}}>{actionStatus}</p>
                        </Grid>
                    </Grid>
                    <form onSubmit={handleSubmit} id={"create-woodticket-form"} autoComplete="off">
                        <Grid container spacing={3} direction="row" alignItems="flex-start" justify="flex-start"
                              style={{padding: 60}}>
                            <Grid item xs={12}>
                                <Grid container spacing={3} direction="row" alignItems="flex-start"
                                      justify="flex-start">
                                    <Grid item xs={12}>
                                        <Grid container spacing={3} direction="row" alignItems="flex-start"
                                              justify="flex-start">
                                            <Grid item xs={1} hidden={ticketId}>
                                                <Button type="submit" variant="text" style={{color: "black"}}
                                                        disabled={ticketId}>
                                                    <Tooltip title="Add Ticket" placement="top-start">
                                                        <AddTicketIcon/>
                                                    </Tooltip>
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} hidden={!ticketId}>
                                                <Tooltip title="Update Ticket" placement="top-start">
                                                    <UpdateTicketIcon onClick={handleSubmit}/>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={1} hidden={!ticketId}>
                                                <Tooltip title="Copy Ticket" placement="top-start">
                                                    <CopyTicketIcon onClick={(e) => openModal(e)}/>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={1} hidden={!ticketId}>
                                                <Tooltip title="Delete Ticket" placement="top-start">
                                                    <DeleteTicketIcon onClick={handleDelete}/>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip title="Reset Form" placement="top-start">
                                                    <ResetFormIcon onClick={handleReset}/>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="ticket-date-text"
                                            label="Ticket Date"
                                            type="date"
                                            value={ticketDate}
                                            required="true"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => handleChange("ticketdate", e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3} direction="row" alignItems="flex-start"
                                              justify="flex-start">
                                            <Grid item xs={3}>
                                                <TextField
                                                    id="fon-search-text"
                                                    label="Land Owner/FON/Wood Lot Search"
                                                    variant="outlined"
                                                    helperText={woodLotInfo}
                                                    value={woodLotsSearch}
                                                    onChange={(e) => handleChange("searchwoodlots", e)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <InputLabel id="woodlot-label">Wood Lot</InputLabel>
                                                <Select labelId="woodlot-label" required="true" id="select-woodlot"
                                                        value={woodLotId}
                                                        onChange={(e) => handleChange("woodlot", e)}>
                                                    {woodLots.length > 0 && woodLots.map((el, index) =>
                                                        <MenuItem key={index}
                                                                  value={el.id}>[{el.landOwner}]: {el.name} ({el.description})
                                                            FON: {el.fon}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <InputLabel id="receivingsite-label">Receiving Site</InputLabel>
                                                <Select labelId="receivingsite-label" required="true"
                                                        id="select-receivingsite"
                                                        value={receivingSiteId}
                                                        onChange={(e) => handleChange("receivingsite", e)}>
                                                    {receivingSites.length > 0 && receivingSites.map((el, index) =>
                                                        <MenuItem key={index} value={el.id}>{el.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12}>
                                <Grid container spacing={3} direction="row" alignItems="flex-start"
                                      justify="flex-start">
                                    <Grid item xs={3}>
                                        <TextField
                                            id="millticket-number"
                                            label="Mill Ticket"
                                            variant="outlined"
                                            helperText="Required"
                                            required="true"
                                            value={millTicket}
                                            inputProps={{minLength: 5}}
                                            onChange={(e) => handleChange("millticket", e)}
                                        />
                                        <Tooltip title="Find Mill Ticket" placement="top-start">
                                            <SearchTicketIcon onClick={(e) => handleFind(e, millTicket, "MILL")}/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="tripticket-number"
                                            label="Trip Ticket"
                                            variant="outlined"
                                            helperText="Required"
                                            onChange={(e) => handleChange("tripticket", e)}
                                            required="true"
                                            value={tripTicket}
                                        />
                                        <Tooltip title="Find Trip Ticket" placement="top-start">
                                            <SearchTicketIcon onClick={(e) => handleFind(e, tripTicket, "TRIP")}/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="clsticket-number"
                                            label="CLS Trip Ticket"
                                            variant="outlined"
                                            helperText="Optional: Only for CLS"
                                            onChange={(e) => handleChange("clstripticket", e)}
                                            value={clsTripTicket}
                                        />
                                        <Tooltip title="Find CLS Ticket" placement="top-start">
                                            <SearchTicketIcon onClick={(e) => handleFind(e, clsTripTicket, "CLS")}/>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            id="clscontract-number"
                                            label="CLS Contract Number"
                                            variant="outlined"
                                            helperText="Optional: Only for CLS"
                                            onChange={(e) => handleChange("clscontract", e)}
                                            value={clsContract}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} direction="row" alignItems="flex-start"
                                      justify="flex-start">
                                    <Grid item xs={12}>
                                        <WoodTicketProducts key={ticketId} products={products}
                                                            handleProductChange={(e) => handleProductChange(e)}
                                                            getTicketId={(e) => getTicketId(e)}
                                                            productHasChanged={(e) => productHasChanged(e)}
                                                            setProductIdSelected={(e) => setProductIdSelected(e)}
                                                            woodLotId={woodLotId} receivingSiteId={receivingSiteId}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3} direction="row" alignItems="flex-start"
                                      justify="flex-start">
                                    <Grid item xs={12}>
                                        <LaborResources key={ticketId} laborResources={laborResources}
                                                        handleLaborChange={(e) => handleLaborChange(e)}
                                                        laborHasChanged={(e) => laborHasChanged(e)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </LoadingOverlay>

            </main>
        );
    } else {

        if (findMoreThanOneData.length === 0) {
            return (
                <main>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Enter Ticket Copy Info"
                    >
                        <form onSubmit={handleSubmit} id={"copy-ticket-form"} autoComplete="off">
                            <Grid container spacing={3} direction="row" alignItems="flex-start"
                                  justify="flex-start"
                                  style={{padding: 60}}>
                                <Grid item xs={12}>
                                    <h2>Copy Ticket</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="copyticket-date-text"
                                        label="NEW Ticket Date"
                                        type="date"
                                        value={copyTicketDate}
                                        required="true"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleFormChange("copyTicketDate", e)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="copymillicket-text"
                                        label="NEW Mill Ticket"
                                        required="true"
                                        onChange={(e) => handleFormChange("copyMillTicket", e)}
                                        value={copyMillTicket}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="copytripticket-text"
                                        label="NEW Trip Ticket"
                                        required="true"
                                        onChange={(e) => handleFormChange("copyTripTicket", e)}
                                        value={copyTripTicket}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="copygross-text"
                                        label="NEW Gross Weight"
                                        required="true"
                                        type="number"
                                        onChange={(e) => handleFormChange("copyGross", e)}
                                        value={copyGross}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="copyclstripticket-text"
                                        label="NEW CLS Trip Ticket"
                                        required={false}
                                        onChange={(e) => handleFormChange("copyClsTripTicket", e)}
                                        value={copyClsTripTicket}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="copyclscontract-text"
                                        label="NEW CLS Contract"
                                        required={false}
                                        onChange={(e) => handleFormChange("copyClsContract", e)}
                                        value={copyClsContract}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained"
                                            style={{color: "green"}}>Copy Ticket</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Modal>
                </main>
            );
        } else {
            return (
                <main>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Choose Ticket"
                    >
                        <form id={"choose-ticket-form"} autoComplete="off">
                            <Grid container spacing={3} direction="row" alignItems="flex-start"
                                  justify="flex-start"
                                  style={{padding: 60}}>
                                <Grid item xs={12}>
                                    <h2>Multiple Tickets Found</h2>
                                    <h6>Select a Ticket</h6>
                                </Grid>
                                <Grid item xs={12}>
                                    <table width="100%">
                                        <th><u>Ticket Id</u></th>
                                        <th><u>Ticket Date</u></th>
                                        <th><u>Mill Ticket</u></th>
                                        <th><u>Trip Ticket</u></th>
                                        <th><u>CLS Ticket</u></th>

                                        {findMoreThanOneData.length > 0 && findMoreThanOneData.map((el, index) =>
                                            <tr key={index} onClick={(e) => handleFind(e, el.id, "TICKETID") && closeModal()}>
                                                <td>{el.id}</td>
                                                <td>{moment(el.ticketDate).format('MM/DD/YYYY')}</td>
                                                <td>{el.millTicket}</td>
                                                <td>{el.tripTicket}</td>
                                                <td>{el.clsTicket}</td>
                                            </tr>
                                        )}
                                    </table>
                                </Grid>
                            </Grid>
                        </form>
                    </Modal>
                </main>
            );

        }
    }
}