import React, {useState} from 'react';

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Button, TextField, Checkbox} from "@material-ui/core";
import config from "../../config/config";

async function updateData(endPoint, putData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(putData)
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })

} // End updateData()


export default function CompleteTicket(props) {

    const [tripTicket, setTripTicket] = useState('');
    const [millTicket, setMillTicket] = useState('');
    const [netWeight, setNetWeight] = useState('');
    const [updateResults, setUpdateResults] = useState('');

    const handleChange = async (data, e) => {

        if (data === 'tripticket') {
            setTripTicket(e.target.value);
        } else if (data === 'millticket') {
            setMillTicket(e.target.value);
        } else {
            setNetWeight(e.target.value);
        }
    };

    const handleUpdate = async (e) => {

        e.preventDefault();

        let putData, results;

        putData = {
            tripTicket: tripTicket,
            millTicket: millTicket,
            netWeight: netWeight
        };

        results = await updateData('/completeWoodTicket', putData);
        console.log("Update Results: " + JSON.stringify(results));

        setUpdateResults(results);

        setTimeout(
            () => {
                setTripTicket('')
                setMillTicket('')
                setNetWeight('')
                setUpdateResults('')
            },
            5000
        );

    };

    if (!updateResults || updateResults.length === 0) {
        return (
            <main>
                <h2>Complete Ticket</h2>
                <form onSubmit={handleUpdate} id={"update-ticket-form"} autoComplete="off">
                    <Grid container spacing={3} direction="row" alignItems="center" justify="left">
                        <Grid item xs={3}>
                            <br/><br/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="import-tripticket-text"
                                label="Trip Ticket"
                                type="text"
                                value={tripTicket}
                                required="true"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handleChange("tripticket", e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="import-millticket-text"
                                label="Mill Ticket"
                                type="text"
                                value={millTicket}
                                required="true"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handleChange("millticket", e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="import-netweight-text"
                                label="Net Weight"
                                type="number"
                                value={netWeight}
                                required="true"
                                helperText="Unit: lbs"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => handleChange("netweight", e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={9}>
                            <Button type="submit">Complete Ticket</Button>
                        </Grid>

                    </Grid>
                </form>
            </main>
        );

    } else {
        if (updateResults.success) {
            return (
                <main>
                    <h2>Complete Ticket</h2>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                        <Grid item xs={3}>
                            <h4>Complete Tickets Results:</h4>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12}>
                                <br/><br/><br/>
                                <p style={{color: "green"}}>{updateResults.success}</p>
                                <br/>
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
            );
        } else {
            return (
                <main>
                    <h2>Complete Ticket</h2>
                    <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                        <Grid item xs={3}>
                            <h4>Complete Ticket ERROR:</h4>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12}>
                                <br/><br/><br/>
                                <p style={{color: "red"}}>{updateResults.error}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
            );
        }
    }
}
