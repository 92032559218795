import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Header from './components/Header/Header';
import Login from "./components/Login/Login";
import Layout from "./components/Layout/Layout";
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class App extends Component {

    state = {
        token: sessionStorage.getItem("wacct_token")
    };

    _check_token = token => {

        let buff = new Buffer(token.toString(), 'base64');
        let decoded_token = buff.toString('ascii');
        const regex = /.*@wtg\.local,.*/;
        if (!decoded_token.match(regex)) {
            sessionStorage.clear();
            return false;
        } else {
            return true;
        }
    }

    _decode_token = token => {
        let buff = new Buffer(token.toString(), 'base64');
        return buff.toString('ascii');
    }


    render() {

        let decoded_token;
        if (this.state.token) {
            decoded_token = this._decode_token(this.state.token);
        }

        if (!this.state.token || !this._check_token(this.state.token)) {
            return (
                <Router>
                    <div>
                        <div className="auth-wrapper">
                            <div className="auth-inner">
                                <Switch>
                                    <Route path="/">
                                        <Login/>
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </Router>
            )
        } else {
            return (
                <Router>
                    <Header user_details={decoded_token}/>
                        <Switch>
                            <Route path="/">
                                <Layout user_details={decoded_token} />
                            </Route>
                        </Switch>
                </Router>
            );
        }
    }
}

export default App;