import React, { useState } from 'react';
import config from "../../config/config.json";
import './ag-grid.css';

// Material-UI Components
import {ButtonGroup, Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

// AG Grid Components
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

async function getAllData(endPoint) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        }
    })
        .then(data => data.json())
        .catch( error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function insertData(endPoint, postData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(postData)
    })
        .then(data => data.json())
        .catch( error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

async function updateData(endPoint, putData) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        },
        body: JSON.stringify(putData)
    })
        .then(data => data.json())
        .catch( error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}


export default function DataAdministration(props) {

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [tableHeading, setTableHeading] = useState('');
    const [tableError, setTableError] = useState('');
    const [menuColors, setMenuColors] = useState(['white', 'white', 'white', 'white']);


    let handleClick = async (menuIndex, e) => {
       try {

           let colors = ["white", "white", "white", "white"];
           colors[menuIndex] = "#90ee90";

           setMenuColors(colors);

           let result;
           let columnDefs = [];
           let endPoint = e.target.innerText.toLowerCase().replace(/\s/g, '');


           result = await getAllData('/' + endPoint);

           if (result.error) {
               console.dir(e);
               setRowData([{"error": "Unable to fetch data from table! " + result.error}]);
               columnDefs.push({field: "error"});
               setColumnDefs(columnDefs);
           } else {

               // Special case for Species table
               if (endPoint === "species") {
                   result["success"].forEach((el) => {
                       el.speciesType = el.SpecieType.name;
                       if (el.SpecieType) {
                           delete el.SpecieType;
                       }
                   });
               }

               result.success.push({...result.success[0]});

               const keys = Object.keys(result.success[0]);

               keys.forEach((key, index) => {
                   if (key === 'id') {
                       result.success[result.success.length - 1][key] = null;
                   } else if (key === 'inactive') {
                       result.success[result.success.length - 1][key] = false;
                   } else {
                       result.success[result.success.length - 1][key] = '<NEW VALUE>'
                   }
               });


               setRowData(result.success);

               let columnFields = Object.keys(result.success[0]);
               for (let col in columnFields) {

                   if ((columnFields[col] !== "createdAt") && (columnFields[col] !== "updatedAt")) {
                       if (columnFields[col] === 'id') {
                           columnDefs.push({field: columnFields[col], sortable: true, flex: "initial", pinned: "left", maxWidth: 100})
                       } else if (columnFields[col] === 'inactive') {
                           columnDefs.push({field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial", pinned: "right", maxWidth: 100,
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {values: ['false', 'true']}})
                       } else if (columnFields[col] === 'speciesType') {
                           columnDefs.push({field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial",
                               cellEditor: 'agSelectCellEditor',
                               cellEditorParams: {values: ['Hardwood', 'Softwood']}})
                       } else {
                           columnDefs.push({field: columnFields[col], filter: true, editable: true, sortable: true, flex: "initial"})
                       }
                   }
               }

               setColumnDefs(columnDefs);
               setTableHeading(e.target.innerText.toUpperCase());

           }
       } catch(error) {
           console.log("ERROR: Unable to fetch data from table! " + error)
           setRowData([{"error": "Unable to fetch data from table! " + error}]);
           setColumnDefs([{field: "error"}]);
       }
    };

    let handleEdit = async e => {
        try {

            // Update updated at
            console.dir(e);
            e.data.updatedAt = Date.now();

            console.log("Cell data to be changed: " + JSON.stringify(e.data));

            // Important Logic.  If any of the values have the string <NEW VALUE> then we do not want to POST until all values are updated,
            // that have <NEW VALUE>

            const keys = Object.keys(e.data);
            let regex = /<NEW VALUE>/;

            let transactRow = true;
            keys.forEach((key, index) => {
                if (key === 'id') {
                    return;
                } else if (key === 'inactive') {
                    return;
                } else if (key === 'createdAt') {
                   return;
                } else {
                    if (e.data[key]) {
                        if (e.data[key].toString().match(regex)) {
                            transactRow = false;
                        }
                    }
                }
            });

            if(! transactRow) {
                console.log('Found NEW DATA. Will not transact.')
                return;
            }

            let result;
            let endPoint = tableHeading.toLowerCase().replace(/\s/g, '');

            if (e.data.id) {
                result = await updateData('/' + endPoint, e.data);
                if (result.error) {
                    console.log("Update failed: " + result.error);
                    setTableError(result.error);
                    e.api.undoCellEditing();
                    setTimeout(
                        () => setTableError(''),
                        5000
                    );
                }
            } else {
                e.data.createdAt = Date.now();
                e.data.updatedAt = e.data.createdAt;
                result = await insertData('/' + endPoint, e.data);

                if (result.success) {

                    let refreshResult = await getAllData('/' + endPoint);

                    // Special case for Species table
                    if (tableHeading.toLowerCase() === "species") {
                        refreshResult["success"].forEach((el) => {
                            el.speciesType = el.SpecieType.name;
                            if (el.SpecieType) {
                                delete el.SpecieType;
                            }
                        });
                    }

                    let newRow = {...refreshResult.success[0]};
                    let newRowData = [...refreshResult.success];

                    const keys = Object.keys(newRow);

                    keys.forEach((key, index) => {
                        if (key === 'id') {
                            newRow[key] = null;
                        } else if (key === 'inactive') {
                            newRow[key] = false;
                        } else {
                            newRow[key] = '<NEW VALUE>'
                        }
                    });

                    newRowData.push(newRow);
                    setRowData(newRowData);
                } else {
                        console.log("Insert failed: " + result.error);
                        setTableError(result.error);
                        e.api.undoCellEditing();
                        setTimeout(
                         () => setTableError(''),
                         5000
                        );
                }
            }

        } catch (error) {
            console.log("Failed to update cell data: " + error);
            setTableError(error);
            e.api.undoCellEditing();
        }
    }

    // Uncomment for debugging
    //console.log(JSON.stringify(rowData));
    //console.log(JSON.stringify(columnDefs));

    // Role for Data Admin Access Required
    let regex = /ADMIN|SUPER/;

    if (props.user_details.match(regex)) {
        return (
            <main>
                <h1>Data Administration</h1>
                <Grid container>
                    <Grid item xs={3}>
                        <p>Please select a table to edit.</p>
                        <ButtonGroup orientation="vertical" size="large"  aria-label="outlined primary button group">
                            <Button style={{background: menuColors[0]}} onClick={(e) => handleClick(0, e)}>Products</Button>
                            <Button style={{background: menuColors[1]}} onClick={(e) => handleClick(1, e)}>Species</Button>
                            <Button style={{background: menuColors[2]}} onClick={(e) => handleClick(2, e)}>Grades</Button>
                            <Button style={{background: menuColors[3]}} onClick={(e) => handleClick(3, e)}>Lengths</Button>
                        </ButtonGroup>
                        <br/><br/>
                        <ButtonGroup orientation="vertical" size="large"  aria-label="outlined primary button group">
                            <Button style={{background: menuColors[4]}} onClick={(e) => handleClick(4, e)}>Customers</Button>
                            <Button style={{background: menuColors[5]}} onClick={(e) => handleClick(5, e)}>GL Accounts</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={9}>
                        <div className="ag-theme-alpine" style={{height: "70vh", width: "60vw"}}>
                            <h2 className="grid-table-heading">{tableHeading}</h2>
                            <div style={{color: "red", textAlign: "right", fontWeight: "bold", padding: "10px"}}>{tableError}</div>
                            <AgGridReact undoRedoCellEditing={true} rowData={rowData} columnDefs={columnDefs} onCellValueChanged={handleEdit} />
                        </div>
                    </Grid>
                </Grid>
            </main>
        );

    } else {
        return (
            <main>
                <h1>Data Administration</h1>
                <p>NOTE: You do not have privilege to access this menu.</p>
            </main>
        );
    }
}