import React, {useState} from 'react';



// Material-UI Companents
/*
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {InputLabel, MenuItem, Select} from "@material-ui/core";

 */

import config from "../../config/config";

import Grid from '@material-ui/core/Grid';
import {Button, TextField} from "@material-ui/core";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'


export default function WoodTicketRate(props) {

    return (

        <div>
            <Grid container wrap="nowrap" spacing={3} direction="row" alignItems="flex-start" justify="flex-start">

                <Grid item xs={3}>
                    {props.woodTicketRate.rateTypeName}
                </Grid>
                <Grid item xs={3}>
                    <CurrencyTextField
                        id={"rate-unit-price-" + props.index}
                        label="Unit Price"
                        variant="outlined"
                        //helperText="Required"
                        onKeyPress={(e) => props.handleFieldChange(e)}
                        onBlur={(e) => props.handleFieldBlur(e)}
                        //required="true"
                        value={props.woodTicketRate.unitPrice}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id={"rate-unit-" + props.index}
                        label="Unit"
                        variant="outlined"
                        onChange={(e) => props.handleFieldChange(e)}
                        //helperText="Calculated Value"
                        disabled="true"
                        defaultValue={props.woodTicketRate.unitTypeName}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CurrencyTextField
                        id={"rate-total-price-" + props.index}
                        label="Total Price"
                        variant="outlined"
                        onChange={(e) => props.handleFieldChange(e)}
                        //helperText="Calculated Value"
                        value={props.woodTicketRate.totalPrice}
                        disabled="true"
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        id={"rate-rateid-" + props.index}
                        label="Rate Id"
                        variant="outlined"
                        //helperText="Calculated Value"
                        value={props.woodTicketRate.rateId}
                        disabled="true"
                    />
                </Grid>

            </Grid>
        </div>

    )
};
