import React from 'react'
import config from "../../config/config.json";
import { Link } from "react-router-dom";
import Logout from '../../components/Login/Logout';

function Header(props) {

    if (props.user_details) {
        return (
            <nav className="navbar navbar-expand-lg navbar-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand text-white" to={"/"}>Wood Accounting {config.app_version}</Link>
                    <p className="float-right>"><i>{props.user_details}</i>&nbsp;&nbsp;&nbsp;&nbsp;<Logout /></p>
                </div>
            </nav>
        )


    } else {
        return (
            <nav className="navbar navbar-expand-lg navbar-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand text-white" to={"/"}>Wood Accounting <i>{config.app_version}</i></Link>
                </div>
            </nav>
        )
    }
}

export default Header