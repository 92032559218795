import React, {Component} from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Button} from '@material-ui/core';
import config from "../../config/config";


async function exportData(endPoint) {

    return fetch(config.wacct_api_url + ":" + config.wacct_api_port + endPoint, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-wtg-token': config.wacct_api_token
        }
    })
        .then(data => data.json())
        .catch(error => {
            console.log("ERROR connecting to WACCT API: " + error)
            return {"error": "Error connecting to the WACCT API: " + error};
        })
}

export default class Co1Export extends Component {

    state = {message: '', messageColor: 'black', disabled: false};

    config = {
        header: true,
        skipEmptyLines: true
    };

    handleExport = async (e) => {

        try {
            let result;

            this.setState({message: '', messageColor: 'black', disabled: true});

            result = await exportData('/exportInterCompanies');

            if (result.success) {
                this.setState({message: result.success, messageColor: 'green'});
                console.log("Success InterCo Export: " + result.success);
                setTimeout(
                    () => {
                        this.setState({message: '', messageColor: 'black'});
                        this.props.handleGoBack(e);
                    },
                    10000
                );
            } else if (result.warning) {
                this.setState({message: result.warning, messageColor: 'orange'});
                console.log("WARNING InterCo Export: " + result.success);
                setTimeout(
                    () => {
                        this.setState({message: '', messageColor: 'black'});
                        this.props.handleGoBack(e);
                    },
                    10000
                );

            } else { // Error occured
                this.setState({message: result.error, messageColor: 'red'});
                console.log("ERROR InterCo Export: " + result.success);
                setTimeout(
                    () => {
                        this.setState({message: '', messageColor: 'black'});
                        this.props.handleGoBack(e);
                    },
                    10000
                );
            }

        } catch (error) {
            console.log("InterCo Export was not successful. " + error);
            this.setState({message: error, messageColor: 'red'});
            setTimeout(
                () => {
                    this.setState({message: '', messageColor: 'black'});
                    this.props.handleGoBack(e);
                },
                10000
            );
        }

    };

    render() {
        return (
            <>
                <h3><ArrowBackIcon onClick={(e) => this.props.handleGoBack(e)}/>InterCompany Great Plains Export</h3>
                <br/>
                <div style={{"marginLeft": 100, "color": this.state.messageColor}}>
                    {this.state.message}
                </div>
                <div style={{"marginLeft": 100}}>
                    <br/><br/>
                    <Button variant="contained" disabled={this.state.disabled} onClick={(e) => this.handleExport(e)}>Export
                        InterCompany Data</Button>
                </div>
            </>
        );
    }
}
